"use client";
import React from "react";

const NotFoundPage = () => {
  const handleBackClick = () => {
    if (typeof window !== "undefined") {
      window.location.href = "/";
    }
  };

  return (
    <main className="container">
      <div className="blur-circle" aria-hidden="true" />
      <section className="content">
        <h1 className="error-code">404</h1>
        <h2 className="error-title">Page Not Found</h2>
        <p className="error-message">
          The page you are looking for can't be found.
        </p>
        <button
          onClick={handleBackClick}
          className="button"
          aria-label="Return to homepage"
        >
          Go Back to Homepage
        </button>
      </section>

      <style jsx>{`
        .container {
          width: 100%;
          height: 100vh;
          background: linear-gradient(124deg, #032836 22.23%, #07516c 91.8%);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
        }
        .blur-circle {
          width: 415px;
          height: 415px;
          border-radius: 50%;
          opacity: 0.35;
          filter: blur(117.36px);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #b6f3f8;
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          z-index: 1;
        }
        .error-code {
          color: #fff;
          font-family: "Roboto", sans-serif;
          font-size: 144px;
          font-weight: 800;
          margin: 0;
        }
        .error-title {
          color: #fff;
          font-family: "Roboto", sans-serif;
          font-size: 48px;
          font-weight: 500;
          margin: 0 0 25px;
        }
        .error-message {
          color: #fff;
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.32px;
          margin-bottom: 54px;
        }
        .button {
          padding: 12px 24px;
          border-radius: 2px;
          color: #07516c;
          text-align: center;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          cursor: pointer;
          background-color: #fff;
          border: none;
          transition: opacity 0.2s ease;
        }
        .button:hover {
          opacity: 0.9;
        }
        .button:focus {
          outline: 2px solid #fff;
          outline-offset: 2px;
        }
        @media (max-width: 991px) {
          .error-code {
            font-size: 120px;
          }
          .error-title {
            font-size: 40px;
          }
          .error-message {
            font-size: 14px;
            margin-bottom: 40px;
          }
        }
        @media (max-width: 640px) {
          .error-code {
            font-size: 96px;
          }
          .error-title {
            font-size: 32px;
            margin-bottom: 20px;
          }
          .error-message {
            font-size: 14px;
            margin-bottom: 30px;
            padding: 0 20px;
          }
          .button {
            padding: 10px 20px;
            font-size: 14px;
          }
        }
      `}</style>
    </main>
  );
};

export default NotFoundPage;
