import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Access from "../../../../Images/Service/SubPages/access-control.png";
import HeroAccess from "../../../../Images/Service/SubPages/HeroImages/Access.png";

// Images for Access service cards
import AccessDesignImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/AccessDesign.webp";
import AccessInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/AccessInstallation.webp";
import AccessIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/AccessIntegration.webp";
import AccessMaintenanceImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/AccessMaintenance.webp";

// Workflow images for Access Control (sized 450px x 380px)
import WorkflowSurveyImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/Workflow/WorkflowSurvey.webp";
import WorkflowInstallImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/Workflow/WorkflowInstall.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/AccessControl/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Reliable Access Control Systems for Secure Spaces";
const heroSubTitle =
  "Ensure the safety of your business and property with custom-designed access control solutions. We tailor systems to your security needs, integrating advanced technology for robust protection.";

// Services Section
const serviceTitle = "Advanced Access Control Solutions";
const serviceSubTitle =
  "Our services cover complete access control system design, installation, and ongoing support. We help you secure your facilities with tailored solutions.";

// Service Cards (4 cards)
const accessCardTitles = [
  "Custom System Design",
  "Professional Installation",
  "Integration & Automation",
  "Maintenance & Support",
];
const accessCardSubTitles = [
  "Tailor-made designs for optimal security coverage and efficiency.",
  "Expert setup ensuring reliable and secure system deployment.",
  "Seamless integration with existing security systems and smart automation.",
  "Regular updates and support to keep your access control running reliably.",
];
const accessCardImages = [
  AccessDesignImage,
  AccessInstallationImage,
  AccessIntegrationImage,
  AccessMaintenanceImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Deployment Process";
const workflowSubTitle =
  "Our structured process ensures your access control system is planned, installed, and maintained to the highest standards.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Initial Consultation & Site Survey",
    subtitle1:
      "We evaluate your premises and assess your security needs.",
    image1: WorkflowSurveyImage,
    listItem1: "Initial Consultation",
    listItem2: "Site Survey",
    listItem3: "Needs Assessment",
    listItem4: "Custom Proposal",
    listItem5: "Plan Approval",
  },
  {
    title2: "System Installation & Configuration",
    subtitle2:
      "Our team installs and configures your access control system for optimal performance.",
    image2: WorkflowInstallImage,
    listItem6: "Equipment Setup",
    listItem7: "System Installation",
    listItem8: "Configuration",
    listItem9: "Testing",
    listItem10: "Validation",
  },
  {
    title3: "Ongoing Maintenance & Support",
    subtitle3:
      "Continuous monitoring and maintenance keep your system secure and reliable.",
    image3: WorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Software Updates",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const AccessControlSystems = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroAccess}
        serviceTitle="Access Control Systems"
        serviceImage={Access}
        heroImageClass="service-image"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={accessCardTitles}
        cardSubTitles={accessCardSubTitles}
        cardImages={accessCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default AccessControlSystems;
