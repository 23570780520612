import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./Partners.css";

// Images
import Cubes from "../../../Images/Partners/cubes.png";
import CubesMobile from "../../../Images/Partners/cubesMobile.png";
import SmallCubes from "../../../Images/Partners/small_cubes.png";
import Kinetx from "../../../Images/Partners/Kinetx.webp";
import Kino from "../../../Images/Partners/Kino.webp";	
import Kit from "../../../Images/Partners/KIT.webp";
import Kodex from "../../../Images/Partners/Kodex.webp";

const useStyles = makeStyles((theme) => ({
  partners: {
    backgroundColor: "#fff",
  },
  container: {
    padding: "150px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "70px 24px 50px",
    },
  },
  gridContainer: {
    minHeight: "300px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column-reverse",
    },
  },
  leftContainer: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr",
    position: "relative",
    alignItems: "center",
    placeItems: "center",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr", // Stack vertically on mobile
      gridTemplateRows: "repeat(4, auto)",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  bigCubes: {
    position: "absolute",
    backgroundImage: `url(${Cubes})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${CubesMobile})`,
    },
  },
  logoButtonContainer: {
    display: "flex",
    flexFlow: "column !important",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: "1",
    height: "170px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      justifyContent: "center",
      transform: "translateY(-25px)",
    },
    [theme.breakpoints.down("xs")]: {
      height: "230px",
    },
  },
  logoHeight: {
    height: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  },
  button: {
    padding: "8px 18px !important",
    background: "#07516C !important",
    color: "#fff !important",
    textTransform: "capitalize !important",
    fontFamily: "Roboto !important",
    fontWeight: "700 !important",
    [theme.breakpoints.down("xs")]: {
      padding: "6px 14px !important",
    },
  },
  rightContainer: {
    display: "flex",
    flexFlow: "column !important",
    justifyContent: "center",
    paddingLeft: "64px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "unset",
      alignItems: "center",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "unset",
    },
  },
  title: {
    fontWeight: "600 !important",
    fontFamily: "Roboto !important",
    fontSize: "36px !important",
    color: "#2D2D2D !important",
  },
  paragraph: {
    fontWeight: "400 !important",
    fontSize: "17px !important",
    marginTop: "30px !important",
    color: "#2D2D2D !important",
    fontFamily: "Roboto !important",
    maxWidth: "500px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "535px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "400px",
      marginTop: "10px !important",
    },
  },
  smallCubes: {
    position: "absolute",
    right: "-30px",
    bottom: "1px",
    zIndex: "-1",
    [theme.breakpoints.down("sm")]: {
      left: "120px",
      bottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "0",
      bottom: "1px",
    },
  },
}));

const logo = [Kinetx, Kit, Kino, Kodex];
const linkUrl = ["#", "https://getkit.us/", "https://kino.live/"];

const Partners = () => {
  const classes = useStyles();
  return (
    <div className={classes.partners}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={5} sm={12} xs={12} className={classes.leftContainer}>
            <div className={classes.bigCubes}></div>
            {logo.map((item, i) => (
              <Grid
                item
                md={3}
                sm={3}
                xs={12} // Changed xs to 12 for vertical stacking on mobile
                className={classes.logoButtonContainer}
                key={i}
              >
                <span className={classes.logoHeight}>
                  <img
                    src={logo[i]}
                    alt={"Brand Logo"}
                    style={{ maxHeight: "49px", width: "auto" }}
                  />
                </span>
                <a
                  href={linkUrl[i]}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button className={classes.button}>Learn More</Button>
                </a>
              </Grid>
            ))}
          </Grid>
          <Grid item md={6} sm={12} xs={12} className={classes.rightContainer}>
            <Typography variant="h2" className={classes.title}>
              <span style={{ position: "relative", zIndex: "1" }}>
                Kinetx Ecosystem
                <img
                  src={SmallCubes}
                  alt={"Small Cubes"}
                  className={classes.smallCubes}
                  style={{ maxHeight: "49px", width: "auto" }}
                />
              </span>
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Our ecosystem unites four distinct technology brands that work together to drive your business forward. <br /><br />
              <strong>Kinetx Co</strong> – A trusted IT consultancy delivering practical technology solutions and solid infrastructure planning. <br /><br />
              <strong>KIT</strong> – A cloud-based VoIP telephone service offering an advanced PBX system with over 100 features to reduce communication costs. <br /><br />
              <strong>Kino Live</strong> – An online co-working space built for remote teams, providing tools for natural interaction and productive collaboration. <br /><br />
              <strong>Kodex</strong> – A software development firm that creates custom applications and websites, offering digital solutions tailored to your business needs. <br /><br />
              Together, these brands form a robust technology network that supports your operations and delivers measurable results.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Partners;
