import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom"; // For internal navigation
import "./Service.css";

// Updated Images (unchanged)
import Physical1 from "../../../Images/Industries/Retail.webp";
import Physical2 from "../../../Images/Industries/Hospitality.webp";
import Physical3 from "../../../Images/Industries/Corporate.webp";
import Physical4 from "../../../Images/Industries/Manufacturing.webp";

const useStyles = makeStyles((theme) => ({
	projects: {
		display: "flex",
		alignItems: "center",
		position: "relative",
	},
	projectsImage: {
		background: "#FFFFFF",
		opacity: "0.5",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "60px 0 100px",
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "36px !important",
		textTransform: "capitalize !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	titleHighlight: {
		background: "#ADCFDB",
		width: "400px",
		height: "15px",
		opacity: "0.4",
		marginBottom: "60px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto 60px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
			margin: "0 0 60px",
		},
	},
	gridContainerProjects: {
		justifyContent: "space-between",
	},
	projectContainer: {
		display: "flex",
		position: "relative",
		background: "#eff6f8",
		marginBottom: "60px",
		justifyContent: "space-around",
		alignItems: "center",
		padding: "20px 10px",
		maxWidth: "48% !important",
		[theme.breakpoints.down("sm")]: {
			padding: "15px 10px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "100% !important",
		},
	},
	infoContainer: {
		width: "340px",
		padding: "0 10px",
		[theme.breakpoints.down("sm")]: {
			width: "100% !important",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "340px !important",
			padding: "0 0 0 15px",
		},
	},
	cubeImage: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
			border: "1px solid #000",
			padding: "5px",
		},
	},
	cubeImageTablet: {
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			height: "100%",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	subTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "20px",
		marginBottom: "5px",
		maxWidth: "270px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto 15px",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "0 0 15px",
			fontSize: "18px",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "340px",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	paragraphTablet: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "340px",
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
}));

// New industry data with modified text
const industryTitles = [
	"Retail",
	"Hospitality",
	"Corporate",
	"Manufacturing",
];

const industryParagraphs = [
	"Elevate your retail operations with innovative IT strategies that boost customer engagement and drive growth.",
	"Transform guest experiences with bespoke IT solutions that enhance service quality and streamline operations.",
	"Empower your organization with robust IT infrastructures that secure data and optimize business processes.",
	"Revolutionize production with advanced IT systems that ensure efficiency, connectivity, and scalable growth.",
];

// Use the updated image links
const industryImages = [Physical1, Physical2, Physical3, Physical4];

// Internal links for each industry page
const industryLinks = [
	"/industries/retail",
	"/industries/hospitality",
	"/industries/corporate",
	"/industries/manufacturing",
];

const IndustriesShowcase = () => {
	const classes = useStyles();
	return (
		<div className={classes.projects}>
			<div className={classes.projectsImage}></div>
			<Container className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={12} style={{ zIndex: "1" }}>
						<Link to="/industries" style={{ textDecoration: "none", color: "inherit" }}>
							<Typography variant="h2" className={classes.title}>
								Industries We Serve
							</Typography>
						</Link>
						<div className={classes.titleHighlight}></div>
						<Grid container className={classes.gridContainerProjects}>
							{industryTitles.map((item, i) => (
								<Grid
									item
									md={6}
									sm={6}
									xs={12}
									className={classes.projectContainer}
									key={i}
								>
									<div>
										<Link to={industryLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
											<img
												src={industryImages[i]}
												className={classes.cubeImage}
												alt="Industry Illustration"
											/>
										</Link>
									</div>
									<div className={classes.infoContainer}>
										<Link to={industryLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
											<Typography variant="h2" className={classes.subTitle}>
												{industryTitles[i]}
											</Typography>
											<Typography
												variant="subtitle1"
												className={classes.paragraph}
												dangerouslySetInnerHTML={{ __html: industryParagraphs[i] }}
											></Typography>
										</Link>
										<div style={{ display: "flex", alignItems: "center" }}>
											<Link to={industryLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
												<Typography
													variant="subtitle1"
													className={classes.paragraphTablet}
													dangerouslySetInnerHTML={{ __html: industryParagraphs[i] }}
												></Typography>
											</Link>
											<Link to={industryLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
												<img
													src={industryImages[i]}
													className={classes.cubeImageTablet}
													alt="Industry Illustration Tablet"
												/>
											</Link>
										</div>
									</div>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default IndustriesShowcase;
