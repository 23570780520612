import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Architecture from "../../../../Images/Service/SubPages/physical-architecture.png";
import HeroArchitecture from "../../../../Images/Service/SubPages/HeroImages/PhysicalArchitecture.png";

// Images for System Architecture service cards
import ArchDesignImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/ArchDesign.webp";
import ArchIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/ArchIntegration.webp";
import ArchDeploymentImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/ArchDeployment.webp";
import ArchSupportImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/ArchSupport.webp";

// Workflow images for System Architecture (sized 450px x 380px)
import WorkflowDesignImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/Workflow/WorkflowDesign.webp";
import WorkflowIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/Workflow/WorkflowIntegration.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/SystemArchitecture/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Transforming IT Through Creative Architecture";
const heroSubTitle =
  "We dive deep into your business goals and design tailored IT frameworks that drive innovation and efficiency. Our approach blends strategic insight with technical expertise to build systems that truly fit your needs.";

// Services Section
const serviceTitle = "Bespoke IT Infrastructure Solutions";
const serviceSubTitle =
  "Our solutions fuse creativity with technical precision. We craft custom architectures that bridge your business objectives with technology through robust planning, innovative design, smart integration, and proactive optimization.";

// Service Cards (4 cards)
const systemArchCardTitles = [
  "Insightful Planning",
  "Innovative Design",
  "Smart Integration",
  "Proactive Optimization",
];
const systemArchCardSubTitles = [
  "Deep dive into your business processes to outline a custom IT roadmap.",
  "Creating unique architectures that mirror your vision.",
  "Merging new solutions with existing systems for maximum efficiency.",
  "Continuous improvements to keep your infrastructure future-ready.",
];
const systemArchCardImages = [
  ArchDesignImage,
  ArchIntegrationImage,
  ArchDeploymentImage,
  ArchSupportImage,
];

// Vendors Section
const vendorsTitle = "Our Esteemed Clients";

// Workflow Section
const workflowTitle = "Our Creative Process";
const workflowSubTitle =
  "We partner with you from concept to execution, crafting solutions that align technology with your business ambitions.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Understanding Your Goals",
    subtitle1:
      "We listen and analyze your business objectives to form the foundation of a custom solution.",
    image1: WorkflowDesignImage,
    listItem1: "Business Goals",
    listItem2: "Process Mapping",
    listItem3: "Stakeholder Input",
    listItem4: "Environment Review",
    listItem5: "Requirement Analysis",
  },
  {
    title2: "Creative Strategy Development",
    subtitle2:
      "Our team brainstorms innovative approaches that translate your needs into practical solutions.",
    image2: WorkflowIntegrationImage,
    listItem6: "Idea Generation",
    listItem7: "Strategic Alignment",
    listItem8: "Technology Selection",
    listItem9: "Prototype Development",
    listItem10: "Feedback Iteration",
  },
  {
    title3: "Tailored Execution",
    subtitle3:
      "We implement your custom solution with precision, monitoring every detail for optimal performance.",
    image3: WorkflowSupportImage,
    listItem11: "Solution Deployment",
    listItem12: "Rigorous Testing",
    listItem13: "User Training",
    listItem14: "Fine-Tuning",
    listItem15: "Performance Assessment",
  },
];

const SystemArchitecture = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroArchitecture}
        serviceTitle="System Architecture"
        serviceImage={Architecture}
        heroImageClass="service-image2"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={systemArchCardTitles}
        cardSubTitles={systemArchCardSubTitles}
        cardImages={systemArchCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default SystemArchitecture;
