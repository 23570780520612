import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./Service.css";

// Images (unchanged)
import Physical1 from "../../../Images/Service/physical1.png";
import Physical2 from "../../../Images/Service/physical2.png";
import Physical3 from "../../../Images/Service/physical3.png";
import Physical4 from "../../../Images/Service/physical4.png";

const useStyles = makeStyles((theme) => ({
	projects: {
		display: "flex",
		alignItems: "center",
		position: "relative",
	},
	projectsImage: {
		background: "#FFFFFF",
		opacity: "0.5",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "60px 0 100px",
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "36px !important",
		textTransform: "capitalize !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	titleHighlight: {
		background: "#ADCFDB",
		width: "400px",
		height: "15px",
		opacity: "0.4",
		marginBottom: "60px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto 60px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
			margin: "0 0 60px",
		},
	},
	gridContainerProjects: {
		justifyContent: "space-between",
	},
	projectContainer: {
		display: "flex",
		position: "relative",
		background: "#eff6f8",
		marginBottom: "60px",
		justifyContent: "space-around",
		alignItems: "center",
		padding: "20px 10px",
		maxWidth: "48% !important",
		[theme.breakpoints.down("sm")]: {
			padding: "15px 10px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "100% !important",
		},
	},
	infoContainer: {
		width: "340px",
		padding: "0 10px",
		[theme.breakpoints.down("xs")]: {
			width: "100% !important",
			maxWidth: "340px !important",
			padding: "0 0 0 15px",
		},
	},
	cubeImage: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
			border: "1px solid #000",
			padding: "5px",
		},
	},
	cubeImageTablet: {
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			height: "100%",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	subTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "20px",
		marginBottom: "5px",
		maxWidth: "270px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto 15px",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "0 0 15px",
			fontSize: "18px",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "340px",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	paragraphTablet: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "340px",
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
}));

const serviceTitles = [
	"Low-voltage Networking",
	"Wireless Connections",
	"Security Cameras",
	"A/V Systems",
];

const serviceParagraphs = [
	"Optimized wiring for superior performance. Expertly installed to ensure scalable, stable connections tailored to your needs.",
	"State-of-the-art wireless solutions provide secure, uninterrupted connectivity around the clock.",
	"Advanced surveillance systems offer comprehensive monitoring and robust protection.",
	"High-performance audiovisual setups deliver crystal-clear sound and vivid visuals to transform your space.",
];

const serviceLinks = [
	"/physical-infrastructure/low-voltage-cabling",
	"/physical-infrastructure/wifi-networks",
	"/physical-infrastructure/security-cameras",
	"/physical-infrastructure/audio-video-systems",
];

const serviceImages = [Physical1, Physical2, Physical3, Physical4];

const PhysicalProjects = () => {
	const classes = useStyles();
	return (
		<div className={classes.projects}>
			<div className={classes.projectsImage}></div>
			<Container className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={12} style={{ zIndex: "1" }}>
						<Typography variant="h2" className={classes.title}>
							Our Services
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Grid container className={classes.gridContainerProjects}>
							{serviceTitles.map((item, i) => (
								<Grid
									item
									md={6}
									sm={6}
									xs={12}
									className={classes.projectContainer}
									key={i}
								>
									<Link
										to={serviceLinks[i]}
										style={{
											textDecoration: "none",
											color: "inherit",
											display: "flex",
											width: "100%",
											justifyContent: "space-around",
											alignItems: "center",
										}}
									>
										<div>
											<img
												src={serviceImages[i]}
												className={classes.cubeImage}
												alt="Service Illustration"
											/>
										</div>
										<div className={classes.infoContainer}>
											<Typography variant="h2" className={classes.subTitle}>
												{serviceTitles[i]}
											</Typography>
											<Typography
												variant="subtitle1"
												className={classes.paragraph}
												dangerouslySetInnerHTML={{ __html: serviceParagraphs[i] }}
											></Typography>
											<div style={{ display: "flex", alignItems: "center" }}>
												<Typography
													variant="subtitle1"
													className={classes.paragraphTablet}
													dangerouslySetInnerHTML={{ __html: serviceParagraphs[i] }}
												></Typography>
												<img
													src={serviceImages[i]}
													className={classes.cubeImageTablet}
													alt="Service Illustration Tablet"
												/>
											</div>
										</div>
									</Link>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default PhysicalProjects;
