import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import "./Service.css";

// Images
import Stacked from "../../../Images/Service/StackedCubes.png";

const useStyles = makeStyles((theme) => ({
	solution: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		position: "relative",
	},
	solutionImage: {
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
		background: "linear-gradient(255.64deg, #032836 1.47%, #07516C 117.99%)",
		[theme.breakpoints.down("sm")]: {
			background: "linear-gradient(356.05deg, #032836 3.85%, #07516C 113.72%)",
		},
		[theme.breakpoints.down("xs")]: {
			background: "linear-gradient(255.64deg, #032836 1.47%, #07516C 117.99%)",
		},
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "130px 0",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 0",
		},
		[theme.breakpoints.down("xs")]: {
			flexFlow: "column-reverse !important",
		},
	},
	leftContainer: {
		display: "flex",
		justifyContent: "center",
		zIndex: "1",
		flexFlow: "column !important",
		[theme.breakpoints.down("sm")]: {
			alignItems: "center",
		},
	},
	title: {
		color: "#fff !important",
		fontFamily: "Roboto !important",
		fontSize: "36px !important",
		fontWeight: "700 !important",
		marginBottom: "30px !important",
		lineHeight: "48px !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	paragraph: {
		fontFamily: "Roboto !important",
		color: "#fff !important",
		fontWeight: "400 !important",
		fontSize: "15.5px !important",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "505px",
			marginBottom: "30px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "unset",
			marginBottom: "unset",
		},
	},
	rightContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: "1",
		flexFlow: "column !important",
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
			marginBottom: "10px",
		},
	},
	stackedImage: {
		[theme.breakpoints.down("xs")]: {
			height: "200px",
		},
	},
}));

const Solution = () => {
	const classes = useStyles();
	return (
		<div className={classes.solution}>
			<div className={classes.solutionImage}></div>
			<Container className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} sm={12} xs={12} className={classes.leftContainer}>
						<Typography variant="h2" className={classes.title}>
							Experience next-generation IT and data accessibility solutions
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							We craft custom IT infrastructures built on the strongest strategies, ensuring every detail fits your unique operations.
							<br /><br />
							Unlike other consulting firms, we begin by understanding your business model, daily workflow, and what drives your success—so we can deliver a solution that truly propels you ahead.
						</Typography>
					</Grid>
					<Grid item md={6} sm={12} xs={12} className={classes.rightContainer}>
						<img
							src={Stacked}
							alt="Stacked Cubes"
							className={classes.stackedImage}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Solution;
