import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import LegalIcon from "../../../../Images/Industries/SubPages/LegalIconWhite.png";
import HeroLegal from "../../../../Images/Industries/SubPages/HeroImages/Legal.webp";

// Images for Service cards (Legal images)
import LegalDesignImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal/LegalDesign.webp";
import LegalDeploymentImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal/LegalDeployment.webp";
import VirtualWorkspaceImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal//OnlineCollaboration.webp";
import LegalSupportImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal/LegalSupport.webp";

// Workflow images for Legal
import WorkflowConsultLegalImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal/Workflow/WorkflowConsultLegal.webp";
import WorkflowIntegrationLegalImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal/Workflow/WorkflowIntegrationLegal.webp";
import WorkflowSupportLegalImage from "../../../../Images/Industries/SubPages/ServicesImages/Legal/Workflow/WorkflowSupportLegal.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Secure IT Solutions for the Legal Industry";
const heroSubTitle =
  "Enhance your firm's operations with secure, compliant, and innovative IT infrastructures tailored to the unique needs of legal practices. Our solutions ensure confidentiality, reliability, and efficiency.";

// Services Section
const serviceTitle = "Comprehensive Legal IT Solutions";
const serviceSubTitle =
  "We specialize in creating secure, scalable IT infrastructures designed for law firms and legal departments. Our services include secure data management systems, robust network connectivity, online collaboration solutions to keep your legal teams connected and productive, and advanced security & compliance solutions to meet strict regulatory standards.";

// Service Cards (4 cards)
const legalCardTitles = [
  "Secure Data Systems",
  "High-Speed Network Connectivity",
  "Online Collaboration",
  "Security & Compliance Solutions",
];
const legalCardSubTitles = [
  "Design and implementation of secure data management and backup solutions to protect sensitive legal information.",
  "Deployment of reliable network infrastructures, including WiFi networks, to support seamless communication within your firm.",
  "Equip your legal team with advanced online collaboration tools that ensure secure connectivity, efficient file sharing, and virtual workspace integration for remote cooperation.",
  "Implementation of advanced security measures, including access control and surveillance, to ensure compliance with legal regulations and protect client confidentiality.",
];
const legalCardImages = [
  LegalDesignImage,
  LegalDeploymentImage,
  VirtualWorkspaceImage,
  LegalSupportImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Implementation Process";
const workflowSubTitle =
  "Our structured process ensures your legal IT infrastructure is designed, integrated, and supported to meet the highest standards of security and efficiency.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Consultation & Needs Assessment",
    subtitle1:
      "We evaluate your firm’s requirements and develop a tailored IT strategy.",
    image1: WorkflowConsultLegalImage,
    listItem1: "Initial Consultation",
    listItem2: "Firm Audit",
    listItem3: "Needs Analysis",
    listItem4: "Proposal",
    listItem5: "Plan Approval",
  },
  {
    title2: "Design & Integration",
    subtitle2:
      "Our team designs a secure infrastructure and integrates it with your existing systems.",
    image2: WorkflowIntegrationLegalImage,
    listItem6: "Blueprint Development",
    listItem7: "Component Selection",
    listItem8: "System Design",
    listItem9: "Integration Testing",
    listItem10: "Validation",
  },
  {
    title3: "Deployment & Ongoing Support",
    subtitle3:
      "We deploy your infrastructure and provide continuous support to maintain optimal performance.",
    image3: WorkflowSupportLegalImage,
    listItem11: "System Deployment",
    listItem12: "Configuration",
    listItem13: "Quality Assurance",
    listItem14: "Continuous Monitoring",
    listItem15: "Performance Optimization",
  },
];

const LegalSolutions = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroLegal}
        serviceTitle="Legal IT Solutions"
        serviceImage={LegalIcon}
        heroImageClass="service-image3"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={legalCardTitles}
        cardSubTitles={legalCardSubTitles}
        cardImages={legalCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"virtual"} />
    </div>
  );
};

export default LegalSolutions;
