import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import ManufacturingIcon from "../../../../Images/Industries/SubPages/ManufacturingIconWhite.png";
import HeroManufacturing from "../../../../Images/Industries/SubPages/HeroImages/Manufacturing.webp";

// Images for Service cards (Manufacturing images)
import ManufacturingDesignImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/ManufacturingLoveVoltage.webp";
import ManufacturingInstallationImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/WifiInstallation.webp";
import ManufacturingMaintenanceImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/ManufacturingData.webp";
import ManufacturingIntegrationImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/ManufacturingArchitecture.webp";

// Workflow images for Manufacturing
import ManufacturingWorkflowAssessmentImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/Workflow/WorkflowAssessment.webp";
import ManufacturingWorkflowInstallationImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/Workflow/WorkflowInstallation.webp";
import ManufacturingWorkflowSupportImage from "../../../../Images/Industries/SubPages/ServicesImages/Manufacturing/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Innovative IT Solutions for the Manufacturing Industry";
const heroSubTitle =
  "We deliver tailored, cutting-edge infrastructures that support manufacturers with robust physical connectivity and smart virtual systems, driving operational excellence.";

// Services Section
const serviceTitle = "Comprehensive Manufacturing IT Solutions";
const serviceSubTitle =
  "Our integrated approach blends dependable physical services with advanced virtual systems to streamline production and boost efficiency. We ensure reliable connectivity with robust low voltage cabling and next-generation wireless solutions, while our virtual offerings include sophisticated manufacturing data systems—integrating QuickBooks Enterprise, Xtuple, and EDI Systems—and custom system architecture tailored to manufacturing demands.";

// Service Cards (4 cards)
const manufacturingCardTitles = [
  "Low Voltage Cabling",
  "Wireless Solutions",
  "Manufacturing Data Systems",
  "Manufacturing-Focused System Architecture",
];
const manufacturingCardSubTitles = [
  "Reliable cabling solutions designed for high-performance manufacturing environments.",
  "Advanced wireless networks providing flexible, robust connectivity throughout your facility.",
  "Integration of QuickBooks Enterprise, Xtuple, and EDI Systems to streamline data management and operations.",
  "Custom IT architectures engineered to support complex manufacturing processes and drive innovation.",
];
const manufacturingCardImages = [
  ManufacturingDesignImage,
  ManufacturingInstallationImage,
  ManufacturingMaintenanceImage,
  ManufacturingIntegrationImage,
];

// Vendors Section
const vendorsTitle = "Our Esteemed Clients";

// Workflow Section
const workflowTitle = "Our Manufacturing Implementation Process";
const workflowSubTitle =
  "From facility assessments to advanced installation and continuous support, our structured process ensures your manufacturing operations reach peak performance.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Facility Assessment & Design",
    subtitle1:
      "Evaluate your manufacturing environment and craft a tailored IT blueprint.",
    image1: ManufacturingWorkflowAssessmentImage,
    listItem1: "Initial Consultation",
    listItem2: "Facility Survey",
    listItem3: "Production Layout Planning",
    listItem4: "Custom Process Design",
    listItem5: "Plan Approval",
  },
  {
    title2: "Advanced Installation & Integration",
    subtitle2:
      "Implement low voltage cabling, wireless networks, and integrated data systems with precision.",
    image2: ManufacturingWorkflowInstallationImage,
    listItem6: "Equipment Setup",
    listItem7: "Equipment Installation",
    listItem8: "Quality Assurance",
    listItem9: "System Testing",
    listItem10: "Operational Configuration",
  },
  {
    title3: "Continuous Support & Optimization",
    subtitle3:
      "Provide ongoing maintenance, upgrades, and performance reviews to keep your systems secure and efficient.",
    image3: ManufacturingWorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Upgrades",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const ManufacturingSolutions = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroManufacturing}
        serviceTitle="Manufacturing Solutions"
        serviceImage={ManufacturingIcon}
        heroImageClass="service-image2"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={manufacturingCardTitles}
        cardSubTitles={manufacturingCardSubTitles}
        cardImages={manufacturingCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default ManufacturingSolutions;
