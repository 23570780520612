import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button, Box } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../Industries.css"

const useStyles = makeStyles((theme) => ({
	hero: {
		minHeight: "90vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background: "linear-gradient(132.47deg, #032836 19.24%, #07516C 81.68%)",
		alignItems: "center",
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			background: "linear-gradient(132.47deg, #032836 10.5%, #07516C 74.99%)",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
			minHeight: "65vh",
			background: "linear-gradient(65.73deg, #032836 9.16%, #07516C 85%)",
		},
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			margin: "20px auto 50px",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
			margin: "20px auto",
		},
	},
	gridContainer: {
		[theme.breakpoints.down("sm")]: {
			justifyContent: "space-between",
			alignItems: "center",
			flexFlow: "column",
		},
		[theme.breakpoints.down("xs")]: {
		},
	},
	leftContainer: {
		display: "flex",
		flexFlow: "column",
		justifyContent: "center",
		[theme.breakpoints.down("sm")]: {
			alignItems: "center",
			marginBottom: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "unset",
			marginBottom: "20px",
		},
	},
	rightContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
	},
	serviceContainer: {
		display: "flex",
		alignItems: "center",
		marginBottom: "20px",
	},
	serviceText: {
		fontSize: "21px",
		fontWeight: "700",
		color: "rgba(226, 232, 240, 1)",
		marginLeft: "5px",
	},
	title: {
		fontWeight: "700",
		fontFamily: "Roboto",
		color: "#fff",
		fontSize: "48px",
		maxWidth: "580px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "40px",
			lineHeight: "52px",
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			lineHeight: "40px",
			textAlign: "unset",
		},
	},
	paragraph: {
		fontWeight: "400",
		fontSize: "1rem",
		margin: "30px 0",
		color: "#fff",
		fontFamily: "Roboto",
		maxWidth: "570px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "17px",
			textAlign: "center",
			// maxWidth: "380px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
			maxWidth: "400px",
			lineHeight: "24px",
			textAlign: "unset",
		},
	},
	button: {
		padding: "12px 24px",
		background: "#07516C !important",
		color: "#FFFFFF",
		textTransform: "capitalize",
		fontFamily: "Roboto",
		fontWeight: "700",
	},
	icon: {
		fontSize: "10px",
		marginRight: "10px",
	},
}));

const Hero = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.hero}>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} sm={8} xs={12} className={classes.leftContainer}>
						<div className={classes.serviceContainer}>
							<img src={props.serviceImage} alt={props.serviceImage} />
							<Typography variant="subtitle1" className={classes.serviceText}>
								{props.serviceTitle}
							</Typography>
						</div>
						<Typography variant="h2" className={classes.title}>
							{props.title}
						</Typography>
						<Typography variant="subtitle2" className={classes.paragraph}>
							{props.subtitle}
						</Typography>
						<Link to={"/contact-us"} style={{ textDecoration: "none" }}>
							<Button
								className={classes.button}
								endIcon={<ArrowForwardIosIcon className={classes.icon} />}
							>
								Get Started
							</Button>
						</Link>
					</Grid>
					<Grid item md={6} sm={4} className={classes.rightContainer}>
						<img src={props.image} alt={props.title} className={props.heroImageClass} />
						<Box className={props.fiberClass}></Box>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Hero;
