import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Architecture from "../../../../Images/Service/SubPages/cloud-architecture.png";
import HeroArchitecture from "../../../../Images/Service/SubPages/HeroImages/CloudArchitecture.png";

// Images for Cloud Architecture service cards
import CloudStrategyImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/CloudStrategy.webp";
import CloudDeploymentImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/CloudDeployment.webp";
import CloudIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/CloudIntegration.webp";
import CloudManagementImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/CloudManagement.webp";

// Workflow images for Cloud Architecture (sized 450px x 380px)
import WorkflowConsultImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/Workflow/WorkflowConsult.webp";
import WorkflowIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/Workflow/WorkflowIntegration.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/CloudArchitecture/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Cutting-Edge Cloud Architecture for Digital Transformation";
const heroSubTitle =
  "Build agile, scalable, and secure IT environments with our innovative cloud architecture solutions. We design and deploy dynamic infrastructures that streamline operations and reduce costs.";

// Services Section
const serviceTitle = "Advanced Cloud Architecture Solutions";
const serviceSubTitle =
  "Our services combine modern cloud strategies with robust IT design techniques to create efficient, future-proof systems.";

// Service Cards (4 cards)
const cloudCardTitles = [
  "Cloud Strategy & Planning",
  "Cloud Deployment",
  "Cloud Integration",
  "Ongoing Cloud Management",
];
const cloudCardSubTitles = [
  "Develop a tailored roadmap for cloud adoption that aligns with your business goals.",
  "Implement flexible cloud solutions that adapt to your growth needs.",
  "Effectively merge new cloud systems with your existing IT infrastructure.",
  "Continuous support and proactive updates to keep your cloud running at its best.",
];
const cloudCardImages = [
  CloudStrategyImage,
  CloudDeploymentImage,
  CloudIntegrationImage,
  CloudManagementImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Cloud Architecture Design Process";
const workflowSubTitle =
  "We transform your IT environment with impeccable design, precise attention to detail, and professional execution. Every element is crafted to ensure your cloud infrastructure is robust and perfectly aligned with your business vision.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Comprehensive Consultation & Analysis",
    subtitle1:
      "We conduct an in-depth evaluation of your current systems, gathering precise requirements and insights.",
    image1: WorkflowConsultImage,
    listItem1: "Detailed Consultation",
    listItem2: "Environment Audit",
    listItem3: "Requirement Analysis",
    listItem4: "Strategic Planning",
    listItem5: "Precise Documentation",
  },
  {
    title2: "Precision Design & Deployment",
    subtitle2:
      "Our experts develop detailed architectural blueprints and deploy solutions with exacting standards.",
    image2: WorkflowIntegrationImage,
    listItem6: "Blueprint Creation",
    listItem7: "Component Specification",
    listItem8: "Precision Deployment",
    listItem9: "Quality Testing",
    listItem10: "Integration Verification",
  },
  {
    title3: "Professional Management & Refinement",
    subtitle3:
      "With continuous oversight, we fine-tune your cloud architecture to maintain peak performance and uphold professional standards.",
    image3: WorkflowSupportImage,
    listItem11: "Continuous Monitoring",
    listItem12: "Scheduled Maintenance",
    listItem13: "Quality Assurance",
    listItem14: "Proactive Refinement",
    listItem15: "Performance Evaluation",
  },
];

const CloudArchitecture = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroArchitecture}
        serviceTitle="Cloud Architecture"
        serviceImage={Architecture}
        heroImageClass="service-image3"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={cloudCardTitles}
        cardSubTitles={cloudCardSubTitles}
        cardImages={cloudCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"virtual"} />
    </div>
  );
};

export default CloudArchitecture;
