import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {
	Container,
	Grid,
	Typography,
	TextField,
	Button,
	CircularProgress,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Images
import Small from "../../../Images/Service/smallCube.png";
import Big from "../../../Images/Service/bigCube.png";

const useStyles = makeStyles((theme) => ({
	consult: {
		position: "relative",
	},
	sectionImage: {
		background: "#E3F2F3",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	mainContainer: {
		padding: "150px 24px",
		[theme.breakpoints.down("sm")]: {
			padding: "50px 24px",
		},
	},
	container: {
		minHeight: "300px",
		display: "flex",
		justifyContent: "center",
	},
	highlight: {
		transition: ".5s ease-out",
		position: "absolute",
		background: "#ADCFDB",
		top: "-1px",
		height: "25px",
		width: "100%",
		left: "0",
	},
	highlightChangePhy: {
		background: "#FBCC9B",
		transition: ".3s ease-in",
	},
	highlightChangeVir: {
		background: "#FBCC9B",
		transition: ".3s ease-in",
	},
	title: {
		fontWeight: "600 !important",
		fontFamily: "Roboto !important",
		fontSize: "36px !important",
		color: "#2D2D2D",
		marginLeft: "70px",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "500px",
			margin: "0 auto",
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "405px",
			textAlign: "unset",
			margin: "unset",
		},
	},
	smallCubes: {
		position: "absolute",
		left: "-70px",
		bottom: "-26px",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			bottom: "80px",
			left: "-5px",
			height: "75px",
			display: "flex",
		},
	},
	rightContainer: {
		position: "relative",
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			marginTop: "40px",
		},
	},
	formContainer: {
		display: "flex",
		flexFlow: "column",
		alignItems: "flex-start",
		width: "340px",
		margin: "auto 0 auto auto",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			margin: "0 auto",
			textAlign: "center",
			position: "relative",
		},
		[theme.breakpoints.down("xs")]: {
			display: "flex",
			flexWrap: "wrap",
			width: "unset",
			alignItems: "flex-start",
		},
	},
	formField: {
		width: "340px",
		background: "#fff !important",
		marginBottom: "20px !important",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "340px",
			width: "100%",
		},
	},
	button: {
		padding: "12px 24px !important",
		background: "#07516C !important",
		color: "#fff !important",
		textTransform: "capitalize !important",
		fontFamily: "Roboto !important",
		fontWeight: "700 !important",
		marginTop: "25px",
		width: "235px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "235px",
			width: "100%",
		},
	},
	icon: {
		fontSize: "10px !important",
		marginRight: "10px !important",
	},
	bigCubes: {
		position: "absolute",
		left: "125px",
		bottom: "-65px",
		zIndex: "-1",
		[theme.breakpoints.down("sm")]: {
			height: "430px",
			left: "-25px",
			bottom: "-25px",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	errorText: {
		color: "red",
		fontSize: "14px",
		marginTop: "4px",
		marginLeft: "10px",
	},
}));

const Consult = (props) => {
	const classes = useStyles();
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		company: "",
	});
	const [errors, setErrors] = useState({
		name: "",
		email: "",
	});
	const [loading, setLoading] = useState(false);
	const [initialColor, setInitialColor] = useState(false);
	const [initialPhysical, setInitialPhysical] = useState(false);
	const [initialVirtual, setInitialVirtual] = useState(false);

	const listenScrollEvent = (e) => {
		let highlightElement = document.getElementById("color-change");
		let distanceToTop = Math.round(highlightElement.getBoundingClientRect().top);
		distanceToTop = distanceToTop - 80;

		if (props.color === "physical") {
			if (distanceToTop > -50 && distanceToTop < 330) {
				setInitialPhysical(true);
			} else {
				setInitialPhysical(false);
			}
		} else if (props.color === "virtual") {
			if (distanceToTop > -50 && distanceToTop < 330) {
				setInitialVirtual(true);
			} else {
				setInitialVirtual(false);
			}
		} else {
			if (distanceToTop > -50 && distanceToTop < 330) {
				setInitialColor(true);
			} else {
				setInitialColor(false);
			}
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", listenScrollEvent);
		return () => {
			window.removeEventListener("scroll", listenScrollEvent);
		};
	}, []);

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
		setErrors({ ...errors, [e.target.id]: "" });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let valid = true;
		const newErrors = { name: "", email: "" };

		if (formData.name.trim().length < 2) {
			newErrors.name = "Name must be at least 2 characters.";
			valid = false;
		}

		if (!emailRegex.test(formData.email.trim())) {
			newErrors.email = "Please enter a valid email address.";
			valid = false;
		}

		if (!valid) {
			setErrors(newErrors);
			return;
		}

		setLoading(true);
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: formData.name,
					email: formData.email,
					message: formData.company ? `Company: ${formData.company}` : "No company provided",
				}),
			});

			const result = await response.json();

			if (response.ok) {
				alert("Message sent successfully!");
				setFormData({ name: "", email: "", company: "" });
			} else {
				alert("Error sending message.");
			}
		} catch (error) {
			console.error("Submission error:", error);
			alert("Something went wrong. Please try again.");
		}
		setLoading(false);
	};

	return (
		<div className={classes.consult} id="color-change">
			<div className={classes.sectionImage}></div>
			<Container maxWidth="lg" className={classes.mainContainer}>
				<Grid container className={classes.container}>
					<Grid item md={6} sm={12} xs={12}>
						<Typography variant="h2" className={classes.title}>
							<span style={{ position: "relative" }}>
								Experience our expertise firsthand for{" "}
								<img src={Small} alt={"Small Cube"} className={classes.smallCubes} />
							</span>
							<span style={{ position: "relative" }}>
								<span style={{ position: "relative", zIndex: "2" }}>free</span>
								<span
									className={`
										${classes.highlight} 
										${initialColor && classes.highlightChange} 
										${initialPhysical && classes.highlightChangePhy} 
										${initialVirtual && classes.highlightChangeVir}
									`}
								></span>
							</span>
						</Typography>
					</Grid>
					<Grid item md={6} sm={12} xs={12} className={classes.rightContainer}>
						<form onSubmit={handleSubmit} autoComplete="off" className={classes.formContainer}>
							<TextField
								size="small"
								id="name"
								value={formData.name}
								onChange={handleChange}
								placeholder="Enter your name"
								variant="outlined"
								className={classes.formField}
							/>
							{errors.name && (
								<Typography className={classes.errorText}>{errors.name}</Typography>
							)}
							<TextField
								size="small"
								id="email"
								value={formData.email}
								onChange={handleChange}
								placeholder="Enter your email"
								variant="outlined"
								className={classes.formField}
							/>
							{errors.email && (
								<Typography className={classes.errorText}>{errors.email}</Typography>
							)}
							<TextField
								size="small"
								id="company"
								value={formData.company}
								onChange={handleChange}
								placeholder="Enter your company (optional)"
								variant="outlined"
								className={classes.formField}
							/>
							<Button type="submit" className={classes.button}>
								{loading ? (
									<CircularProgress size={24} color="inherit" />
								) : (
									<>
										GET CONSULT
										<ArrowForwardIosIcon className={classes.icon} />
									</>
								)}
							</Button>
							<img src={Big} alt={"Big Cube"} className={classes.bigCubes} />
						</form>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Consult;
