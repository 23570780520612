import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult.jsx";

// Images for Hero section
import Voltage from "../../../../Images/Service/SubPages/low-voltage.png";
import HeroVoltage from "../../../../Images/Service/SubPages/HeroImages/Voltage.png";

// Images for Low Voltage service cards
import LowVoltageDesignImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/LowVoltageDesign.webp";
import LowVoltageInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/LowVoltageInstallation.webp";
import LowVoltageMaintenanceImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/LowVoltageMaintenance.webp";
import LowVoltageIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/LowVoltageIntegration.webp";

// Workflow images for Low Voltage (sized 450px x 380px)
import WorkflowAssessmentImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/Workflow/WorkflowAssessment.webp";
import WorkflowInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/Workflow/WorkflowInstallation.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/LowVoltage/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Reliable Low-Voltage Cabling for Modern IT Infrastructure";
const heroSubTitle =
  "We design and install efficient low-voltage wiring systems tailored to your business needs, ensuring robust connectivity and improved network performance.";

// Services Section
const serviceTitle = "Comprehensive Cabling Solutions";
const serviceSubTitle =
  "Our low-voltage cabling services include expert planning, precise installation, and ongoing maintenance to support your network infrastructure reliably.";

// Service Cards (4 cards)
const lowVoltageCardTitles = [
  "Network Design & Planning",
  "Professional Installation",
  "Maintenance & Upgrades",
  "System Integration",
];
const lowVoltageCardSubTitles = [
  "Custom design strategies for optimal cable layout and performance.",
  "Expert installation ensuring high-quality wiring and connectivity.",
  "Regular inspections and updates to maintain efficiency.",
  "Smooth integration of cabling with existing network systems.",
];
const lowVoltageCardImages = [
  LowVoltageDesignImage,
  LowVoltageInstallationImage,
  LowVoltageMaintenanceImage,
  LowVoltageIntegrationImage,
];

// Vendors Section
const vendorsTitle = "Our Esteemed Clients";

// Workflow Section
const workflowTitle = "Our Deployment Process";
const workflowSubTitle =
  "Our structured process takes your low-voltage cabling from site assessment to full installation and maintenance, ensuring optimal network performance.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Site Assessment & Design",
    subtitle1:
      "We assess your site and create a tailored cabling blueprint.",
    image1: WorkflowAssessmentImage,
    listItem1: "Initial Consultation",
    listItem2: "Site Survey",
    listItem3: "Layout Planning",
    listItem4: "Custom Design",
    listItem5: "Plan Approval",
  },
  {
    title2: "Precise Installation",
    subtitle2:
      "Our team installs cabling with precision for reliable connectivity.",
    image2: WorkflowInstallationImage,
    listItem6: "Equipment Setup",
    listItem7: "Cable Installation",
    listItem8: "Quality Assurance",
    listItem9: "Testing",
    listItem10: "Configuration",
  },
  {
    title3: "Ongoing Maintenance & Support",
    subtitle3:
      "Continuous monitoring and maintenance ensure your system runs efficiently.",
    image3: WorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Upgrades",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const LowVoltageCabling = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroVoltage}
        serviceTitle="Low-voltage Cabling"
        serviceImage={Voltage}
        heroImageClass="service-image2"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={lowVoltageCardTitles}
        cardSubTitles={lowVoltageCardSubTitles}
        cardImages={lowVoltageCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default LowVoltageCabling;

