import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Data from "../../../../Images/Service/SubPages/data-systems.png";
import HeroData from "../../../../Images/Service/SubPages/HeroImages/DataSystems.png";

// Images for Data Systems service cards
import DataInfraDesignImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/DataInfraDesign.webp";
import DataInfraDeploymentImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/DataInfraDeployment.webp";
import DataInfraIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/DataInfraIntegration.webp";
import DataInfraSupportImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/DataInfraSupport.webp";

// Workflow images for Data Systems (sized 450px x 380px)
import WorkflowConsultDataImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/Workflow/WorkflowConsultData.webp";
import WorkflowIntegrationDataImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/Workflow/WorkflowIntegrationData.webp";
import WorkflowSupportDataImage from "../../../../Images/Service/SubPages/ServicesImages/DataSystems/Workflow/WorkflowSupportData.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Robust Data Infrastructure for Modern Businesses";
const heroSubTitle =
  "Transform your organization with a cutting-edge data infrastructure designed to scale, secure, and streamline your IT operations. Our custom designs lay the foundation for dependable data management.";

// Services Section
const serviceTitle = "Innovative Data Infrastructure Solutions";
const serviceSubTitle =
  "We specialize in building resilient, scalable data infrastructures that support efficient data storage and management, ensuring your business stays competitive.";

// Service Cards (4 cards)
const dataCardTitles = [
  "Strategic Infrastructure Planning",
  "Design & Deployment",
  "System Integration",
  "Ongoing Support & Optimization",
];
const dataCardSubTitles = [
  "Develop a custom data architecture tailored to your operational needs.",
  "Expert design and installation of robust data systems for critical workloads.",
  "Merge new infrastructure with existing systems for peak performance.",
  "Proactive management and continuous improvements to keep your infrastructure efficient.",
];
const dataCardImages = [
  DataInfraDesignImage,
  DataInfraDeploymentImage,
  DataInfraIntegrationImage,
  DataInfraSupportImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Implementation Process";
const workflowSubTitle =
  "Our structured process ensures your data infrastructure is built to last—from initial consultation to full deployment and ongoing support.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Consultation & Assessment",
    subtitle1:
      "We evaluate your current environment and determine the ideal infrastructure strategy.",
    image1: WorkflowConsultDataImage,
    listItem1: "Initial Consultation",
    listItem2: "Environment Audit",
    listItem3: "Needs Analysis",
    listItem4: "Strategic Proposal",
    listItem5: "Plan Finalization",
  },
  {
    title2: "Design & Integration",
    subtitle2:
      "Our team designs a custom data infrastructure and integrates it with your operations.",
    image2: WorkflowIntegrationDataImage,
    listItem6: "Blueprint Development",
    listItem7: "Component Selection",
    listItem8: "System Design",
    listItem9: "Integration Testing",
    listItem10: "Validation",
  },
  {
    title3: "Deployment & Support",
    subtitle3:
      "We deploy your infrastructure and provide continuous support for lasting performance.",
    image3: WorkflowSupportDataImage,
    listItem11: "System Deployment",
    listItem12: "Configuration",
    listItem13: "Quality Assurance",
    listItem14: "Continuous Monitoring",
    listItem15: "Performance Optimization",
  },
];

const DataSystems = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroData}
        serviceTitle="Data Systems"
        serviceImage={Data}
        heroImageClass="service-image3"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={dataCardTitles}
        cardSubTitles={dataCardSubTitles}
        cardImages={dataCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"virtual"} />
    </div>
  );
};

export default DataSystems;
