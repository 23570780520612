import React from 'react'
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography } from "@material-ui/core";
import ServicesCard from './ServicesCard';
import "../Services.css"

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
		justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "flex-start",
		},
	},
    title: {
		fontWeight: "700",
		color: "#000000",
		fontSize: "48px",
        marginTop: "35px",
		[theme.breakpoints.down("xs")]: {
            fontSize: "36px",
		},
	},
    subTitle: {
        fontWeight: "400",
		color: "#2D2D2D",
		fontSize: "1rem",
        maxWidth: "750px",
        margin: "20px 0 40px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            textAlign: "left",
		},
    },

}));

const Services = (props) => {
    const classes = useStyles();
  return (
    <div>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item md={12} sm={12} xs={12} className={classes.container}>
                    <Typography variant="h1" className={classes.title}>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12} className={classes.container}>
                    <Typography variant="subtitle2" className={classes.subTitle}>
                        {props.subtitle}
                    </Typography>
                </Grid>
                <ServicesCard 
                    cardTitles={props.cardTitles}
                    cardSubTitles={props.cardSubTitles}
                    cardImages={props.cardImages}
                />
            </Grid>
        </Container>
	</div>
  )
}

export default Services;
