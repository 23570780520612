import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { Fragment } from "react";

// Accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// DropDown
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";

// Icons
import { SwipeableDrawer } from "@material-ui/core";
import Hamburger from "../../Images/Navbar/Hamburger.svg";
import CloseButton from "../../Images/Navbar/CloseButton.svg";

//Physical
import Physical1 from "../../Images/Navbar/Physical/wifi.png";
import Physical2 from "../../Images/Navbar/Physical/low-voltage.png";
import Physical3 from "../../Images/Navbar/Physical/fiber-optics.png";
import Physical4 from "../../Images/Navbar/Physical/audio-systems.png";
import Physical5 from "../../Images/Navbar/Physical/access-control.png";
import Physical6 from "../../Images/Navbar/Physical/security-cameras.png";
import Physical7 from "../../Images/Navbar/Physical/physical-architecture.png";

// Virtual
import Virtual1 from "../../Images/Navbar/Virtual/cloud-systems.png";
import Virtual2 from "../../Images/Navbar/Virtual/data-systems.png";
import Virtual3 from "../../Images/Navbar/Virtual/data-backup.png";
import Virtual4 from "../../Images/Navbar/Virtual/online-collab.png";
import Virtual5 from "../../Images/Navbar/Virtual/virtual-architecture.png";

//Industry
import Industry1 from "../../Images/Navbar/Industries/Hospitality.png";
import Industry2 from "../../Images/Navbar/Industries/Retail.png";
import Industry3 from "../../Images/Navbar/Industries/Manufacturing.png";
import Industry4 from "../../Images/Navbar/Industries/Legal.png";
import Industry5 from "../../Images/Navbar/Industries/Corporate.png";


const useStyles = makeStyles((theme) => ({
	list: {
		width: "100vw",
		height: "100%",
		// height: "50vh",
		padding: "60px 16px 0",
		overflow: "overlay",
		[theme.breakpoints.down("xs")]: {
			height: "100vh",
		},
	},
	text: {
		fontFamily: "Roboto",
		fontWeight: "600",
		fontSize: "18px",
		color: "#2D2D2D",
	},
	listItem: {
		marginBottom: "30px",
		textAlign: "left",
	},
	link: {
		color: "#032836",
		textDecoration: "none",
	},
	closeButtonStyle: {
		position: "absolute",
		top: "15px",
		right: "15px",
		"&:hover": {
			cursor: "pointer",
		},
	},
	startedButton: {
		marginLeft: "16px",
		padding: "10px 24px !important",
		background: "var(--navbar-color) !important",
		color: "#FFFFFF !important",
		textTransform: "capitalize !important",
		fontFamily: "Roboto !important",
		fontWeight: "700 !important",
		borderRadius: "90px !important",
	},
	icon: {
		fontSize: "12px !important",
		marginRight: "10px !important",
	},
}));

const Physical = [
	"WiFi Networks",
	"Low-voltage Cabling",
	"Fiber Optics",
	"Audio / Video Systems",
	"Access-control Systems",
	"Security Cameras",
	"System Architecture",
];

const Virtual = [
	"Cloud Systems",
	"Data Systems",
	"Data Backup & Protection",
	"Online Collaboration",
	"System Architecture",
];

const Industries = [
	"Retail",
	"Hospitality",
	"Corporate",
	"Manufacturing",
	"Legal",
  ];
  


const PhysicalLinks = [
	"/physical-infrastructure/wifi-networks",
	"/physical-infrastructure/low-voltage-cabling",
	"/physical-infrastructure/fiber-optics",
	"/physical-infrastructure/audio-video-systems",
	"/physical-infrastructure/access-control-systems",
	"/physical-infrastructure/security-cameras",
	"/physical-infrastructure/system-architecture",
];

const VirtualLinks = [
	"/virtual-infrastructure/cloud-systems",
	"/virtual-infrastructure/data-systems",
	"/virtual-infrastructure/data-backup-protection",
	"/virtual-infrastructure/online-collaboration",
	"/virtual-infrastructure/system-architecture",
];

const IndustriesLinks = [
	"/industries/retail",
	"/industries/hospitality",
	"/industries/corporate",
	"/industries/manufacturing",
	"/industries/legal",
  ];

const PhysicalImages = [
	Physical1,
	Physical2,
	Physical3,
	Physical4,
	Physical5,
	Physical6,
	Physical7,
];

const IndustryImages = [
	Industry1, 
	Industry2, 
	Industry3, 
	Industry4, 
	Industry5
];

const VirtualImages = [Virtual1, Virtual2, Virtual3, Virtual4, Virtual5];

export default function MenuDrawer() {
	const classes = useStyles();
	const [state, setState] = useState({ right: false });
	const [colorChange, setColorChange] = useState(false);

	const toggleDrawer = (anchor, open) => (event) => {
		if (event && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setState({ [anchor]: open });
		setColorChange(false);
	};

	const handleClickService = () => {
		setColorChange(!colorChange);
		if (!colorChange) {
			setColorChange(true);
		} else {
			setColorChange(false);
		}
	};

	const guestList = (anchor) => (
		<div
			role="presentation"
			className={classes.list}
			// onClick={toggleDrawer(anchor, false)}
			// onKeyDown={toggleDrawer(anchor, false)}
		>
			<img
				src={CloseButton}
				alt="Close Menu Button"
				className={classes.closeButtonStyle}
				onClick={toggleDrawer(anchor, false)}
			/>
			<List>
				<Link
					to="/about-us"
					className={classes.link}
					onClick={toggleDrawer(anchor, false)}
				>
					<ListItem button className={classes.listItem}>
						<ListItemText>
							<span className={classes.text}>About Us</span>
						</ListItemText>
					</ListItem>
				</Link>
				<Accordion className="accordion-hamburger">
					<AccordionSummary
						className="accordion-summary"
						id={`${colorChange && "colorChange"}`}
						onClick={handleClickService}
						expandIcon={<KeyboardArrowDownIcon />}
					>
						<Typography>Services</Typography>
					</AccordionSummary>
					<AccordionDetails className="accordion-details">
						<div className="stripe"></div>
						<Typography>
							<Link
								to="/physical-infrastructure/"
								className={classes.link}
								onClick={toggleDrawer(anchor, false)}
							>
								<MenuItem disableRipple>Physical Infrastructure</MenuItem>
							</Link>
							{Physical.map((item, i) => (
								<Link
									className={classes.link}
									to={PhysicalLinks[i]}
									onClick={toggleDrawer(anchor, false)}
								>
									<MenuItem disableRipple className="sub-links">
										<img
											src={PhysicalImages[i]}
											alt={Physical[i]}
											className="image"
										/>
										{Physical[i]}
									</MenuItem>
								</Link>
							))}

							<div className="stripe"></div>
							<Link
								to="/virtual-infrastructure/"
								className={classes.link}
								onClick={toggleDrawer(anchor, false)}
							>
								<MenuItem disableRipple>Virtual Infrastructure</MenuItem>
							</Link>
							{Virtual.map((item, i) => (
								<Link
									className={classes.link}
									to={VirtualLinks[i]}
									onClick={toggleDrawer(anchor, false)}
								>
									<MenuItem disableRipple className="sub-links">
										<img
											src={VirtualImages[i]}
											alt={Virtual[i]}
											className="image"
										/>
										{Virtual[i]}
									</MenuItem>
								</Link>
							))}
							
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion className="accordion-hamburger">
					<AccordionSummary
						className="accordion-summary"
						id={`${colorChange && "colorChange"}`}
						onClick={handleClickService}
						expandIcon={<KeyboardArrowDownIcon />}
					>
						<Typography>Industries</Typography>
					</AccordionSummary>
					<AccordionDetails className="accordion-details">
						<div className="stripe"></div>
						<Typography>
							<Link
								to="/industries/"
								className={classes.link}
								onClick={toggleDrawer(anchor, false)}
							>
								<MenuItem disableRipple>Industries We Serve</MenuItem>
							</Link>
							{Industries.map((item, i) => (
								<Link
									className={classes.link}
									to={IndustriesLinks[i]}
									onClick={toggleDrawer(anchor, false)}
								>
									<MenuItem disableRipple className="sub-links">
										<img
											src={IndustryImages[i]}
											alt={Industries[i]}
											className="image"
										/>
										{Industries[i]}
									</MenuItem>
								</Link>
							))}

														
						</Typography>
					</AccordionDetails>
				</Accordion>


				<Link
					to="/contact-us"
					className={classes.link}
					onClick={toggleDrawer(anchor, false)}
				>
					<ListItem button className={classes.listItem}>
						<ListItemText>
							<span className={classes.text}>Contact Us</span>
						</ListItemText>
					</ListItem>
				</Link>

				{/* <Link
					to="/partners"
					className={classes.link}
					onClick={toggleDrawer(anchor, false)}
				>
					<ListItem button className={classes.listItem}>
						<ListItemText>
							<span className={classes.text}>Partners</span>
						</ListItemText>
					</ListItem>
				</Link> */}

				<Link
					to={"/contact-us"}
					className={classes.link}
					onClick={toggleDrawer(anchor, false)}
				>
					<Button
						className={classes.startedButton + ` dropdownButton`}
						endIcon={<ArrowForwardIosIcon className={classes.icon} />}
					>
						Get Started
					</Button>
				</Link>
				<ListItem button className={classes.listItem}>
						<ListItemText>
							<span className={classes.text}>Call us 917-719-4889</span>
						</ListItemText>
					</ListItem>
			</List>
		</div>
	);

	return (
		<div>
			{["right"].map((anchor) => (
				<Fragment key={anchor}>
					<Button
						onClick={toggleDrawer(anchor, true)}
						style={{ height: "44px", maxWidth: "49px" }}
					>
						<img src={Hamburger} alt="Hamburger Menu" />
					</Button>
					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
						onOpen={toggleDrawer(anchor, true)}
						className="drawer"
					>
						{guestList(anchor)}
					</SwipeableDrawer>
				</Fragment>
			))}
		</div>
	);
}
