import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import "./ContactUs.css";

// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";

// Images
import backgroundImage from "../../../Images/Hero/BackgroundImage.jpg";
import Cubes from "../../../Images/Contact Us/waveCubes.png";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    minHeight: "100vh",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    background:
      "linear-gradient(61.54deg, #DADDDD -48.56%, #DADBDD -48.55%, #F0F7FA 72.22%)",
    position: "relative",
    alignItems: "center",
  },
  heroImage: {
    backgroundImage: `url(${backgroundImage})`,
    opacity: "0.5",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "0",
  },
  container: {
    zIndex: "1",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      margin: "30px auto 20px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "50px auto 80px",
    },
  },
  leftContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  title: {
    fontWeight: "700 !important",
    fontFamily: "Roboto !important",
    fontSize: "46px !important",
    textTransform: "capitalize !important",
    marginTop: "10px !important",
    color: "#2D2D2D !important",
  },
  titleHighlight: {
    background: "#FBCC9B",
    height: "15px",
    width: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "270px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "400px",
    },
  },
  paragraph: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    margin: "50px 0 40px !important",
    maxWidth: "450px",
    fontFamily: "Roboto !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "525px",
      margin: "20px 0 25px !important",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "355px",
    },
  },
  formStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: "440px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formTitle: {
    fontWeight: "700 !important",
    fontFamily: "Roboto !important",
    fontSize: "18px !important",
    marginLeft: "10px !important",
  },
  formField: {
    width: "100%",
    background: "#fff !important",
    marginBottom: "20px !important",
  },
  formFieldTextArea: {
    width: "100%",
    background: "#fff !important",
    marginBottom: "30px !important",
  },
  button: {
    padding: "12px 24px !important",
    background: "#07516C !important",
    color: "#fff !important",
    textTransform: "capitalize !important",
    fontFamily: "Roboto !important",
    fontWeight: "700 !important",
    marginTop: "20px !important",
    width: "100%",
    maxWidth: "200px",
    alignSelf: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },
  errorText: {
    color: "red",
    fontSize: "14px",
    marginTop: "4px",
    marginLeft: "10px",
  },
}));

const ContactUs = () => {
  const classes = useStyles();

  // State to store form data
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  // State for error messages
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  // Loading state for button spinner
  const [loading, setLoading] = useState(false);

  // Regex for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?[0-9\s\-()]{10,}$/;

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setErrors({ ...errors, [e.target.id]: "" });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    const newErrors = { name: "", phone: "", email: "", message: "" };

    // Validate full name (at least 2 characters)
    if (formData.name.trim().length < 2) {
      newErrors.name = "Name must be at least 2 characters.";
      valid = false;
    }

    // Validate email format
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
    }

    // Validate phone only if provided
    if (formData.phone.trim() && !phoneRegex.test(formData.phone.trim())) {
      newErrors.phone = "Please enter a valid phone number.";
      valid = false;
    }

    // Validate message (non-empty)
    if (!formData.message.trim()) {
      newErrors.message = "Please enter a message.";
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        alert("Message sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" });
      } else {
        alert("Error sending message.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  return (
    <div className={classes.contactUs}>
      <div className={classes.heroImage}></div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12} className={classes.leftContainer}>
            <img src={Cubes} alt="Wave Cubes" />
            <Typography variant="h2" className={classes.title}>
              Reliable IT solutions start with a conversation
            </Typography>
            <div className={classes.titleHighlight}></div>
            <Typography variant="subtitle1" className={classes.paragraph}>
              Tell us what you need by submitting the form below.
              Our team will review your request and get back to you as soon as possible with expert
              guidance tailored to your IT needs. Need a quicker response? 
            </Typography>
            <Typography variant="subtitle1" className={classes.paragraph}>
            Start a chat using the bubble at the bottom of the page, or call us at 917-719-4889.
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className={classes.rightContainer}>
            <form onSubmit={handleSubmit} autoComplete="off" className={classes.formStyle}>
              <Typography variant="subtitle1" className={classes.formTitle}>
                Full Name
              </Typography>
              <TextField
                size="small"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                variant="outlined"
                className={classes.formField}
              />
              {errors.name && (
                <Typography className={classes.errorText}>{errors.name}</Typography>
              )}

              <Typography variant="subtitle1" className={classes.formTitle}>
                Phone Number
              </Typography>
              <TextField
                size="small"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                variant="outlined"
                className={classes.formField}
              />
              {errors.phone && (
                <Typography className={classes.errorText}>{errors.phone}</Typography>
              )}

              <Typography variant="subtitle1" className={classes.formTitle}>
                E-mail
              </Typography>
              <TextField
                size="small"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                variant="outlined"
                className={classes.formField}
              />
              {errors.email && (
                <Typography className={classes.errorText}>{errors.email}</Typography>
              )}

              <Typography variant="subtitle1" className={classes.formTitle}>
                How can we assist?
              </Typography>
              <TextField
                id="message"
                multiline
                rows={6}
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message here..."
                variant="outlined"
                className={classes.formFieldTextArea}
              />
              {errors.message && (
                <Typography className={classes.errorText}>{errors.message}</Typography>
              )}

              <Button type="submit" className={classes.button}>
                {loading ? <CircularProgress size={24} color="inherit" /> : (
                  <>
                    Send
                    <ArrowForwardIosIcon className={classes.icon} />
                  </>
                )}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactUs;
