import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {
	Container,
	Grid,
	Typography,
	TextField,
	Button,
} from "@material-ui/core";
import "./AboutUs.css";

// Images
import Quotes from "../../../Images/About Us/reviewQuotes.png";
import Stars from "../../../Images/About Us/stars.png";

const useStyles = makeStyles((theme) => ({
	reviews: {
		position: "relative",
	},
	sectionBackground: {
		background:
			"linear-gradient(260.18deg, #DADBDD 7.94%, #DADBDD 7.96%, #FFFFFF 149.29%)",
		opacity: "0.5",
		position: "absolute",
		width: "100%",
		height: "100%",
	},
	container: {
		padding: "80px 24px !important",
		[theme.breakpoints.down("xs")]: {
			padding: "80px 24px 0 !important",
		},
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "36px",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "38px",
			marginBottom: "15px",
			textAlign: "unset",
		},
	},
	titleHighlight: {
		background: "#07516C",
		height: "15px",
		width: "500px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
			margin: "unset",
		},
	},
	reviewContainerGrid: {
		zIndex: "1",
		marginBottom: "80px",
		[theme.breakpoints.down("xs")]: {
			marginBottom: "40px",
		},
	},
	reviewContainer: {
		marginLeft: "35px",
	},
	titleDate: {
		display: "flex",
		position: "relative",
	},
	titleReview: {
		fontWeight: "700 !important",
		// fontFamily: "Roboto !important",
	},
	date: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		marginLeft: "5px !important",
	},
	quotes: {
		position: "absolute",
		left: "-35px",
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "450px",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "340px",
		},
	},
}));

const title = ["Ran Buchnik", "Aylee Schneider", "עמרי דליהו", "Shlomi Atash"];
const date = ["sep 1. 2018", "jul 27. 2018", "aug 12. 2018", "jul 30. 2018"];
const desc = [
	"When I worked with Kinetx I immediately knew I'm getting a high-level, professional service. Their services were on-point and for a good price but also saved my business a lot of money too. Their way of approaching issues and seeing the big picture for my business helped us scale up quickly while doing things the right way - thus saving us a lot of time and money.",
	"When selecting any technology partner, you should ask yourself what is the difference between Kinetx and every other company out there like them. The answer: ingenuity and creativity. Kinetx has some of the best solution architects in the field. Their team crafts custom solutions for home/businesses which will enable productivity and enhance capabilities. I’ve used Kinetx’s services for more than 5 years and couldn’t be happier.",
	"We worked with Kinetx to set the office's secured wireless network along all the data systems and backup systems. The service we received was of exceptional quality and professionalism. The personal attitude and the great courtesy is the reason we use their services to this day.",
	"I’ve been using Kinetx services for my business for a while and am very satisfied. Raviv and his team are professional and super creative. They always deliver the solution I want and need. Highly recommend!",
];

const Reviews = () => {
	const classes = useStyles();
	return (
		<div className={classes.reviews}>
			<div className={classes.sectionBackground}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container>
					<Grid item md={12} sm={12} xs={12} style={{ zIndex: "1" }}>
						<div>
							<Typography variant="h2" className={classes.title}>
								Client Reviews
							</Typography>
							<div className={classes.titleHighlight}></div>
						</div>
					</Grid>
				</Grid>
				<Grid container style={{ marginTop: "40px" }}>
					{title.map((item, i) => (
						<Grid
							item
							md={6}
							sm={6}
							xs={12}
							className={classes.reviewContainerGrid}
						>
							<div className={classes.reviewContainer}>
								<div className={classes.titleDate}>
									<img src={Quotes} alt="Quotes" className={classes.quotes} />
									<Typography
										variant="subtitle1"
										className={classes.titleReview}
									>
										{title[i]}
									</Typography>
									<Typography variant="subtitle1" className={classes.date}>
										{date[i]}
									</Typography>
								</div>
								<img src={Stars} alt="5 Stars" />
								<Typography variant="subtitle1" className={classes.paragraph}>
									{desc[i]}
								</Typography>
							</div>
						</Grid>
					))}
				</Grid>
			</Container>
		</div>
	);
};

export default Reviews;
