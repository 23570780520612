import React from "react";
import "./Homepage.css";

// Components
import Hero from "./PageComponents/Hero/Hero";
import Pitch from "./PageComponents/Pitch/Pitch";
import Services from "./PageComponents/Services/Services";
import Partners from "./PageComponents/Partners/Partners";
import Consult from "./PageComponents/Consult/Consult";

const Homepage = () => {
	return (
		<>
			<Hero />
			<Pitch />
			<Services />
			<Partners />
			<Consult color={"homepage"} />
		</>
	);
};

export default Homepage;
