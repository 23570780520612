import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button, Box } from "@material-ui/core";
import "./AboutUs.css";

// Images
import Big from "../../../Images/About Us/bigSpiral.png";
import BigMobile from "../../../Images/About Us/bigSpiralMobile.png";
import Small from "../../../Images/About Us/smallSpiral.png";
import SmallMobile from "../../../Images/About Us/smallSpiralMobile.png";
import Small1 from "../../../Images/About Us/smallSpiral1.png";
import Small2 from "../../../Images/About Us/smallSpiral2.png";

const useStyles = makeStyles((theme) => ({
	ourServices: {
		position: "relative",
	},
	sectionBackground: {
		background: "#DADBDD",
		opacity: "0.3",
		position: "absolute",
		width: "100%",
		height: "100%",
	},
	container: {
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "100px 0",
		[theme.breakpoints.down("xs")]: {
			flexFlow: "column-reverse",
		},
	},
	leftContainer: {
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexFlow: "column",
			alignItems: "center",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "unset",
		},
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "36px",
		marginTop: "10px",
		maxWidth: "480px",
		textTransform: "none !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "35px",
			textAlign: "unset",
			maxWidth: "350px",
		},
	},
	titleHighlight: {
		background: "#ADCFDB",
		height: "15px",
		maxWidth: "500px",
		width: "100%",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
		},
	},
	rightContainer: {
		zIndex: "1",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-start",
		},
	},
	smallSpiralContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "70px",
		maxWidth: "500px",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "unset",
		},
	},
	smallSpiralMobile: {
		[theme.breakpoints.down("xs")]: {
			margin: "0 10px",
		},
	},
	spiralTitles: {
		fontFamily: "Roboto !important",
		color: "#2D2D2D !important",
		fontSize: "20px",
		maxWidth: "145px",
		lineHeight: "24px !important",
		[theme.breakpoints.down("sm")]: {
			fontWeight: "700",
		},
		[theme.breakpoints.down("xs")]: {
			fontWeight: "400",
			fontSize: "16px",
		},
	},
	smallSpiralImage: {
		[theme.breakpoints.down("xs")]: {
			width: "105px",
		},
	},
	bigSpiralImage: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
			height: "120px",
		},
	},
	spacingMargin: {
		marginTop: "120px",
	},
}));

const title1 = ["Physical Infrastructure", "Virtual Infrastructure"];
const title2 = ["Keep your business safe", "Communication freedom"];
const image = [Small1, Small2];

const OurServices = () => {
	const classes = useStyles();
	return (
		<div className={classes.ourServices}>
			<div className={classes.sectionBackground}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} sm={12} xs={12} className={classes.leftContainer}>
						<div>
							<Typography variant="h2" className={classes.title}>
								How our services <br /> help your business
							</Typography>
							<div className={classes.titleHighlight}></div>
						</div>
						{title1.map((item, i) => (
							<div
								className={`${classes.smallSpiralContainer}
							${i === 1 ? classes.spacingMargin : ""}
							`}
							>
								<Typography
									variant="subtitle1"
									className={classes.spiralTitles}
								>
									{title1[i]}
								</Typography>

								<img
									src={image[i]}
									alt="Small Spiral"
									className={classes.smallSpiralImage}
								/>
								<Typography
									variant="subtitle1"
									className={classes.spiralTitles}
								>
									{title2[i]}
								</Typography>
							</div>
						))}
					</Grid>
					<Grid item md={6} sm={6} xs={12} className={classes.rightContainer}>
						<img
							src={Big}
							alt="Big Spiral"
							className={classes.bigSpiralImage}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default OurServices;
