import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images
import Fiber from "../../../../Images/Service/SubPages/fiber-optics.png";
import HeroFiber from "../../../../Images/Service/SubPages/HeroImages/Fiber.png";

// Custom images for Fiber Optics service cards
import FiberConsultationImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/FiberConsultation.webp";
import FiberInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/FiberInstallation.webp";
import FiberConnectivityImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/FiberConnectivity.webp";
import FiberMaintenanceImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/FiberMaintenance.webp";

// Workflow images for Fiber Optics (from the specified directory)
import WorkflowConsultationImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/Workflow/WorkflowConsultation.webp";
import WorkflowInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/Workflow/WorkflowInstallation.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/FiberOptics/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

const heroTitle = "Harness the Power of Fiber Optics";
const heroSubTitle =
	"Our fiber optics installations are engineered for expansive spaces and data-intensive environments, delivering high-speed, high-capacity connectivity where traditional cabling falls short.";

const serviceTitle = "Specialized Fiber Optics Solutions";
const serviceSubTitle = "Designed exclusively for large-scale applications, our fiber optics solutions provide robust, high-performance connectivity for environments demanding extensive bandwidth and minimal signal loss.";

const vendorsTitle = "Trusted Enterprise Partners";

const workflowTitle = "Our Deployment Process";
const workflowSubTitle = "Combining expert consultation, precision installation, and ongoing support, we deliver fiber optics connectivity tailored for large spaces and high-demand networks.";
const workflowCardItems = [
	{
		title1: "Consultation & Site Analysis",
		subtitle1: "We assess your environment to design a fiber optics solution that meets the specific demands of large-scale networks.",
		image1: WorkflowConsultationImage,
		listItem1: "Initial Consultation",
		listItem2: "Site Analysis",
		listItem3: "Needs Assessment",
		listItem4: "Custom Design",
		listItem5: "Proposal Review",
	},
	{
		title2: "Precision Installation",
		subtitle2: "Our expert team installs fiber systems with meticulous attention to detail, ensuring optimal performance in high-demand, expansive environments.",
		image2: WorkflowInstallationImage,
		listItem6: "Expert Setup",
		listItem7: "Quality Cabling",
		listItem8: "Optimal Configuration",
		listItem9: "Rigorous Testing",
		listItem10: "Performance Validation",
	},
	{
		title3: "Ongoing Network Support",
		subtitle3: "We provide continuous monitoring and maintenance, ensuring your fiber network remains efficient, secure, and scalable over time.",
		image3: WorkflowSupportImage,
		listItem11: "Proactive Monitoring",
		listItem12: "Regular Maintenance",
		listItem13: "24/7 Support",
		listItem14: "System Upgrades",
		listItem15: "Performance Reviews",
	},
];

// Custom Fiber Optics service card data
const fiberCardTitles = [
  "Customized Consultation",
  "Expert Installation",
  "High-Capacity Connectivity",
  "Reliable Maintenance"
];

const fiberCardSubTitles = [
  "We assess large-scale network needs and design tailored fiber optics solutions.",
  "Our skilled team installs fiber systems with precision and high-quality components.",
  "Experience robust, high-speed connectivity built for data-intensive environments.",
  "Ongoing support ensures your fiber network remains efficient and scalable."
];

const fiberCardImages = [
  FiberConsultationImage,
  FiberInstallationImage,
  FiberConnectivityImage,
  FiberMaintenanceImage
];

const FiberOptics = () => {
	const classes = useStyles();
	return (
		<div>
			<Hero
				title={heroTitle}
				subtitle={heroSubTitle}
				image={HeroFiber}
				serviceTitle="Fiber Optics"
				serviceImage={Fiber}
				heroImageClass="service-image2 service-hero-image-fiber"
				fiberClass="service-image2 fiber-class-show"
			/>
			<Services 
				title={serviceTitle}
				subtitle={serviceSubTitle}
				cardTitles={fiberCardTitles}
				cardSubTitles={fiberCardSubTitles}
				cardImages={fiberCardImages}
			/>
			<Vendors 
				title={vendorsTitle}
			/>
			<Workflow 
				title={workflowTitle}
				subtitle={workflowSubTitle}
				title1={workflowCardItems[0].title1}
				title2={workflowCardItems[1].title2}
				title3={workflowCardItems[2].title3}
				subtitle1={workflowCardItems[0].subtitle1}
				subtitle2={workflowCardItems[1].subtitle2}
				subtitle3={workflowCardItems[2].subtitle3}
				image1={workflowCardItems[0].image1}
				image2={workflowCardItems[1].image2}
				image3={workflowCardItems[2].image3}
				listItem1={workflowCardItems[0].listItem1}
				listItem2={workflowCardItems[0].listItem2}
				listItem3={workflowCardItems[0].listItem3}
				listItem4={workflowCardItems[0].listItem4}
				listItem5={workflowCardItems[0].listItem5}
				listItem6={workflowCardItems[1].listItem6}
				listItem7={workflowCardItems[1].listItem7}
				listItem8={workflowCardItems[1].listItem8}
				listItem9={workflowCardItems[1].listItem9}
				listItem10={workflowCardItems[1].listItem10}
				listItem11={workflowCardItems[2].listItem11}
				listItem12={workflowCardItems[2].listItem12}
				listItem13={workflowCardItems[2].listItem13}
				listItem14={workflowCardItems[2].listItem14}
				listItem15={workflowCardItems[2].listItem15}
			/>
			<Consult color={"physical"} />
		</div>
	);
};

export default FiberOptics;
