import React from 'react'
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography } from "@material-ui/core";
import Card from "./WorkflowCard";
import "../Services.css"

const useStyles = makeStyles((theme) => ({
   divContainer: {
        backgroundColor: 'rgb(227 242 243 / 30%)',
   },
   container: {
        padding: "50px 24px 120px",
        [theme.breakpoints.down("xs")]: {
            padding: "50px 16px",
        },
   },
   gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column',
        alignItems: 'center',
   },
   title: {
        fontWeight: "700",
        color: "#032836",
        fontSize: "48px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "36px",
        },
    },
    subTitle: {
        fontWeight: "400",
		color: "#2D2D2D",
        maxWidth: "750px",
        margin: "20px 0 70px",
        textAlign: "center",
    },
}));

const Workflow = (props) => {
    const classes = useStyles();
  return (
    <div className={classes.divContainer}>
        <Container maxWidth="lg" className={classes.container}>
            <Grid container>
                <Grid item md={12} sm={12} xs={12} className={classes.gridContainer}>
                    <Typography variant="h1" className={classes.title}>
                        {props.title}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
            <Card 
                title1={props.title1}
                title2={props.title2}
                title3={props.title3}
                subtitle1={props.subtitle1}
                subtitle2={props.subtitle2}
                subtitle3={props.subtitle3}
                image1={props.image1}
                image2={props.image2}
                image3={props.image3}
                listItem1={props.listItem1}
                listItem2={props.listItem2}
                listItem3={props.listItem3}
                listItem4={props.listItem4}
                listItem5={props.listItem5}
                listItem6={props.listItem6}
                listItem7={props.listItem7}
                listItem8={props.listItem8}
                listItem9={props.listItem9}
                listItem10={props.listItem10}
                listItem11={props.listItem11}
                listItem12={props.listItem12}
                listItem13={props.listItem13}
                listItem14={props.listItem14}
                listItem15={props.listItem15}
            />
        </Container>
    </div>
  )
}

export default Workflow;
