import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Backup from "../../../../Images/Service/SubPages/data-backup.png";
import HeroBackup from "../../../../Images/Service/SubPages/HeroImages/DataBackup.png";

// Images for Data Backup service cards
import BackupStrategyImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/BackupStrategy.webp";
import BackupDeploymentImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/BackupDeployment.webp";
import DataRecoveryImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/DataRecovery.webp";
import BackupSupportImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/BackupSupport.webp";

// Workflow images for Data Backup (sized 450px x 380px)
import WorkflowAuditImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/Workflow/WorkflowAudit.webp";
import WorkflowIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/Workflow/WorkflowIntegration.webp";
import WorkflowOptimizationImage from "../../../../Images/Service/SubPages/ServicesImages/DataBackup/Workflow/WorkflowOptimization.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Secure Data Backup & Protection for Peace of Mind";
const heroSubTitle =
  "Safeguard your critical data with our comprehensive backup solutions and robust protection strategies. We design, deploy, and maintain backup systems that ensure data integrity and minimize risk.";

// Services Section
const serviceTitle = "Comprehensive Data Backup Solutions";
const serviceSubTitle =
  "Our services cover everything from strategic planning to deployment and ongoing management, ensuring your valuable information is securely backed up and protected.";

// Service Cards (4 cards)
const backupCardTitles = [
  "Backup Strategy & Planning",
  "Advanced Backup Deployment",
  "Data Recovery Solutions",
  "Ongoing Management & Support",
];
const backupCardSubTitles = [
  "Develop a tailored backup plan aligned with your business requirements.",
  "Implement state-of-the-art backup systems for secure data storage.",
  "Efficient recovery strategies to minimize downtime and data loss.",
  "Continuous monitoring and updates to keep your backup system effective.",
];
const backupCardImages = [
  BackupStrategyImage,
  BackupDeploymentImage,
  DataRecoveryImage,
  BackupSupportImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Data Protection Process";
const workflowSubTitle =
  "Our structured process ensures your data is protected from the start, from consultation to continuous improvement.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Consultation & Needs Assessment",
    subtitle1:
      "We evaluate your current data infrastructure and identify vulnerabilities.",
    image1: WorkflowAuditImage,
    listItem1: "Initial Consultation",
    listItem2: "Data Audit",
    listItem3: "Risk Assessment",
    listItem4: "Strategy Development",
    listItem5: "Plan Approval",
  },
  {
    title2: "System Deployment & Integration",
    subtitle2:
      "Our experts deploy and integrate robust backup systems tailored to your needs.",
    image2: WorkflowIntegrationImage,
    listItem6: "Infrastructure Setup",
    listItem7: "System Installation",
    listItem8: "Data Migration",
    listItem9: "Integration Testing",
    listItem10: "Go-Live Support",
  },
  {
    title3: "Continuous Monitoring & Optimization",
    subtitle3:
      "We monitor your backup systems to ensure peak performance and continuous improvement.",
    image3: WorkflowOptimizationImage,
    listItem11: "Performance Monitoring",
    listItem12: "Regular Updates",
    listItem13: "Security Checks",
    listItem14: "Troubleshooting",
    listItem15: "Optimization Reviews",
  },
];

const DataBackupProtection = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroBackup}
        serviceTitle="Data Backup & Protection"
        serviceImage={Backup}
        heroImageClass="service-image"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={backupCardTitles}
        cardSubTitles={backupCardSubTitles}
        cardImages={backupCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"virtual"} />
    </div>
  );
};

export default DataBackupProtection;
