import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./Service.css";

// Images (unchanged)
import Virtual1 from "../../../Images/Service/virtual1.png";
import Virtual2 from "../../../Images/Service/virtual2.png";
import Virtual3 from "../../../Images/Service/virtual3.png";
import Virtual4 from "../../../Images/Service/virtual4.png";

const useStyles = makeStyles((theme) => ({
	projects: {
		display: "flex",
		alignItems: "center",
		position: "relative",
	},
	projectsImage: {
		background: "#FFFFFF",
		opacity: "0.5",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "60px 0 100px",
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "36px !important",
		textTransform: "capitalize !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	titleHighlight: {
		background: "#ADCFDB",
		width: "400px",
		opacity: "0.4",
		marginBottom: "60px",
		height: "15px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto 60px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
			margin: "0 0 60px",
		},
	},
	gridContainerProjects: {
		justifyContent: "space-between",
	},
	projectContainer: {
		display: "flex",
		position: "relative",
		background: "#eff6f8",
		marginBottom: "60px",
		justifyContent: "space-around",
		alignItems: "center",
		padding: "20px 10px",
		maxWidth: "48% !important",
		[theme.breakpoints.down("sm")]: {
			padding: "15px 10px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "100% !important",
		},
	},
	infoContainer: {
		width: "340px",
		padding: "0 10px",
		[theme.breakpoints.down("sm")]: {
			width: "100% !important",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "340px !important",
			padding: "0 0 0 15px",
		},
	},
	cubeImage: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
			border: "1px solid #000",
			padding: "5px",
		},
	},
	cubeImageTablet: {
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			height: "100%",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	subTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "20px",
		marginBottom: "5px",
		maxWidth: "270px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto 15px",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "0 0 15px",
			fontSize: "18px",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "340px",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	paragraphTablet: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		maxWidth: "340px",
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
}));

const titleArray = [
	"Cloud Based",
	"Data Systems",
	"Data Protection",
	"Online Collaboration",
];

const paragraphArray = [
	"Empower your operations with robust cloud solutions. Enjoy scalable, secure, and flexible access to your critical data around the clock.",
	"Transform your workflows with integrated data platforms that enable seamless collaboration and boost efficiency.",
	"Safeguard your essential information with comprehensive security measures. Our backup systems shield your data from threats.",
	"Unite your team with state-of-the-art collaboration tools. Experience real-time communication that drives productivity.",
];

const serviceLinks = [
	"/virtual-infrastructure/cloud-systems",
	"/virtual-infrastructure/data-systems",
	"/virtual-infrastructure/data-backup-protection",
	"/virtual-infrastructure/online-collaboration",
];

const images = [Virtual1, Virtual2, Virtual3, Virtual4];

const VirtualProjects = () => {
	const classes = useStyles();
	return (
		<div className={classes.projects}>
			<div className={classes.projectsImage}></div>
			<Container className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={12} style={{ zIndex: "1" }}>
						<Typography variant="h2" className={classes.title}>
							Our Services
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Grid container className={classes.gridContainerProjects}>
							{titleArray.map((item, i) => (
								<Grid
									item
									md={6}
									sm={6}
									xs={12}
									className={classes.projectContainer}
									key={i}
								>
									<div>
										<Link to={serviceLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
											<img
												src={images[i]}
												className={classes.cubeImage}
												alt="Service Illustration"
											/>
										</Link>
									</div>
									<div className={classes.infoContainer}>
										<Link to={serviceLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
											<Typography variant="h2" className={classes.subTitle}>
												{titleArray[i]}
											</Typography>
											<Typography
												variant="subtitle1"
												className={classes.paragraph}
												dangerouslySetInnerHTML={{ __html: paragraphArray[i] }}
											></Typography>
										</Link>
										<div style={{ display: "flex", alignItems: "center" }}>
											<Typography
												variant="subtitle1"
												className={classes.paragraphTablet}
												dangerouslySetInnerHTML={{ __html: paragraphArray[i] }}
											></Typography>
											<Link to={serviceLinks[i]} style={{ textDecoration: "none", color: "inherit" }}>
												<img
													src={images[i]}
													className={classes.cubeImageTablet}
													alt="Service Illustration Tablet"
												/>
											</Link>
										</div>
									</div>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default VirtualProjects;
