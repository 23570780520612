import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Hero.css";

// Images
import Boxes from "../../../Images/Hero/Boxes.png";
import BoxesTablet from "../../../Images/Hero/BoxesTablet.png";
import BoxesMobile from "../../../Images/Hero/BoxesMobile.png";
import backgroundImage from "../../../Images/Hero/BackgroundImage.jpg";

const useStyles = makeStyles((theme) => ({
	hero: {
		// height: "90vh",
		minHeight: "90vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background: "linear-gradient(132.47deg, #032836 19.24%, #07516C 81.68%)",
		alignItems: "center",
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			background: "linear-gradient(132.47deg, #032836 10.5%, #07516C 74.99%)",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
			minHeight: "75vh",
			background: "linear-gradient(65.73deg, #032836 9.16%, #07516C 85%)",
		},
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			margin: "20px auto 140px",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	heroImage: {
		// backgroundImage: `url(${backgroundImage})`,
		opacity: "0.6",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	gridContainer: {
		[theme.breakpoints.down("sm")]: {
			justifyContent: "space-between",
		},
	},
	desktopCube: {
		[theme.breakpoints.down("md")]: {
			height: "400px",
		},
	},
	leftContainer: {
		display: "flex",
		flexFlow: "column",
		justifyContent: "center",
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		color: "#fff !important",
		fontSize: "46px !important",
		[theme.breakpoints.down("sm")]: {
			fontSize: "40px !important",
			lineHeight: "52px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px !important",
			lineHeight: "40px !important",
		},
	},
	highlight: {
		background: "#047394",
		color: "#fff",
	},
	paragraph: {
		fontWeight: "400 !important",
		fontSize: "18px !important",
		margin: "30px 0 !important",
		color: "#fff !important",
		fontFamily: "Roboto !important",
		maxWidth: "500px !important",
		[theme.breakpoints.down("sm")]: {
			fontSize: "17px !important",
			maxWidth: "380px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px !important",
			maxWidth: "400px !important",
			lineHeight: "24px !important",
		},
	},
	button: {
		padding: "12px 24px",
		background: "#047394",
		color: "#FFFFFF",
		textTransform: "capitalize",
		fontFamily: "Roboto",
		fontWeight: "700",
	},
	icon: {
		fontSize: "10px !important",
		marginRight: "10px !important",
	},
	gridImage: {
		display: "flex !important",
		justifyContent: "flex-end !important",
		// [theme.breakpoints.down("md")]: {

		// },
		[theme.breakpoints.down("xs")]: {
			display: "none !important",
		},
	},
	gridImageMobile: {
		display: "none !important",
		justifyContent: "flex-end !important",
		[theme.breakpoints.down("xs")]: {
			display: "flex !important",
		},
	},
	text: {
		textAlign: "center",
		textTransform: "uppercase !important",
		fontWeight: "400 !important",
		fontSize: "16px !important",
		fontFamily: "Roboto !important",
		color: "#fff !important",
	},
	stripe: {
		width: "3px",
		height: "60px",
	},
	exploreMore: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		bottom: "0px",
		position: "absolute",
	},
}));

const Hero = () => {
	const classes = useStyles();

	const openZohoChat = () => {
		if (window.$zoho && window.$zoho.salesiq) {
		  if (window.$zoho.salesiq.chat && typeof window.$zoho.salesiq.chat.start === "function") {
			window.$zoho.salesiq.chat.start();
		  } else {
			console.error("Zoho SalesIQ chat API not ready.");
		  }
		} else {
		  console.error("Zoho SalesIQ script not loaded.");
		}
	  };
	  
	return (
		<div className={classes.hero}>
			<div className={classes.heroImage}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item xs={12} className={classes.gridImageMobile}>
						<img src={BoxesMobile} alt="Small Cubes" />
					</Grid>
					<Grid item md={6} sm={8} xs={12} className={classes.leftContainer}>
						<Typography variant="h2" className={classes.title}>
							Tailored IT {<br />} for Exceptional {<br />}{" "}
							<span className={classes.highlight}>Business Operations</span>
						</Typography>
						<Typography variant="subtitle2" className={classes.paragraph}>
							We deliver custom, integrated technologies that empower
							businesses to excel. Proactive support, expert design,
							and efficient growth through every aspect of IT.
						</Typography>

						<Button
							className={classes.button}
							endIcon={<ArrowForwardIosIcon className={classes.icon} />}
							onClick={() => {
								openZohoChat();
							}}
						>
							Get Started
						</Button>

					</Grid>
					<Grid item md={6} sm={4} className={classes.gridImage}>
						<Box display={{ xs: "none", sm: "none", md: "block" }}>
							<img src={Boxes} alt="Cubes" className={classes.desktopCube} />
						</Box>
						<Box
							display={{ xs: "block", sm: "flex", md: "none" }}
							style={{ alignItems: "center" }}
						>
							<img
								src={BoxesTablet}
								alt="Tablet Cubes"
								className="tabletCube"
							/>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<div className={classes.exploreMore}>
				<Typography variant="subtitle1" className={classes.text}>
					Explore the possibilities
				</Typography>
				<span className={classes.stripe + ` colorAnimation`}></span>
			</div>
		</div>
	);
};

export default Hero;
