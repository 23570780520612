import React from "react";
import { makeStyles } from "@material-ui/core";
import "./Service.css";

// Components
import Industries from "./Industries";
import IndustriesType from "./IndustriesType";
import Solution from "./Solution";
import Consult from "./Consult";

const useStyles = makeStyles((theme) => ({
	service: {},
}));

const Service = () => {
	const classes = useStyles();
	return (
		<div className={classes.service}>
			<Industries />
			<IndustriesType />
			<Solution />
			<Consult color={"physical"} />
		</div>
	);
};

export default Service;
