import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button, Box } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Pitch.css";
import { Link } from "react-router-dom";

// Images
import CubeTitle from "../../../Images/Pitch/cube.png";
import WeImg from "../../../Images/Pitch/we.png";
import GrowImg from "../../../Images/Pitch/grow.png";
import TogetherImg from "../../../Images/Pitch/together.png";
import WhitePaperImg from "../../../Images/Pitch/white-paper.png";
import TabletImage from "../../../Images/Pitch/tabletImage.png";
import MobileImage from "../../../Images/Pitch/mobileImage.png";

const useStyles = makeStyles((theme) => ({
	pitch: {
		background: "#e3f2f391",
		position: "relative",
	},
	pitchImage: {
		background: `url(${WhitePaperImg})`,
		backgroundSize: "960px 100%",
		backgroundRepeat: "no-repeat",
		opacity: "0.5",
		position: "absolute",
		width: "50%",
		height: "100%",
		zIndex: "0",
		right: "0",
		[theme.breakpoints.down("sm")]: {
			display: "none !important",
		},
	},
	container: {
		padding: "60px 24px !important",
	},
	weGrowTogether: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			display: "none !important",
		},
	},
	cubeImgMobile: {
		display: "none !important",
		[theme.breakpoints.down("sm")]: {
			display: "flex !important",
		},
	},
	pitchTitle: {
		fontSize: "36px !important",
		marginBottom: "30px !important",
		width: "fit-content",
		fontFamily: "Roboto !important",
		fontWeight: "600 !important",
		color: "#2D2D2D !important",
	},
	pitchDescription: {
		color: "#2D2D2D !important",
		fontFamily: "Roboto !important",
		fontWeight: "400 !important",
		fontSize: "16px !important",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "510px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "350px",
		},
	},
	highlight: {
		position: "absolute",
		background: "#FBCC9B",
		top: "-1px",
		height: "20px",
		width: "100%",
		left: "0",
	},
	cubeImg: {
		height: "83px",
		position: "absolute",
		top: "-36px",
		right: "-26px",
		[theme.breakpoints.down("sm")]: {
			right: "45px",
		},
		[theme.breakpoints.down("xs")]: {
			left: "0",
			top: "-16px",
			height: "60px",
		},
	},
	leftContent: {
		display: "flex",
		flexDirection: "column !important",
		justifyContent: "center",
		padding: "150px 24px 150px 0",
		[theme.breakpoints.down("sm")]: {
			padding: "0",
			display: "none !important",
		},
	},
	rightContent: {
		padding: "150px 40px 150px 64px",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexFlow: "column",
			alignItems: "center",
			padding: "0",
			marginBottom: "40px",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "unset",
		},
	},
	button: {
		padding: "12px 24px",
		background: "#047394",
		color: "#FFFFFF",
		textTransform: "capitalize",
		fontFamily: "Roboto",
		fontWeight: "700",
		
	},
}));

const Pitch = () => {
	const classes = useStyles();
	return (
		<div className={classes.pitch}>
			{/* <div className={classes.pitchImage}></div> */}
			<Container maxWidth="lg" className={classes.container}>
				<Grid container>
					<Grid item md={6} className={classes.leftContent}>
						<div className={classes.weGrowTogether}>
							<div
								className={classes.weImg}
								data-aos="fade-right"
								data-aos-delay="200"
							>
								<img
									src={WeImg}
									alt="We Cube"
									style={{ marginRight: "30px" }}
								/>
							</div>
							<div
								className={classes.weImg}
								data-aos="fade-right"
								data-aos-delay="700"
							>
								<img
									src={GrowImg}
									alt="Growing Cube"
									style={{ marginRight: "30px" }}
								/>
							</div>
							<div
								className={classes.weImg}
								data-aos="fade-right"
								data-aos-delay="1200"
							>
								<img
									src={TogetherImg}
									alt="Together Cube"
									style={{ marginRight: "30px" }}
								/>
							</div>
						</div>
					</Grid>
					<Grid item md={6} sm={12} xs={12} className={classes.rightContent}>
						<Typography variant="h4" className={classes.pitchTitle}>
							<span style={{ position: "relative" }}>
								<span style={{ position: "relative", zIndex: "2" }}>
								Why Kinetx Co
								</span>
								<span className={classes.highlight}></span>
								<img
									src={CubeTitle}
									alt="Title Cube"
									className={classes.cubeImg}
								/>
							</span>
						</Typography>

						<Typography
							variant="subtitle1"
							className={classes.pitchDescription}
						>
							We design and manage IT systems that simplify
							operations and ensure business continuity. 
							<br />
							<br />
							From network infrastructure and security to communication and digital 
							platforms, Kinetx is the backbone of the  business technology. Making 
							sure every system runs reliably in the background.
							<br />
							<br />
							Our team, committed to maintain the business technology, 
							delivering proactive management, expert solutions, and continuous optimization 
							to enhance performance, reduce downtime, and support long-term growth with 
							efficiency and reliability.
							<br />
							<br />
							<strong>
								We provide the tech peace of mind for business growth.
							</strong>
							<br />
							<br />
								<Link to={"/contact-us"} style={{ textDecoration: "none" }}>
							<Button
								className={classes.button}
								endIcon={<ArrowForwardIosIcon className={classes.icon} />}
							>
								Get Started
							</Button>
							</Link>
						</Typography>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Box display={{ xs: "block", sm: "none" }}>
							<img
								src={MobileImage}
								alt="Smaller Cubes"
								className={classes.cubeImgMobile}
							/>
						</Box>
						<Box
							display={{ xs: "none", sm: "flex", md: "none" }}
							style={{ justifyContent: "center" }}
						>
							<img src={TabletImage} alt="Tablet Cubes" />
						</Box>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Pitch;