import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images
import Collaboration from "../../../../Images/Service/SubPages/online-collab.png";
import HeroCollaboration from "../../../../Images/Service/SubPages/HeroImages/OnlineCollaboration.png";

// Custom images for Online Collaboration service cards
import ConnectivityImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/Connectivity.webp";
import FileSharingImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/FileSharing.webp";
import VirtualWorkspaceImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/VirtualWorkspace.webp";
import KinoLiveImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/KinoLive.webp";

// Custom images for workflow steps
import ConsultationImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/Workflow/Consultation.webp";
import DeploymentImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/Workflow/Deployment.webp";
import SupportImage from "../../../../Images/Service/SubPages/ServicesImages/OnlineCollaboration/Workflow/Support.webp";

const useStyles = makeStyles((theme) => ({}));

const heroTitle = "Advanced Online Collaboration for Remote Teams";
const heroSubTitle =
  "Harness innovative connectivity solutions to keep your remote workforce connected, secure, and productive. With support from our sister company Kino Live, experience a virtual co-working space designed for efficient communication and collaboration.";

const serviceTitle = "Innovative Remote Work Solutions";
const serviceSubTitle =
  "Our comprehensive services equip your remote teams with secure communication tools, efficient file sharing, and integrated virtual workspaces.";

const vendorsTitle = "Our Valued Clients";

const workflowTitle = "Our Remote Work Implementation Process";
const workflowSubTitle =
  "Our methodical approach ensures your remote work infrastructure is designed to optimize connectivity, streamline communication, and foster collaboration.";

const workflowCardItems = [
  {
    title1: "Consultation & Assessment",
    subtitle1:
      "We evaluate your remote work needs and existing infrastructure.",
    image1: ConsultationImage,
    listItem1: "Initial Consultation",
    listItem2: "Infrastructure Audit",
    listItem3: "Requirements Gathering",
    listItem4: "Strategy Proposal",
    listItem5: "Plan Finalization",
  },
  {
    title2: "Design & Deployment",
    subtitle2:
      "Our experts design and deploy tailored solutions for remote work.",
    image2: DeploymentImage,
    listItem6: "Solution Design",
    listItem7: "Technology Selection",
    listItem8: "Integration",
    listItem9: "Deployment",
    listItem10: "Testing",
  },
  {
    title3: "Ongoing Support & Enhancement",
    subtitle3:
      "Continuous support and proactive updates keep your remote work environment efficient.",
    image3: SupportImage,
    listItem11: "Performance Monitoring",
    listItem12: "Regular Updates",
    listItem13: "Security Checks",
    listItem14: "Troubleshooting",
    listItem15: "Continuous Improvement",
  },
];

// Custom service card data
const serviceCardTitles = [
  "Robust Connectivity",
  "Secure File Sharing",
  "Virtual Workspace Integration",
  "Kino Live Co-Working",
];

const serviceCardSubTitles = [
  "Ensure secure and stable connections for all remote communications.",
  "Enable real-time collaboration with advanced, protected file sharing solutions.",
  "Incorporate modern virtual workspaces to enhance team productivity.",
  "Leverage our sister company Kino Live for an interactive online co-working experience.",
];

const serviceCardImages = [
  ConnectivityImage,
  FileSharingImage,
  VirtualWorkspaceImage,
  KinoLiveImage,
];

const OnlineCollaboration = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroCollaboration}
        serviceTitle="Online Collaboration"
        serviceImage={Collaboration}
        heroImageClass="service-image"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={serviceCardTitles}
        cardSubTitles={serviceCardSubTitles}
        cardImages={serviceCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"virtual"} />
    </div>
  );
};

export default OnlineCollaboration;
