import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button, Box } from "@material-ui/core";
import "./AboutUs.css";

// Images
import TestimonialUser from "../../../Images/About Us/Testimonial.png";
import TestimonialUserMobile from "../../../Images/About Us/TestimonialMobile.png";
import Quotes from "../../../Images/About Us/quotes.png";
import ReverseQuotes from "../../../Images/About Us/reverseQuotes.png";

const useStyles = makeStyles((theme) => ({
	testimonial: {
		position: "relative",
	},
	sectionBackground: {
		background: "#E3F2F3",
		opacity: "0.5",
		position: "absolute",
		width: "100%",
		height: "100%",
	},
	container: {
		padding: "40px 24px !important",
	},
	gridContainer: {
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
	},
	gridItemContainer: {
		display: "flex",
		justifyContent: "center",
		zIndex: "1",
	},
	testimonialImageMobile: {
		display: "none",
		position: "absolute",
		left: "-65px",
		top: "-5px",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	divContainer: {
		display: "flex",
		alignItems: "baseline",
		flexFlow: "column",
		justifyContent: "center",
		marginLeft: "40px",
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			marginLeft: "30px",
		},
		[theme.breakpoints.down("xs")]: {
			marginLeft: "50px",
		},
	},
	titleQuotesContainer: {
		display: "flex",
		alignItems: "center",
		marginBottom: "20px",
	},
	quotes: {
		position: "absolute",
		left: "-40px",
		top: "-15px",
		[theme.breakpoints.down("sm")]: {
			top: "-10px",
			left: "-45px",
		},
		[theme.breakpoints.down("xs")]: {
			top: "0",
			left: "-55px",
		},
	},
	endingQuotes: {
		top: "35px",
		right: "-42px",
		position: "absolute",
		zIndex: "1",
	},
	title: {
		color: "#fff !important",
		fontFamily: "Roboto !important",
		fontSize: "20px !important",
		fontWeight: "700 !important",
		maxWidth: "400px !important",
		position: "relative",
		zIndex: "0",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "300px !important",
		},
	},
	highlight: {
		background: "#032836",
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: "-1",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
		},
	},
	paragraph: {
		fontFamily: "Roboto !important",
		color: "#707678 !important",
		lineHeight: "24px !important",
		position: "relative",
	},
}));

const Testimonial = () => {
	const classes = useStyles();
	return (
		<div className={classes.testimonial}>
			<div className={classes.sectionBackground}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container>
					<Grid
						item
						md={12}
						sm={12}
						xs={12}
						className={classes.gridItemContainer}
					>
						<Box display={{ xs: "none", sm: "block", md: "block" }}>
							<img src={TestimonialUser} alt="Testimonial User" />
						</Box>
						<div className={classes.divContainer}>
							<div className={classes.titleQuotesContainer}>
								<Typography variant="h2" className={classes.title}>
									<div className={classes.highlight}></div>
									In fact, technology routinely surpasses society’s ability to
									deal with it.
									<img
										src={Quotes}
										alt="Testimonial Quotes"
										className={classes.quotes}
									/>
									<Box display={{ xs: "none", sm: "none", md: "block" }}>
										<img
											src={ReverseQuotes}
											alt="Ending Testimonial Quotes"
											className={classes.endingQuotes}
										/>
									</Box>
								</Typography>
							</div>
							<div>
								<Typography variant="subtitle1" className={classes.paragraph}>
									Tarun Khanna,
									<br />
									When Technology Gets Ahead of Society, HBR
									<img
										src={TestimonialUserMobile}
										alt="Testimonial User Mobile"
										className={classes.testimonialImageMobile}
									/>
								</Typography>
							</div>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Testimonial;
