import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import RetailIcon from "../../../../Images/Industries/SubPages/RetailIconWhite.png";
import HeroRetail from "../../../../Images/Industries/SubPages/HeroImages/Retail.webp";

// Images for Service cards (Retail images)
import RetailWirelessImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/RetailWireless.webp";
import RetailInstallationImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/RetailAudio.webp";
import RetailDataImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/RetailData.webp";
import RetailArchitectureImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/RetailArchitecture.webp";

// Workflow images for Retail
import RetailWorkflowAssessmentImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/Workflow/WorkflowAssessment.webp";
import RetailWorkflowInstallationImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/Workflow/WorkflowInstallation.webp";
import RetailWorkflowSupportImage from "../../../../Images/Industries/SubPages/ServicesImages/Retail/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Innovative IT Solutions for the Retail Industry";
const heroSubTitle =
  "We deliver tailored, cutting-edge infrastructures that support retailers with robust physical connectivity and smart virtual systems, driving operational excellence.";

// Services Section
const serviceTitle = "Comprehensive Retail IT Solutions";
const serviceSubTitle =
  "Our integrated approach blends dependable physical services with advanced virtual systems to streamline operations and boost efficiency. We ensure reliable connectivity with state-of-the-art wireless solutions and high-quality audio/video systems, while our virtual offerings include sophisticated retail data systems—integrating Retail Management Software, POS Systems, and Inventory Management Solutions—and custom system architecture tailored to retail demands.";

// Service Cards (4 cards)
const retailCardTitles = [
  "Wireless Solutions",
  "Audio/Video Systems",
  "Retail Data Systems",
  "Retail-Focused System Architecture",
];
const retailCardSubTitles = [
  "Reliable connectivity solutions designed for high-performance retail environments.",
  "High-quality AV systems for dynamic in-store communication and digital signage.",
  "Integration of Retail Management Software, POS Systems, and Inventory Management Solutions to streamline operations and data management.",
  "Custom IT architectures engineered to support complex retail processes and drive operational efficiency.",
];
const retailCardImages = [
  RetailWirelessImage,
  RetailInstallationImage,
  RetailDataImage,
  RetailArchitectureImage,
];

const ServiceLinks = [
  "/physical-infrastructure/wifi-networks",
  "/physical-infrastructure/audio-video-systems",
  "/virtual-infrastructure/data-systems",
  "/virtual-infrastructure/system-architecture"

];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Retail Implementation Process";
const workflowSubTitle =
  "From store assessments to advanced installation and continuous support, our structured process ensures your retail operations reach peak performance.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Store Assessment & Design",
    subtitle1:
      "Evaluate your retail environment and craft a tailored IT blueprint.",
    image1: RetailWorkflowAssessmentImage,
    listItem1: "Initial Consultation",
    listItem2: "Store Survey",
    listItem3: "Layout Planning",
    listItem4: "Design Proposal",
    listItem5: "Plan Approval",
  },
  {
    title2: "Advanced Installation & Integration",
    subtitle2:
      "Implement wireless networks, audio/video systems, and integrated data systems with precision.",
    image2: RetailWorkflowInstallationImage,
    listItem6: "Equipment Setup",
    listItem7: "System Installation",
    listItem8: "Calibration",
    listItem9: "Testing",
    listItem10: "Configuration",
  },
  {
    title3: "Continuous Support & Optimization",
    subtitle3:
      "Provide ongoing maintenance, upgrades, and performance reviews to keep your systems secure and efficient.",
    image3: RetailWorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Upgrades",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const RetailSolutions = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroRetail}
        serviceTitle="Retail Solutions"
        serviceImage={RetailIcon}
        heroImageClass="service-image2"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={retailCardTitles}
        cardSubTitles={retailCardSubTitles}
        cardImages={retailCardImages}
        cardLinks={ServiceLinks}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default RetailSolutions;
