import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for hero section
import Wifi from "../../../../Images/Service/SubPages/wifi.png";
import HeroWifi from "../../../../Images/Service/SubPages/HeroImages/WiFi.webp";

// Images for Wifi service cards
import WifiConsultationImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/WifiConsultation.webp";
import WifiInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/WifiInstallation.webp";
import WifiConnectivityImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/WifiConnectivity.webp";
import WifiMaintenanceImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/WifiMaintenance.webp";

// Workflow images for Wifi (sized 450px x 380px)
import WorkflowConsultationImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/Workflow/WorkflowConsultation.webp";
import WorkflowInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/Workflow/WorkflowInstallation.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/Wifi/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

const heroTitle = "Reliable Wireless Connectivity for Your Business";
const heroSubTitle =
  "Upgrade to modern wireless networks that deliver consistent, secure, and scalable connections. We improve your wireless infrastructure so your business stays connected around the clock.";

const serviceTitle = "Advanced Wireless Solutions";
const serviceSubTitle =
  "We provide comprehensive wireless network services—from planning and deployment to ongoing maintenance—to ensure robust connectivity and improved performance for your business.";

const vendorsTitle = "Our Esteemed Clients";

const workflowTitle = "Our Deployment Process";
const workflowSubTitle =
  "Our structured process takes your wireless network from initial assessment to full optimization, delivering performance and reliability at every stage.";

const workflowCardItems = [
  {
    title1: "Site Assessment & Planning",
    subtitle1:
      "We examine your environment and design a wireless solution tailored to your business needs.",
    image1: WorkflowConsultationImage,
    listItem1: "Initial Consultation",
    listItem2: "Site Survey",
    listItem3: "Network Mapping",
    listItem4: "Custom Design",
    listItem5: "Plan Approval",
  },
  {
    title2: "Precision Installation",
    subtitle2:
      "Our team installs advanced wireless systems, ensuring optimal coverage and reliable performance.",
    image2: WorkflowInstallationImage,
    listItem6: "Equipment Setup",
    listItem7: "Optimal Placement",
    listItem8: "Signal Testing",
    listItem9: "Configuration",
    listItem10: "Performance Validation",
  },
  {
    title3: "Ongoing Optimization & Support",
    subtitle3:
      "Continuous monitoring and prompt support keep your wireless network running at peak performance.",
    image3: WorkflowSupportImage,
    listItem11: "Real-Time Monitoring",
    listItem12: "Maintenance Checks",
    listItem13: "Software Updates",
    listItem14: "Troubleshooting",
    listItem15: "Continuous Improvement",
  },
];

// Custom Wireless Networks service card data
const wirelessCardTitles = [
  "Wireless Network Design",
  "Professional Deployment",
  "Maintenance & Optimization",
  "Security & Scalability",
];

const wirelessCardSubTitles = [
  "Tailored design strategies to optimize coverage and performance.",
  "Expert installation and configuration of wireless systems for reliable connectivity.",
  "Regular monitoring and updates to keep your network operating at its best.",
  "Modern security measures and scalable solutions that grow with your business.",
];

const wirelessCardImages = [
  WifiConsultationImage,
  WifiInstallationImage,
  WifiConnectivityImage,
  WifiMaintenanceImage,
];

const WifiNetworks = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroWifi}
        serviceTitle="Wifi Networks"
        serviceImage={Wifi}
        heroImageClass="service-image2"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={wirelessCardTitles}
        cardSubTitles={wirelessCardSubTitles}
        cardImages={wirelessCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default WifiNetworks;
