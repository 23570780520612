import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Camera from "../../../../Images/Service/SubPages/security-cameras.png";
import HeroCamera from "../../../../Images/Service/SubPages/HeroImages/Camera.png";

// Images for Security service cards
import SecurityDesignImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/SecurityDesign.webp";
import SecurityInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/SecurityInstallation.webp";
import SecurityIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/SecurityIntegration.webp";
import SecurityMaintenanceImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/SecurityMaintenance.webp";

// Workflow images for Security (sized 450px x 380px)
import WorkflowSurveyImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/Workflow/WorkflowSurvey.webp";
import WorkflowInstallImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/Workflow/WorkflowInstall.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/SecurityCameras/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Reliable Security Camera Systems for Enhanced Safety";
const heroSubTitle =
  "We create custom video surveillance solutions that protect your residential or commercial property. Following a thorough site survey, we design and install advanced systems to monitor and safeguard your premises.";

// Services Section
const serviceTitle = "Advanced Security Solutions";
const serviceSubTitle =
  "Our comprehensive security services include system design, expert installation, and ongoing maintenance to ensure continuous protection and monitoring.";

// Service Cards (4 cards)
const securityCardTitles = [
  "Customized System Design",
  "Professional Installation",
  "System Integration",
  "Maintenance & Support",
];
const securityCardSubTitles = [
  "Tailored design strategies for optimal surveillance coverage.",
  "Expert setup ensuring high-quality camera placement and connectivity.",
  "Integration with existing security systems and smart devices.",
  "Regular inspections and updates to keep your system operating reliably.",
];
const securityCardImages = [
  SecurityDesignImage,
  SecurityInstallationImage,
  SecurityIntegrationImage,
  SecurityMaintenanceImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Deployment Process";
const workflowSubTitle =
  "Our structured process takes your security system from initial assessment to full installation and ongoing support, ensuring optimal performance and safety.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Site Survey & Consultation",
    subtitle1:
      "We evaluate your property and develop a tailored surveillance plan.",
    image1: WorkflowSurveyImage,
    listItem1: "Initial Consultation",
    listItem2: "Property Survey",
    listItem3: "Risk Assessment",
    listItem4: "Custom Design",
    listItem5: "Plan Approval",
  },
  {
    title2: "Precision Installation",
    subtitle2:
      "Our experts install cameras with attention to detail to cover all vulnerable areas.",
    image2: WorkflowInstallImage,
    listItem6: "Equipment Setup",
    listItem7: "Camera Installation",
    listItem8: "Optimal Placement",
    listItem9: "System Testing",
    listItem10: "Configuration",
  },
  {
    title3: "Ongoing Support & Maintenance",
    subtitle3:
      "Continuous monitoring and maintenance keep your security system performing at its best.",
    image3: WorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Software Updates",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const SecurityCameras = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroCamera}
        serviceTitle="Security Cameras"
        serviceImage={Camera}
        heroImageClass="service-image3"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={securityCardTitles}
        cardSubTitles={securityCardSubTitles}
        cardImages={securityCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default SecurityCameras;
