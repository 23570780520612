import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import "./Service.css";

// Images
import backgroundImage from "../../../Images/Hero/BackgroundImage.jpg";
import IndustriesImage from "../../../Images/Industries/industries.png";
import IndustriesHero1 from "../../../Images/Industries/industries-hero-1.png";
import IndustriesHero2 from "../../../Images/Industries/industries-hero-2.png";

const useStyles = makeStyles((theme) => ({
	industriesContainer: {
		minHeight: "100vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background:
			"linear-gradient(72.49deg, #DADBDD -19.29%, #DADBDD -19.28%, #FFFFFF 53.4%)",
		position: "relative",
		alignItems: "center",
	},
	serviceImage: {
		backgroundImage: `url(${backgroundImage})`,
		opacity: "0.6",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "100px 0",
		[theme.breakpoints.down("sm")]: {
			flexFlow: "column-reverse",
			padding: "30px 0 60px",
		},
	},
	leftContainer: {
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			alignItems: "center",
			flexFlow: "column",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
		},
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "46px",
		textTransform: "capitalize !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
			fontSize: "40px",
		},
	},
	titleHighlight: {
		background: "#FBCC9B",
		width: "450px",
		height: "15px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "510px",
		},
	},
	titleParagraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		lineHeight: "24px !important",
		maxWidth: "520px",
		marginTop: "10px",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	subTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "20px !important",
		marginTop: "70px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "50px",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		lineHeight: "24px !important",
		maxWidth: "500px",
	},
	heroImage1: {
		margin: "20px 0 0",
		[theme.breakpoints.down("sm")]: {
			margin: "10px 0 0",
		},
	},
	heroImage2: {
		marginTop: "20px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "10px",
		},
	},
	rightContainer: {
		zIndex: "1",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "center",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-end",
		},
	},
	industriesImageStyle: {
		[theme.breakpoints.down("sm")]: {
			height: "250px",
		},
		[theme.breakpoints.down("xs")]: {
			height: "200px",
		},
	},
}));

const IndustriesWeServe = () => {
	const classes = useStyles();
	return (
		<div className={classes.industriesContainer}>
			<div className={classes.serviceImage}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} className={classes.leftContainer}>
						<Typography variant="h2" className={classes.title}>
							Industries We Serve
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Typography variant="subtitle1" className={classes.titleParagraph}>
							We provide tailored IT solutions across diverse industries including Retail, Hospitality, Corporate, Manufacturing, and Legal.
						</Typography>
						<Typography variant="subtitle1" className={classes.subTitle}>
							Transforming Industries with IT Solutions
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							Not sure which industry solution fits your business? We are ready to partner with you to analyze your needs and develop a customized IT strategy that is clear and manageable.
						</Typography>
						<img
							src={IndustriesHero1}
							alt="Industries Hero 1"
							className={classes.heroImage1}
						/>
						<Typography variant="subtitle1" className={classes.subTitle}>
							Every industry is unique:
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							We customize our approach to suit your industry's specific requirements. Your input is essential in developing the optimal IT solution for your business.
						</Typography>
						<img
							src={IndustriesHero2}
							alt="Industries Hero 2"
							className={classes.heroImage2}
						/>
					</Grid>
					<Grid item md={6} className={classes.rightContainer}>
						<img
							src={IndustriesImage}
							alt="Industries We Serve"
							className={classes.industriesImageStyle}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default IndustriesWeServe;
