import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {
	Container,
	Grid,
	Typography,
	TextField,
	Button,
} from "@material-ui/core";
import "./AboutUs.css";

// Images
import backgroundImage from "../../../Images/Hero/BackgroundImage.jpg";
import Cubes from "../../../Images/About Us/4Cubes.png";

const useStyles = makeStyles((theme) => ({
	about: {
		minHeight: "100vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background:
			"linear-gradient(72.49deg, #DADBDD -19.29%, #DADBDD -19.28%, #FFFFFF 53.4%)",
		position: "relative",
		alignItems: "center",
	},
	aboutImage: {
		backgroundImage: `url(${backgroundImage})`,
		opacity: "0.6",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		zIndex: "1",
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		[theme.breakpoints.down("sm")]: {
			flexFlow: "column-reverse",
			marginTop: "20px",
		},
	},
	cubeImage: {
		[theme.breakpoints.down("sm")]: {
			height: "200px",
		},
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "46px",
		textTransform: "capitalize !important",
		marginTop: "10px",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	titleHighlight: {
		background: "linear-gradient(255.64deg, #032836 1.47%, #07516C 117.99%)",
		height: "15px",
		width: "450px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
			margin: "unset",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontSize: "16px",
		margin: "20px 0 40px",
		maxWidth: "530px",
		fontFamily: "Roboto !important",
		[theme.breakpoints.down("sm")]: {
			margin: "10px auto 40px",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "400px",
			margin: "10px 0 40px",
		},
	},
	rightContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-end",
		},
	},
}));

const About = () => {
	const classes = useStyles();
	return (
		<div className={classes.about}>
			<div className={classes.aboutImage}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} sm={12}>
						<Typography variant="h2" className={classes.title}>
							About Us
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Typography variant="subtitle1" className={classes.paragraph}>
							At Kinetx Co, our vision is to build affordable IT solutions tailored
							to each business's unique needs. We believe every company deserves an
							IT infrastructure designed to support its distinct values and goals.
							We tackle the challenge of making sophisticated technology accessible
							and cost-effective for small and mid-sized businesses.
							<br /> <br />
							The Information Technology sector has evolved drastically over the past
							decade. Tech companies have introduced groundbreaking solutions to
							transform daily business operations. However, implementing these
							technologies became entangled in complexities that hindered their
							intended impact. Companies emerged to solve these issues, yet their
							expensive solutions turned into secondary problems for those who could
							not afford them. Ultimately, the solution became a problem.
						</Typography>
					</Grid>
					<Grid item md={6} sm={12} className={classes.rightContainer}>
						<img src={Cubes} alt="4 Cubes" className={classes.cubeImage} />
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default About;
