import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import AOS from "aos";
import "aos/dist/aos.css";
import TagManager from "react-gtm-module";
import "./App.css";

// Navbar
import Navbar from "./Views/Navbar/Navbar";

// Footer
import Footer from "./Views/Footer/Footer";

// Homepage
import Homepage from "./Views/Homepage";

// Pages
import ContactUs from "./Views/Pages/ContactUs/ContactUs";
import AboutUs from "./Views/Pages/AboutUs/AboutUs";
import Partners from "./Views/Pages/Partners/Partners";
import Service from "./Views/Pages/Services-MainPage/Service";
import Service2 from "./Views/Pages/Services-MainPage/Service2";
import Industries from "./Views/Pages/Industries-MainPage/Service";

// Services SubPages
// Physical
import WifiNetworks from "./Views/Pages/Services-SubPages/Physical/WifiNetworks";
import LowVoltageCabling from "./Views/Pages/Services-SubPages/Physical/LowVoltageCabling";
import FiberOptics from "./Views/Pages/Services-SubPages/Physical/FiberOptics";
import AudioVideoSystems from "./Views/Pages/Services-SubPages/Physical/AudioVideoSystems";
import AccessControlSystems from "./Views/Pages/Services-SubPages/Physical/AccessControlSystems";
import SecurityCameras from "./Views/Pages/Services-SubPages/Physical/SecurityCameras";
import PhysicalSystemArchitecture from "./Views/Pages/Services-SubPages/Physical/SystemArchitecture";
// Virtual
import CloudSystems from "./Views/Pages/Services-SubPages/Virtual/CloudSystems";
import DataSystems from "./Views/Pages/Services-SubPages/Virtual/DataSystems";
import DataBackupProtection from "./Views/Pages/Services-SubPages/Virtual/DataBackupProtection";
import OnlineCollaboration from "./Views/Pages/Services-SubPages/Virtual/OnlineCollaboration";
import VirtualSystemArchitecture from "./Views/Pages/Services-SubPages/Virtual/SystemArchitecture";
// Industries
import Corporate from "./Views/Pages/Industries-SubPages/Industries/Corporate";
import Retail from "./Views/Pages/Industries-SubPages/Industries/Retail";
import Legal from "./Views/Pages/Industries-SubPages/Industries/Legal";
import Manufacturing from "./Views/Pages/Industries-SubPages/Industries/Manufacturing";
import Hospitality from "./Views/Pages/Industries-SubPages/Industries/Hospitality";

// 404 Page
import NotFoundPage from "./Views/Pages/404/404";

// Components
import ScrollToTop from "./Views/components/ScrollToTop";

function App() {
  useEffect(() => {
    AOS.init({ duration: 500 });
    TagManager.initialize({ gtmId: "GTM-MNCVXWQ" });
  }, []);

  return (
    <div className="app">
      <div className="app-wrapper">
        <Router>
          <Fragment>
            <Navbar />
            <BreakpointProvider>
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Homepage} />

                  <Route exact path="/contact-us" component={ContactUs} />
                  <Route exact path="/about-us" component={AboutUs} />
                  <Route exact path="/partners" component={Partners} />
                  <Route exact path="/physical-infrastructure" component={Service} />
                  <Route exact path="/virtual-infrastructure" component={Service2} />
                  <Route exact path="/industries" component={Industries} />

                  <Route path="/physical-infrastructure/wifi-networks" component={WifiNetworks} />
                  <Route path="/physical-infrastructure/low-voltage-cabling" component={LowVoltageCabling} />
                  <Route path="/physical-infrastructure/fiber-optics" component={FiberOptics} />
                  <Route path="/physical-infrastructure/audio-video-systems" component={AudioVideoSystems} />
                  <Route path="/physical-infrastructure/access-control-systems" component={AccessControlSystems} />
                  <Route path="/physical-infrastructure/security-cameras" component={SecurityCameras} />
                  <Route path="/physical-infrastructure/system-architecture" component={PhysicalSystemArchitecture} />

                  <Route path="/virtual-infrastructure/cloud-systems" component={CloudSystems} />
                  <Route path="/virtual-infrastructure/data-systems" component={DataSystems} />
                  <Route path="/virtual-infrastructure/data-backup-protection" component={DataBackupProtection} />
                  <Route path="/virtual-infrastructure/online-collaboration" component={OnlineCollaboration} />
                  <Route path="/virtual-infrastructure/system-architecture" component={VirtualSystemArchitecture} />

                  <Route path="/industries/hospitality" component={Hospitality} />
                  <Route path="/industries/manufacturing" component={Manufacturing} />
                  <Route path="/industries/legal" component={Legal} />
                  <Route path="/industries/retail" component={Retail} />
                  <Route path="/industries/corporate" component={Corporate} />

                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </ScrollToTop>
            </BreakpointProvider>
            <Footer />
          </Fragment>
        </Router>
      </div>
    </div>
  );
}

export default App;
