import React from "react";
import { makeStyles } from "@material-ui/core";
import "./Service.css";

// Components
import VirtualInfrastructure from "./VirtualInfrastructure";
import Projects from "./VirtualProjects";
import Solution from "./Solution";
import Consult from "./Consult";

const useStyles = makeStyles((theme) => ({
	service: {},
}));

const Service2 = () => {
	const classes = useStyles();
	return (
		<div className={classes.service}>
			<VirtualInfrastructure />
			<Projects />
			<Solution />
			<Consult color={"virtual"} />
		</div>
	);
};

export default Service2;
