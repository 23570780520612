import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled, alpha } from "@mui/material/styles";
import MenuDrawer from "./MenuDrawer";
import Hidden from "@material-ui/core/Hidden";

import "./Navbar.css";

// Images
import Logo from "../../Images/Navbar/KinetxLogo.webp";

//Physical
import Physical1 from "../../Images/Navbar/Physical/wifi.png";
import Physical2 from "../../Images/Navbar/Physical/low-voltage.png";
import Physical3 from "../../Images/Navbar/Physical/fiber-optics.png";
import Physical4 from "../../Images/Navbar/Physical/audio-systems.png";
import Physical5 from "../../Images/Navbar/Physical/access-control.png";
import Physical6 from "../../Images/Navbar/Physical/security-cameras.png";
import Physical7 from "../../Images/Navbar/Physical/physical-architecture.png";

// Virtual
import Virtual1 from "../../Images/Navbar/Virtual/cloud-systems.png";
import Virtual2 from "../../Images/Navbar/Virtual/data-systems.png";
import Virtual3 from "../../Images/Navbar/Virtual/data-backup.png";
import Virtual4 from "../../Images/Navbar/Virtual/online-collab.png";
import Virtual5 from "../../Images/Navbar/Virtual/virtual-architecture.png";

//Industry
import Industry1 from "../../Images/Navbar/Industries/Hospitality.png";
import Industry2 from "../../Images/Navbar/Industries/Retail.png";
import Industry3 from "../../Images/Navbar/Industries/Manufacturing.png";
import Industry4 from "../../Images/Navbar/Industries/Legal.png";
import Industry5 from "../../Images/Navbar/Industries/Corporate.png";

const useStyles = makeStyles((theme) => ({
	navbar: {
		position: "sticky",
		top: "0",
		left: "0",
		height: "80px",
		// height: "100px",
		width: "100%",
		display: "flex",
		alignItems: "center",
		backgroundColor: "#fff",
		zIndex: "10",
		boxShadow: "0px 2px 8px rgba(45, 45, 45, 0.15)",
	},
	navbarContainer: {
		display: "flex",
		width: "100%",
		maxWidth: "1232px",
		justifyContent: "space-between",
		margin: "auto",
	},
	logoButton: {
		display: "flex",
		alignItems: "center",
	},
	navigation: {
		display: "flex",
		alignItems: "center",
		flex: 1,
	},
	navigationList: {
		display: "flex",
		margin: "0 50px 0 0",
		padding: "0",
		flex: 1,
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	navigationElement: {
		textDecoration: "none !important",
		listStyle: "none !important",
		// padding: "0 50px !important",
		fontWeight: "600 !important",
		fontSize: "18px !important",
		color: "#2D2D2D !important",
		textTransform: "capitalize !important",
		fontFamily: "'Roboto', sans-serif !important",
		"&:hover": {
			cursor: "pointer !important",
		},
	},
	link: {
		textDecoration: "none !important",
		listStyle: "none !important",
	},
	logo: {
		textDecoration: "none",
		width: "200px",
		// marginRight: "50px",
		[theme.breakpoints.down("md")]: {
			paddingLeft: "24px",
		},
		// [theme.breakpoints.down("sm")]: {
		// 	marginRight: "unset",
		// },
		// [theme.breakpoints.down("xs")]: {
		// 	paddingLeft: "12px",
		// },
	},
	startedButton: {
		padding: "10px 24px !important",
		background: "var(--navbar-color) !important",
		color: "#FFFFFF !important",
		textTransform: "capitalize !important",
		fontFamily: "Roboto !important",
		fontWeight: "700 !important",
		borderRadius: "90px !important",
		[theme.breakpoints.down("md")]: {
			marginRight: "24px !important",
		},
	},
	icon: {
		fontSize: "10px !important",
		marginRight: "10px !important",
	},
	firstRow: {
		width: "30%",
		marginLeft: "10px",
		// [theme.breakpoints.down("md")]: {
		// 	padding: "0 24px !important",
		// },
	},
	highlightChange: {
		color: "var(--navbar-color) !important",
		borderBottom: "1px solid var(--navbar-color) !important",
	},
}));

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		backgroundColor: "#fff",
		width: "100%",
		maxWidth: "100%",
		height: "75vh",
		top: "66px !important",
		left: "0 !important",
	},
	"&:hover": {},
}));

const Physical = [
	"WiFi Networks",
	"Low-voltage Cabling",
	"Fiber Optics",
	"Audio / Video Systems",
	"Access-control Systems",
	"Security Cameras",
	"System Architecture",
];

const Virtual = [
	"Cloud Systems",
	"Data Systems",
	"Data Backup & Protection",
	"Online Collaboration",
	"Cloud Architecture",
];

const Industries = [
	"Retail",
	"Hospitality",
	"Corporate",
	"Manufacturing",
	"Legal",
];

const IndustriesLinks = [
	"/industries/retail",
	"/industries/hospitality",
	"/industries/corporate",
	"/industries/manufacturing",
	"/industries/legal",
];

const PhysicalLinks = [
	"/physical-infrastructure/wifi-networks",
	"/physical-infrastructure/low-voltage-cabling",
	"/physical-infrastructure/fiber-optics",
	"/physical-infrastructure/audio-video-systems",
	"/physical-infrastructure/access-control-systems",
	"/physical-infrastructure/security-cameras",
	"/physical-infrastructure/system-architecture",
];

const VirtualLinks = [
	"/virtual-infrastructure/cloud-systems",
	"/virtual-infrastructure/data-systems",
	"/virtual-infrastructure/data-backup-protection",
	"/virtual-infrastructure/online-collaboration",
	"/virtual-infrastructure/system-architecture",
];

const PhysicalImages = [
	Physical1,
	Physical2,
	Physical3,
	Physical4,
	Physical5,
	Physical6,
	Physical7,
];

const VirtualImages = [
	Virtual1,
	Virtual2,
	Virtual3,
	Virtual4,
	Virtual5
];

const IndustryImages = [
	Industry1,
	Industry2,
	Industry3,
	Industry4,
	Industry5
];

const Navbar = () => {
	const classes = useStyles();
	const [anchorElService, setAnchorElService] = useState(null);
	const [anchorELIndustries, setAnchorELIndustries] = useState(null);
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const openService = Boolean(anchorElService);
	const openIndustries = Boolean(anchorELIndustries);

	const handleClickService = (event) => {
		setAnchorELIndustries(null)
		setMenuIsOpen(!menuIsOpen);
		if (!menuIsOpen) {
			setAnchorElService(event.currentTarget);
		} else {
			setAnchorElService(null);
		}
	};

	const handleCloseService = () => {
		setMenuIsOpen(false);
		setAnchorElService(null);
	};

	const handleClickIndustries = (event) => {
		setAnchorElService(null);
		setMenuIsOpen(!menuIsOpen);
		if (!menuIsOpen) {
			setAnchorELIndustries(event.currentTarget);
		} else {
			setAnchorELIndustries(null);
		}
	};

	const handleCloseIndustries = () => {
		setMenuIsOpen(false);
		setAnchorELIndustries(null);
	};

	const openZohoChat = () => {
		if (window.$zoho && window.$zoho.salesiq) {
		  if (window.$zoho.salesiq.chat && typeof window.$zoho.salesiq.chat.start === "function") {
			window.$zoho.salesiq.chat.start();
		  } else {
			console.error("Zoho SalesIQ chat API not ready.");
		  }
		} else {
		  console.error("Zoho SalesIQ script not loaded.");
		}
	  };
	  



	return (
		<div className={classes.navbar}>
			<div className={classes.navbarContainer}>
				<div className={classes.logoButton}>
					<Link to="/" onClick={handleCloseService}>
						<img className={classes.logo} src={Logo} alt="Kinetx Logo" />
					</Link>
				</div>
				<Hidden only={["xs", "sm"]}>
					<div className={classes.navigation}>
						<ul className={classes.navigationList}>
							<Link
								to="/about-us"
								className={classes.link}
								onClick={handleCloseService}
							>
								<li className={classes.link}>
									<Button className={classes.navigationElement}>
										About Us
									</Button>
								</li>
							</Link>

							<Button
								className={`${classes.navigationElement} ${openService && classes.highlightChange
									}`}
								id="demo-customized-button"
								aria-controls="demo-customized-menu"
								aria-haspopup="true"
								aria-expanded={openService ? "true" : undefined}
								disableElevation
								onClick={handleClickService}
								endIcon={<KeyboardArrowDownIcon />}
							>
								Services
							</Button>
							<StyledMenu
								id="dropdown-menu"
								MenuListProps={{
									"aria-labelledby": "demo-customized-button",
								}}
								anchorEl={anchorElService}
								open={openService}
								onClose={handleCloseService}
							>
								<div className={classes.firstRow}>
									<Link to="/physical-infrastructure/" className={classes.link}>
										<MenuItem onClick={handleCloseService} disableRipple>
											Physical Infrastructure
										</MenuItem>
									</Link>
									<div className="stripe"></div>
									{Physical.map((item, i) => (
										<Link className={classes.link} to={PhysicalLinks[i]}>
											<MenuItem
												onClick={handleCloseService}
												disableRipple
												className="sub-links"
											>
												<img
													src={PhysicalImages[i]}
													alt={Physical[i]}
													className="image"
												/>
												{Physical[i]}
											</MenuItem>
										</Link>
									))}
								</div>
								{/* <div className={classes.firstRow} style={{margin: '0 45px 0 10px',}}> */}
								<div className={classes.firstRow}>
									<Link to="/virtual-infrastructure/" className={classes.link}>
										<MenuItem onClick={handleCloseService} disableRipple>
											Virtual Infrastructure
										</MenuItem>
									</Link>
									<div className="stripe"></div>
									{Virtual.map((item, i) => (
										<Link className={classes.link} to={VirtualLinks[i]}>
											<MenuItem
												onClick={handleCloseService}
												disableRipple
												className="sub-links"
											>
												<img
													src={VirtualImages[i]}
													alt={Virtual[i]}
													className="image"
												/>
												{Virtual[i]}
											</MenuItem>
										</Link>
									))}
								</div>
							</StyledMenu>

							<Button
								className={`${classes.navigationElement} ${openIndustries && classes.highlightChange
									}`}
								id="demo-customized-button"
								aria-controls="demo-customized-menu"
								aria-haspopup="true"
								aria-expanded={openIndustries ? "true" : undefined}
								disableElevation
								onClick={handleClickIndustries}
								endIcon={<KeyboardArrowDownIcon />}
							>
								Industries
							</Button>
							<StyledMenu
								id="dropdown-menu"
								MenuListProps={{
									"aria-labelledby": "demo-customized-button",
								}}
								anchorEl={anchorELIndustries}
								open={openIndustries}
								onClose={handleCloseIndustries}
							>
								<div className={classes.firstRow}>
									<Link to="/industries/" className={classes.link}>
										<MenuItem onClick={handleCloseIndustries} disableRipple>
											Industries We Serve
										</MenuItem>
									</Link>
									<div className="stripe"></div>
									{Industries.map((item, i) => (
										<Link className={classes.link} to={IndustriesLinks[i]}>
											<MenuItem
												onClick={handleCloseIndustries}
												disableRipple
												className="sub-links"
											>
												<img
													src={IndustryImages[i]}
													alt={Industries[i]}
													className="image"
												/>
												{Industries[i]}
											</MenuItem>
										</Link>
									))}
								</div>
								{/* <div className={classes.firstRow} style={{margin: '0 45px 0 10px',}}> */}
							</StyledMenu>

							{/* <Link
								to="/partners"
								className={classes.link}
								onClick={handleCloseService}
							>
								<li className={classes.link}>
									<Button className={classes.navigationElement}>
										Partners
									</Button>
								</li>
							</Link> */}

							<Link
								to="/contact-us"
								className={classes.link}
								onClick={handleCloseService}
							>
								<li className={classes.link}>

									<Button className={classes.navigationElement}>
										Contact Us
									</Button>
								</li>
							</Link>
						</ul>
					</div>
				</Hidden>
				<Hidden only={["xs", "sm"]}>
					<div style={{ textDecoration: "none", display: "flex", alignItems: "center" }}>
						<Button
							className={classes.startedButton}
							endIcon={<ArrowForwardIosIcon className={classes.icon} />}
							onClick={() => {
								openZohoChat();
							}}
						>
							Get Started
						</Button>
					</div>


				</Hidden>
				<Hidden only={["lg", "md", "xl"]}>
					<MenuDrawer />
				</Hidden>
			</div>
		</div>
	);
};

export default Navbar;