import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography } from "@material-ui/core";
import "../Services.css"

// Images
import Slack from "../../../../Images/Service/SubPages/ClientsImages/slack.png";
import Office from "../../../../Images/Service/SubPages/ClientsImages/office-365.webp";
import Hikvision from "../../../../Images/Service/SubPages/ClientsImages/hikvision.webp";
import Ruckus from "../../../../Images/Service/SubPages/ClientsImages/Ruckus.webp";
import Netgear from "../../../../Images/Service/SubPages/ClientsImages/Netgear.webp";
import Quickbooks from "../../../../Images/Service/SubPages/ClientsImages/QuickBooks.webp";
import SONOS from "../../../../Images/Service/SubPages/ClientsImages/SONOS.webp";
import Cisco from "../../../../Images/Service/SubPages/ClientsImages/Cisco.webp";
import Fanvil from "../../../../Images/Service/SubPages/ClientsImages/Fanvil.webp";
import Yealink from "../../../../Images/Service/SubPages/ClientsImages/Yealink.webp";
import Silarius from "../../../../Images/Service/SubPages/ClientsImages/Silarius.webp";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: "700",
    color: "#032836",
    fontSize: "48px",
    marginBottom: "34px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "46px",
      marginBottom: "20px",
    },
  },
  vendorsContainer: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  conveyor: {
    display: "flex",
    alignItems: "center",
    width: "200%", // Two sets of images side by side
    animation: "$scroll 20s linear infinite",
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "20px",
    minHeight: "180px",
  },
  image: {
    maxWidth: "150px",
    height: "auto",
    objectFit: "contain",
  },
  "@keyframes scroll": {
    "0%": { transform: "translateX(0)" },
    // Overshoot by 1px to avoid the visible reset jump
    "100%": { transform: "translateX(-50%)" },
  },
}));

// Define a pool of headlines
const headlines = [
  "Our Trusted Partners",
  "Our Strategic Collaborators",
  "Partners in Innovation",
  "Our Valued Alliances",
  "Our Collaborative Network",
  "Industry Leaders We Trust",
  "Our Key Technology Partners",
  "Our Essential Partners",
  "The Brands That Empower Us",
  "Our Innovative Partners",
  "Partners Driving Success",
  "Our Strategic Allies",
  "Our Premier Collaborators",
  "Trusted Partners, Trusted Results",
  "Our Network of Excellence",
  "Empowering Partnerships",
  "Our Global Technology Network",
  "Partners in Progress",
  "Our Industry Trailblazers",
  "Collaborating for Success"
];

// Pick a random headline on each page load
const randomHeadline = headlines[Math.floor(Math.random() * headlines.length)];

const vendorsImages = [
  Slack,
  Office,
  Hikvision,
  Ruckus,
  Netgear,
  Quickbooks,
  SONOS,
  Cisco,
  Fanvil,
  Yealink,
  Silarius
];

const vendorsTitles = [
  'Slack',
  'Office 365',
  'Hikvision',
  'Ruckus',
  'Netgear',
  'Quickbooks',
  'SONOS',
  'Cisco',
  'Fanvil',
  'Yealink',
  "Silarius",
];

const vendorsLinks = [
  "https://slack.com/",
  "https://www.microsoft365.com/",
  "https://www.hikvision.com/us-en/",
  "https://www.ruckusnetworks.com/",
  "http://netgear.com/business/",
  "https://quickbooks.intuit.com/",
  "https://www.sonos.com/en-us/home",
  "https://www.cisco.com/",
  "https://www.fanvil.com/",
  "https://www.yealink.com/en",
  "https://silarius.com/collections/silarius",
];

const Vendors = () => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={12} sm={12} xs={12} className={classes.container}>
            <Typography variant="h1" className={classes.title}>
              {randomHeadline}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.vendorsContainer}>
          <div className={classes.conveyor}>
            {vendorsImages.map((item, i) => (
              <Grid item md={3} sm={6} xs={6} className={classes.imageContainer} key={i}>
                <a href={vendorsLinks[i]} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <img src={vendorsImages[i]} alt={vendorsTitles[i]} className={classes.image} />
                </a>
              </Grid>
            ))}
            {/* Duplicate the images for seamless scrolling */}
            {vendorsImages.map((item, i) => (
              <Grid item md={3} sm={6} xs={6} className={classes.imageContainer} key={`dup-${i}`}>
                <a href={vendorsLinks[i]} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <img src={vendorsImages[i]} alt={vendorsTitles[i]} className={classes.image} />
                </a>
              </Grid>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Vendors;
