import React from 'react'
import { makeStyles } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import CardTemplate1 from "../../../../Images/Service/SubPages/ServicesImages/Card-Image-Template.png";
import CardTemplate2 from "../../../../Images/Service/SubPages/ServicesImages/Card-Image-Template.png";
import CardTemplate3 from "../../../../Images/Service/SubPages/ServicesImages/Card-Image-Template.png";
import CardTemplate4 from "../../../../Images/Service/SubPages/ServicesImages/Card-Image-Template.png";
import { Link,useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginBottom: "100px",
    },
    cardContainer: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "30px",
        },
    },
    title: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "24px",
        marginBottom: "10px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "18px",
        },
    },
    subTitle: {
        fontWeight: "400",
        fontSize: "1rem",
        lineHeight: "24px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    image: {
        width: "250px",
        height: "250px",
        borderRadius: "11px",
        marginBottom: "10px",
        [theme.breakpoints.down("xs")]: {
            width: "180px",
            height: "180px",
        },
    },
    imageBox: {
        position: "absolute",
        height: '30px',
        width: '70px',
        backgroundColor: '#07516C',
        right: '-35px',
        top: '113px',
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    titleBox: {
        width: '90px',
        height: '10px',
        backgroundColor: '#07516C',
        marginBottom: "10px",
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        },
    },
    titleContainer: {
        width: "250px",
        [theme.breakpoints.down("xs")]: {
            width: "180px",
        },
    }
}));

const ServicesCard = (props) => {
    const classes = useStyles();
    const navigate = useHistory();
    const CardTitle = props.cardTitles || [
        "Card One",
        "Card Two",
        "Card Three",
        "Card Four",
    ];
    const CardSubTitle = props.cardSubTitles || [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
    ];
    const CardImage = props.cardImages || [
        CardTemplate1,
        CardTemplate2,
        CardTemplate3,
        CardTemplate4,
    ];

    return (
        <>
            <Grid container spacing={2} className={classes.gridContainer}>
                {CardTitle.map((item, i) => (
                    <Grid item md={3} sm={6} xs={6} key={i} className={classes.cardContainer}>
                        <img src={CardImage[i]} alt={CardTitle[i]} className={classes.image} />
                        <div className={i === 3 ? "" : classes.imageBox}></div>
                        <div className={classes.titleContainer}>
                            <Typography variant="h1" className={classes.title}>
                                {CardTitle[i]}
                            </Typography>
                            <div className={classes.titleBox}></div>
                            <Typography variant="subtitle1" className={classes.subTitle}>
                                {CardSubTitle[i]}
                            </Typography>
                        </div>
                    </Grid>
                   
                ))}
            </Grid>
        </>
    );
};

export default ServicesCard;
