import React from 'react'
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
         backgroundColor: 'rgb(227 242 243 / 30%)',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: "120px",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "50px",
        },
    },
    gridContainerMargin: {
        marginBottom: "0",
    },
    title: {
        fontWeight: '700',
        fontSize: '36px',
        lineHeight: '48px',
        color: "#2D2D2D",
        marginBottom: "15px",
    },
    subTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        maxWidth: '600px',
    },
    list: {
        margin: "5px 0 0 30px",
    },
    listItem: {
        marginBottom: "5px",
    },
    imageContainer: {
        zIndex: "1",
    },
    image1: {
        marginRight: "5px",
        width: "440px",
        height: "350px",
        borderRadius: "10px",

    },
    image2: {
        marginLeft: "5px",
        width: "440px",
        height: "350px",
        borderRadius: "10px",
      
    },
    image3: {
        marginRight: "5px",
        width: "440px",
        height: "350px",
        borderRadius: "10px",
       
    },
    shadow1: {
        position: "absolute",
        backgroundColor: '#07516C',
        width: "435px",
        height: "380px",
        left: '-75px',
        bottom: '-65px',
        borderRadius: "10px",
        zIndex: "0",
        [theme.breakpoints.down("sm")]: {
            width: "330px",
        },
        [theme.breakpoints.down("xs")]: {
            left: '-16px',
            bottom: '0',
            top: "-30px",
            height: "230px",
            width: "320px",
        },
    },
    shadow2: {
        position: "absolute",
        backgroundColor: '#07516C',
        right: '-24px',
        width: '435px',
        height: '380px',
        borderRadius: "10px",
        zIndex: "0",
        [theme.breakpoints.down("sm")]: {
            width: "320px",
        },
        [theme.breakpoints.down("xs")]: {
            right: '-16px',
            bottom: '0',
            top: "-20px",
            height: "360px",
            maxWidth: "280px",
            width: "100%",
        },
    },
    shadow3: {
        position: "absolute",
        backgroundColor: '#07516C',
        left: '-75px',
        top: '-35px',
        width: '435px',
        height: '380px',
        borderRadius: "10px",
        zIndex: "0",
        [theme.breakpoints.down("sm")]: {
            width: "350px",
        },
        [theme.breakpoints.down("xs")]: {
            left: '-16px',
            bottom: '0',
            top: "-30px",
            height: "260px",
            width: "320px",
        },
    },
}));

const WorkflowCard = (props) => {
    const classes = useStyles();
  return (
    <div>
        <Grid container className={classes.gridContainer}>
            <div className={classes.shadow1}></div>
            <Grid item md={5} sm={6} xs={12} className={classes.imageContainer}>
                <img className={classes.image1} src={props.image1} alt={props.title1} />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <Typography variant="h1" className={classes.title}>
                    {props.title1}
                </Typography>
                <Typography variant="subtitle1" className={classes.subTitle}>
                    {props.subtitle1}
                    <ul className={classes.list}>
                        <li className={classes.listItem}>{props.listItem1}</li>
                        <li className={classes.listItem}>{props.listItem2}</li>
                        <li className={classes.listItem}>{props.listItem3}</li>
                        <li className={classes.listItem}>{props.listItem4}</li>
                        <li className={classes.listItem}>{props.listItem5}</li>
                    </ul>
                </Typography>
            </Grid>
        </Grid>
        <Hidden only={["xs"]}>
            <Grid container className={classes.gridContainer}>
                <div className={classes.shadow2}></div>
                <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="h1" className={classes.title}>
                        {props.title2}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>
                        {props.subtitle2}
                        <ul className={classes.list}>
                            <li className={classes.listItem}>{props.listItem6}</li>
                            <li className={classes.listItem}>{props.listItem7}</li>
                            <li className={classes.listItem}>{props.listItem8}</li>
                            <li className={classes.listItem}>{props.listItem9}</li>
                            <li className={classes.listItem}>{props.listItem10}</li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className={classes.imageContainer}>
                    <img className={classes.image2} src={props.image2} alt={props.title1} />
                </Grid>
            </Grid>
        </Hidden>
        <Hidden only={["sm", "md", "lg", "xl"]}>
            <Grid container className={classes.gridContainer}>
                <div className={classes.shadow2}></div>
                <Grid item md={4} sm={6} xs={12} className={classes.imageContainer}>
                    <img className={classes.image2} src={props.image2} alt={props.title1} />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="h1" className={classes.title}>
                        {props.title2}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subTitle}>
                        {props.subtitle2}
                        <ul className={classes.list}>
                            <li className={classes.listItem}>{props.listItem6}</li>
                            <li className={classes.listItem}>{props.listItem7}</li>
                            <li className={classes.listItem}>{props.listItem8}</li>
                            <li className={classes.listItem}>{props.listItem9}</li>
                            <li className={classes.listItem}>{props.listItem10}</li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
        </Hidden>
        <Grid container className={`${classes.gridContainer} ${classes.gridContainerMargin}`}>
            <div className={classes.shadow3}></div>
            <Grid item md={6} sm={6} xs={12} className={classes.imageContainer}>
                <img className={classes.image3} src={props.image3} alt={props.title1} />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <Typography variant="h1" className={classes.title}>
                    {props.title3}
                </Typography>
                <Typography variant="subtitle1" className={classes.subTitle}>
                    {props.subtitle3}
                    <ul className={classes.list}>
                        <li className={classes.listItem}>{props.listItem11}</li>
                        <li className={classes.listItem}>{props.listItem12}</li>
                        <li className={classes.listItem}>{props.listItem13}</li>
                        <li className={classes.listItem}>{props.listItem14}</li>
                        <li className={classes.listItem}>{props.listItem15}</li>
                    </ul>
                </Typography>
            </Grid>
        </Grid>
    </div>
  )
}

export default WorkflowCard;
