import React from "react";
import { makeStyles } from "@material-ui/core";
import "./AboutUs.css";

// Components
import About from "./About";
import Testimonial from "./Testimonial";
import OurServices from "./OurServices";
import SetsUsApart from "./SetsUsApart";
import Reviews from "./Reviews";

const useStyles = makeStyles((theme) => ({
	aboutUs: {},
}));

const AboutUs = () => {
	const classes = useStyles();
	return (
		<div className={classes.aboutUs}>
			<About />
			<Testimonial />
			<OurServices />
			<SetsUsApart />
			<Reviews />
		</div>
	);
};

export default AboutUs;
