import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography } from "@material-ui/core";
import "../Industries.css"

// Images
import Slack from "../../../../Images/Service/SubPages/ClientsImages/slack.png";
import Office from "../../../../Images/Service/SubPages/ClientsImages/office-365.webp";
import Hikvision from "../../../../Images/Service/SubPages/ClientsImages/hikvision.webp";
import Ruckus from "../../../../Images/Service/SubPages/ClientsImages/Ruckus.webp";
import Netgear from "../../../../Images/Service/SubPages/ClientsImages/Netgear.webp";
import Quickbooks from "../../../../Images/Service/SubPages/ClientsImages/QuickBooks.webp";
import SONOS from "../../../../Images/Service/SubPages/ClientsImages/SONOS.webp";
import Cisco from "../../../../Images/Service/SubPages/ClientsImages/Cisco.webp";
import Fanvil from "../../../../Images/Service/SubPages/ClientsImages/Fanvil.webp";
import Yealink from "../../../../Images/Service/SubPages/ClientsImages/Yealink.webp";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: "700",
    color: "#032836",
    fontSize: "48px",
    marginBottom: "34px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "46px",
      marginBottom: "20px",
    },
  },
  vendorsContainer: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    whiteSpace: "nowrap",
    padding: "20px 0",
  },
  conveyor: {
    display: "flex",
    alignItems: "center",
    width: "200%",
    animation: "$scroll 30s linear infinite",
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "20px",
    minHeight: "120px",
    minWidth: "200px",
  },
  image: {
    width: "150px",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "120px",
    },
  },
  "@keyframes scroll": {
    "0%": { transform: "translateX(0)" },
    "100%": { transform: "translateX(-50%)" }, // Moves by half to create a continuous effect
  },
}));

// Define a pool of headlines
const headlines = [
  "Our Trusted Partners",
  "Our Strategic Collaborators",
  "Partners in Innovation",
  "Our Valued Alliances",
  "Our Collaborative Network",
  "Industry Leaders We Trust",
  "Our Key Technology Partners",
  "Our Essential Partners",
  "The Brands That Support Us",
  "Our Innovative Partners",
  "Partners Driving Success",
  "Our Strategic Allies",
  "Our Premier Collaborators",
  "Trusted Partners, Trusted Results",
  "Our Network of Excellence",
  "Technology Partnerships That Matter",
  "Our Global Technology Network",
  "Partners in Progress",
  "Our Industry Trailblazers",
  "Collaborating for Success"
];

// Pick a random headline on each page load
const randomHeadline = headlines[Math.floor(Math.random() * headlines.length)];

const vendorsImages = [
  Slack, Office, Hikvision, Ruckus, Netgear,
  Quickbooks, SONOS, Cisco, Fanvil, Yealink
];

const vendorsTitles = [
  'Slack', 'Office 365', 'Hikvision', 'Ruckus', 'Netgear',
  'Quickbooks', 'SONOS', 'Cisco', 'Fanvil', 'Yealink'
];

const vendorsLinks = [
  "https://slack.com/",
  "https://www.microsoft365.com/",
  "https://hikvision.com/",
  "https://www.ruckusnetworks.com/",
  "http://netgear.com/business/",
  "https://quickbooks.intuit.com/",
  "https://www.sonos.com/en-us/home",
  "https://www.cisco.com/",
  "https://www.fanvil.com/",
  "https://www.yealink.com/en",
];

const Vendors = (props) => {
  const classes = useStyles();
  
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={12} sm={12} xs={12} className={classes.container}>
            <Typography variant="h1" className={classes.title}>
              {randomHeadline}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.vendorsContainer}>
          <div className={classes.conveyor}>
            {[...vendorsImages, ...vendorsImages].map((item, i) => (
              <div className={classes.imageContainer} key={i}>
                <a href={vendorsLinks[i % vendorsLinks.length]} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <img src={item} alt={vendorsTitles[i % vendorsTitles.length]} className={classes.image} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Vendors;
