import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import HospitalityIcon from "../../../../Images/Industries/SubPages/HospitalityIconWhite.png";
import HeroHospitality from "../../../../Images/Industries/SubPages/HeroImages/Hospitality.webp";

// Images for Service cards (Hospitality images)
import HospitalityDesignImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/HospitalityDesign.webp";
import HospitalityInstallationImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/HospitalityInstallation.webp";
import HospitalityIntegrationImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/HospitalityIntegration.webp";
import HospitalityMaintenanceImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/HospitalityMaintenance.webp";

// Workflow images for Hospitality
import HospitalityWorkflowSurveyImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/Workflow/WorkflowSurvey.webp";
import HospitalityWorkflowInstallImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/Workflow/WorkflowInstall.webp";
import HospitalityWorkflowSupportImage from "../../../../Images/Industries/SubPages/ServicesImages/Hospitality/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Innovative Hospitality IT Solutions for Elevated Guest Experiences";
const heroSubTitle =
  "We create custom IT solutions that enhance the guest experience in your hospitality venue. Following a thorough venue survey, we design and install advanced systems to improve service efficiency and customer satisfaction.";

// Services Section
const serviceTitle = "Advanced Hospitality Solutions";
const serviceSubTitle =
  "Our comprehensive services include robust WiFi networks for guest connectivity, advanced security camera systems for safety, dedicated POS support where we support and manage POS systems like Toast, Clover, or Square, and custom system architecture designed to integrate and optimize your hospitality technology infrastructure.";

// Service Cards (4 cards)
const hospitalityCardTitles = [
  "WiFi Networks",
  "Security Cameras",
  "POS Support",
  "System Architecture",
];
const hospitalityCardSubTitles = [
  "Design and implementation of high-speed, secure wireless networks tailored for hospitality environments to ensure guest satisfaction.",
  "Installation and management of advanced security camera systems to monitor and protect your venue.",
  "We support and manage POS systems like Toast, Clover, or Square, ensuring efficient transaction processing and improved guest service.",
  "Custom IT architectures designed to integrate and optimize your hospitality technology infrastructure.",
];
const hospitalityCardImages = [
  HospitalityDesignImage,
  HospitalityInstallationImage,
  HospitalityIntegrationImage,
  HospitalityMaintenanceImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Hospitality Implementation Process";
const workflowSubTitle =
  "From venue surveys to precision installation and ongoing support, our structured process ensures your hospitality systems are optimally integrated for exceptional service.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Venue Survey & Consultation",
    subtitle1: "We assess your venue and develop a tailored IT plan for your hospitality needs.",
    image1: HospitalityWorkflowSurveyImage,
    listItem1: "Initial Consultation",
    listItem2: "Venue Survey",
    listItem3: "Needs Analysis",
    listItem4: "Design Proposal",
    listItem5: "Plan Approval",
  },
  {
    title2: "Precision Installation & Integration",
    subtitle2: "Our team installs systems with precision to ensure optimal performance in your venue.",
    image2: HospitalityWorkflowInstallImage,
    listItem6: "Equipment Setup",
    listItem7: "System Installation",
    listItem8: "Calibration",
    listItem9: "Testing",
    listItem10: "Configuration",
  },
  {
    title3: "Continuous Support & Optimization",
    subtitle3: "Ongoing maintenance and support keep your hospitality systems operating at peak efficiency.",
    image3: HospitalityWorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Upgrades",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const HospitalitySolutions = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroHospitality}
        serviceTitle="Hospitality Solutions"
        serviceImage={HospitalityIcon}
        heroImageClass="service-image3"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={hospitalityCardTitles}
        cardSubTitles={hospitalityCardSubTitles}
        cardImages={hospitalityCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default HospitalitySolutions;
