import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./Footer.css";

// Images
import FacebookIcon from "../../Images/Footer/facebook.svg";
import FacebookIconMobile from "../../Images/Footer/facebookMobile.svg";
import LinkedinIcon from "../../Images/Footer/linkedin.svg";
import LinkedinIconMobile from "../../Images/Footer/linkedinMobile.svg";
import Logo from "../../Images/Navbar/KinetxLogo.webp";

const useStyles = makeStyles((theme) => ({
	footer: {
		height: "auto",
		background:
			"linear-gradient(0deg,#dadbdd -57.27%,#dadbdd -57.26%,#ffffff 75.98%)",
		width: "100%",
		position: "relative",
		bottom: "0",
		left: "0",
		right: "0",
		color: "#000",
		padding: "20px",
		[theme.breakpoints.down("xs")]: {
			padding: "24px",
		},
	},
	footerContainer: {
		padding: "80px 24px",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexFlow: "column-reverse",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "40px 0",
			flexFlow: "column",
		},
	},
	logo: {
		width: "200px",
	},
	copyright: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		fontSize: "16px !important",
		margin: "10px 0 !important",
		[theme.breakpoints.down("xs")]: {
			color: "rgba(45, 45, 45, 0.7) !important",
			paddingLeft: "8px !important",
		},
	},
	footerList: {
		textDecoration: "none !important",
		listStyle: "none !important",
		padding: "0 !important",
		margin: "0 !important",
		color: "#000 ",
	},
	listHeader: {
		color: "#07516C !important",
		padding: "20px 10px !important",
		fontWeight: "600 !important",
		fontFamily: "Roboto !important",
		fontSize: "17px !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			fontWeight: "700 !important",
			padding: "15px 8px !important",
			textAlign: "unset",
		},
	},
	listStyle: {
		padding: "10px !important",
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		display: "flex",
		alignItems: "center",
		color: "#1E2833 !important",
		fontSize: "17px !important",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
		[theme.breakpoints.down("xs")]: {
			color: "rgba(45, 45, 45, 0.7) !important",
			padding: "8px !important",
			justifyContent: "unset",
		},
	},
	link: {
		cursor: "pointer",
		color: "inherit",
		textDecoration: "none",
		display: "flex",
		alignItems: "center",
		"&:hover": {
			textDecoration: "underline",
		},
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-start",
		},
	},
}));

const Footer = () => {
	const classes = useStyles();
	const currentYear = new Date().getFullYear();

	return (
		<div className={classes.footer}>
			<Container maxWidth="lg" className={classes.footerContainer}>
				<Grid container spacing={2}>
					{/* Logo Row */}
					<Grid item xs={12}>
						<Link to="/">
							<img className={classes.logo} src={Logo} alt="Kinetx Logo" />
						</Link>
					</Grid>
					{/* Lists Row */}
					<Grid item container xs={12} spacing={2}>
						{/* Physical Infrastructure Services */}
						<Grid item xs>
							<ul className={classes.footerList}>
								<li className={classes.listHeader}>Physical Infrastructure</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/wifi-networks"
										className={classes.link}
									>
										WiFi Networks
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/low-voltage-cabling"
										className={classes.link}
									>
										Low Voltage Cabling
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/fiber-optics"
										className={classes.link}
									>
										Fiber Optics
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/audio-video-systems"
										className={classes.link}
									>
										Audio / Video Systems
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/access-control-systems"
										className={classes.link}
									>
										Access Control Systems
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/security-cameras"
										className={classes.link}
									>
										Security Cameras
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/physical-infrastructure/system-architecture"
										className={classes.link}
									>
										System Architecture
									</Link>
								</li>
							</ul>
						</Grid>
						{/* Virtual Infrastructure Services */}
						<Grid item xs>
							<ul className={classes.footerList}>
								<li className={classes.listHeader}>Virtual Infrastructure</li>
								<li className={classes.listStyle}>
									<Link
										to="/virtual-infrastructure/cloud-systems"
										className={classes.link}
									>
										Cloud Systems
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/virtual-infrastructure/data-systems"
										className={classes.link}
									>
										Data Systems
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/virtual-infrastructure/data-backup-protection"
										className={classes.link}
									>
										Data Backup & Protection
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/virtual-infrastructure/online-collaboration"
										className={classes.link}
									>
										Online Collaboration
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link
										to="/virtual-infrastructure/system-architecture"
										className={classes.link}
									>
										System Architecture
									</Link>
								</li>
							</ul>
						</Grid>
						{/* Industries */}
						<Grid item xs>
							<ul className={classes.footerList}>
								<li className={classes.listHeader}>Industries</li>
								<li className={classes.listStyle}>
									<Link to="/industries/retail" className={classes.link}>
										Retail
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link to="/industries/hospitality" className={classes.link}>
										Hospitality
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link to="/industries/corporate" className={classes.link}>
										Corporate
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link to="/industries/manufacturing" className={classes.link}>
										Manufacturing
									</Link>
								</li>
							</ul>
						</Grid>
						{/* Kinetx Ecosystem */}
						<Grid item xs>
							<ul className={classes.footerList}>
								<li className={classes.listHeader}>Kinetx Ecosystem</li>
								<li className={classes.listStyle}>
									<a
										href="https://kinetx.co/"
										target="_blank"
										rel="noopener"
										className={classes.link}
									>
										Kinetx Co
									</a>
								</li>
								<li className={classes.listStyle}>
									<a
										href="https://getkit.us/"
										target="_blank"
										rel="noopener"
										className={classes.link}
									>
										KIT
									</a>
								</li>
								<li className={classes.listStyle}>
									<a
										href="https://kino.live/"
										target="_blank"
										rel="noopener"
										className={classes.link}
									>
										Kino Live
									</a>
								</li>
								<li className={classes.listStyle}>
									<a
										href="https://kodex.dev/"
										target="_blank"
										rel="noopener"
										className={classes.link}
									>
										Kodex
									</a>
								</li>
							</ul>
						</Grid>
						{/* About */}
						<Grid item xs>
							<ul className={classes.footerList}>
								<li className={classes.listHeader}>About</li>
								<li className={classes.listStyle}>
									<Link to="/about-us" className={classes.link}>
										About Us
									</Link>
								</li>
								<li className={classes.listStyle}>
									<Link to="/contact-us" className={classes.link}>
										Contact
									</Link>
								</li>
							</ul>
						</Grid>
					</Grid>
				</Grid>
				{/* Copyright Section */}
				<div style={{ textAlign: "center", marginTop: "20px" }}>
					<Typography variant="subtitle1" className={classes.copyright}>
						Copyright &copy; {currentYear}. Kinetx Co LLC. All rights reserved.
					</Typography>
				</div>
			</Container>
		</div>
	);
};

export default Footer;
