import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Consult.css";

// Images
import Small from "../../../Images/Consult/smallCube.png";
import Big from "../../../Images/Consult/bigCube.png";

const useStyles = makeStyles((theme) => ({
  consult: {
    position: "relative",
  },
  sectionImage: {
    background: "linear-gradient(255.64deg, #036785 1.47%, #07516C 117.99%)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "0",
  },
  mainContainer: {
    padding: "150px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 24px",
    },
  },
  container: {
    minHeight: "300px",
    display: "flex",
    justifyContent: "center",
  },
  highlight: {
    transition: ".5s ease-out",
    position: "absolute",
    background: "#07516C",
    top: "-1px",
    height: "25px",
    width: "100%",
    left: "0",
  },
  highlightChange: {
    background: "#FBCC9B",
    transition: ".3s ease-in",
  },
  highlightChangePhy: {
    background: "#FBCC9B",
    transition: ".3s ease-in",
  },
  highlightChangeVir: {
    background: "#FBCC9B",
    transition: ".3s ease-in",
  },
  title: {
    fontWeight: "600 !important",
    fontFamily: "Roboto !important",
    fontSize: "36px !important",
    color: "#FFFFFF",
    marginLeft: "70px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "500px",
      margin: "0 auto",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "405px",
      textAlign: "unset",
      margin: "unset",
    },
  },
  smallCubes: {
    position: "absolute",
    left: "-70px",
    bottom: "-26px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "80px",
      left: "-5px",
      height: "75px",
      display: "flex",
    },
  },
  rightContainer: {
    position: "relative",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
  },
  formContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    width: "340px",
    margin: "auto 0 auto auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "0 auto",
      textAlign: "center",
      position: "relative",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap",
      width: "unset",
      alignItems: "flex-start",
    },
  },
  formTitle: {
    fontWeight: "700 !important",
    fontFamily: "Roboto !important",
    fontSize: "18px !important",
    marginLeft: "10px !important",
  },
  formField: {
    width: "340px",
    background: "#fff !important",
    marginBottom: "20px !important",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "340px",
      width: "100%",
    },
  },
  formFieldTextArea: {
    width: "340px",
    background: "#fff !important",
    marginBottom: "30px !important",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "340px",
      width: "100%",
    },
  },
  button: {
    padding: "12px 24px !important",
    background: "#07516C !important",
    color: "#fff !important",
    textTransform: "capitalize !important",
    boxShadow: "0px 0px 4px #ffffff",
    fontFamily: "Roboto !important",
    fontWeight: "700 !important",
    marginTop: "25px",
    width: "235px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "235px",
      width: "100%",
    },
  },
  errorText: {
    color: "red",
    fontSize: "14px",
    marginTop: "4px",
    marginLeft: "10px",
  },
  bigCubes: {
    position: "absolute",
    left: "125px",
    bottom: "-65px",
    zIndex: "-1",
    [theme.breakpoints.down("sm")]: {
      height: "430px",
      left: "-25px",
      bottom: "-25px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const Consult = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [initialColor, setInitialColor] = useState(false);
  const [initialPhysical, setInitialPhysical] = useState(false);
  const [initialVirtual, setInitialVirtual] = useState(false);

  const listenScrollEvent = (e) => {
    let highlightElement = document.getElementById("color-change");
    let distanceToTop = Math.round(highlightElement.getBoundingClientRect().top);
    distanceToTop = distanceToTop - 80;
    if (props.color === "physical") {
      setInitialPhysical(distanceToTop > -50 && distanceToTop < 330);
    } else if (props.color === "virtual") {
      setInitialVirtual(distanceToTop > -50 && distanceToTop < 330);
    } else {
      setInitialColor(distanceToTop > -50 && distanceToTop < 330);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setErrors({ ...errors, [e.target.id]: "" });
  };

  // Function to send form info as a message to the chatbot
  const sendConsultMessage = (data) => {
    if (
      window.$zoho &&
      window.$zoho.salesiq &&
      window.$zoho.salesiq.chat &&
      typeof window.$zoho.salesiq.chat.sendVisitorMessage === "function"
    ) {
      const message = `Consult Request:\nName: ${data.name}\nEmail: ${data.email}\nCompany: ${data.company || "No company provided"}`;
      window.$zoho.salesiq.chat.sendVisitorMessage(message);
    } else {
      console.error("SalesIQ sendVisitorMessage API is not available");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { name: "", email: "" };

    if (formData.name.trim().length < 2) {
      newErrors.name = "Name must be at least 2 characters.";
      valid = false;
    }
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
    }
    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.visitor) {
        window.$zoho.salesiq.visitor.set("Name", formData.name);
        window.$zoho.salesiq.visitor.set("Email", formData.email);
        window.$zoho.salesiq.visitor.set("Company", formData.company || "No company provided");

        window.$zoho.salesiq.chat.getBotListV2(function (response) {
          if (response && response.bots && response.bots.length > 0) {
            const defaultBot = response.bots.find((bot) => bot.isDefault) || response.bots[0];
            if (defaultBot && defaultBot.id) {
              window.$zoho.salesiq.chat.startChat(defaultBot.id);
              sendConsultMessage(formData);
              alert("Your consult request has been sent!");
              setFormData({ name: "", email: "", company: "" });
            } else {
              console.error("No bot available to start chat.");
              alert("No bot available to start chat.");
            }
          } else {
            console.error("Failed to retrieve bot list.");
            alert("Failed to retrieve bot list. Please try again later.");
          }
        });
      } else {
        console.error("Zoho SalesIQ not initialized");
        alert("Zoho SalesIQ is not initialized. Please try again later.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  return (
    <div className={classes.consult} id="color-change">
      <div className={classes.sectionImage}></div>
      <Container maxWidth="lg" className={classes.mainContainer}>
        <Grid container className={classes.container}>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="h2" className={classes.title}>
              <span style={{ position: "relative" }}>
                Let’s Build a Stronger Future for Your Brand{" "}
                <img src={Small} alt={"Small Cube"} className={classes.smallCubes} />
              </span>
              <span style={{ position: "relative" }}>
                <span style={{ position: "relative", zIndex: "2" }}>
                  With a Free Consult
                </span>
                <span
                  className={`${classes.highlight} 
                    ${initialColor && classes.highlightChange} 
                    ${initialPhysical && classes.highlightChangePhy} 
                    ${initialVirtual && classes.highlightChangeVir}`}
                ></span>
              </span>
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className={classes.rightContainer}>
            <form onSubmit={handleSubmit} autoComplete="off" className={classes.formContainer}>
              <TextField
                size="small"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                variant="outlined"
                className={classes.formField}
              />
              {errors.name && <Typography className={classes.errorText}>{errors.name}</Typography>}
              <TextField
                size="small"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                variant="outlined"
                className={classes.formField}
              />
              {errors.email && <Typography className={classes.errorText}>{errors.email}</Typography>}
              <TextField
                size="small"
                id="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Enter your company (optional)"
                variant="outlined"
                className={classes.formField}
              />
              <Button type="submit" className={classes.button}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>
                    GET CONSULT
                    <ArrowForwardIosIcon className={classes.icon} />
                  </>
                )}
              </Button>
              <img src={Big} alt={"Big Cube"} className={classes.bigCubes} />
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Consult;
