import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Audio from "../../../../Images/Service/SubPages/audio-systems.png";
import HeroAudio from "../../../../Images/Service/SubPages/HeroImages/AudioSystems.png";

// Images for Audio/Video service cards
import AudioDesignImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/AudioDesign.webp";
import AudioInstallationImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/AudioInstallation.webp";
import AudioCalibrationImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/AudioCalibration.webp";
import AudioMaintenanceImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/AudioMaintenance.webp";

// Workflow images for Audio/Video (sized 450px x 380px)
import WorkflowConsultImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/Workflow/WorkflowConsult.webp";
import WorkflowInstallImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/Workflow/WorkflowInstall.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/AudioVideo/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "High-Performance Audio/Video Systems for Engaging Experiences";
const heroSubTitle =
  "Enhance your environment with custom-designed audio and video systems that deliver crystal-clear sound and vivid visuals. We design, install, and maintain systems that elevate your space’s functionality and presentation.";

// Services Section
const serviceTitle = "Advanced Audio/Video Solutions";
const serviceSubTitle =
  "Our services cover complete audio and video system design, installation, and ongoing maintenance. We tailor solutions to fit the unique needs of your environment, ensuring optimal performance and clarity.";

// Service Cards (4 cards)
const audioCardTitles = [
  "Custom System Design",
  "Professional Installation",
  "System Calibration & Testing",
  "Maintenance & Support",
];
const audioCardSubTitles = [
  "Bespoke designs that transform your space.",
  "Expert setup for flawless performance.",
  "Precision tuning for crisp audio and vibrant visuals.",
  "Ongoing support and regular updates to keep your AV systems performing at their best.",
];
const audioCardImages = [
  AudioDesignImage,
  AudioInstallationImage,
  AudioCalibrationImage,
  AudioMaintenanceImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Deployment Process";
const workflowSubTitle =
  "Our structured approach takes your audio and video systems from initial consultation to full installation and maintenance, ensuring high-quality performance at every stage.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Consultation & Planning",
    subtitle1: "We assess your needs and design a customized AV solution.",
    image1: WorkflowConsultImage,
    listItem1: "Initial Consultation",
    listItem2: "Site Survey",
    listItem3: "Needs Analysis",
    listItem4: "Design Proposal",
    listItem5: "Plan Approval",
  },
  {
    title2: "Precision Installation",
    subtitle2: "Our team installs your AV systems with precision for optimal performance.",
    image2: WorkflowInstallImage,
    listItem6: "Equipment Setup",
    listItem7: "System Installation",
    listItem8: "Calibration",
    listItem9: "Testing",
    listItem10: "Configuration",
  },
  {
    title3: "Ongoing Maintenance & Support",
    subtitle3: "Continuous monitoring and regular maintenance keep your AV systems at peak performance.",
    image3: WorkflowSupportImage,
    listItem11: "Regular Inspections",
    listItem12: "Maintenance Checks",
    listItem13: "Software Updates",
    listItem14: "Troubleshooting",
    listItem15: "Performance Reviews",
  },
];

const AudioVideoSystems = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroAudio}
        serviceTitle="Audio / Video Systems"
        serviceImage={Audio}
        heroImageClass="service-image"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={audioCardTitles}
        cardSubTitles={audioCardSubTitles}
        cardImages={audioCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default AudioVideoSystems;
