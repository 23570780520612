import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import "./Service.css";

// Images
import backgroundImage from "../../../Images/Hero/BackgroundImage.jpg";
import PhysicalImage from "../../../Images/Service/physical.png";
import PhysicalImage1 from "../../../Images/Service/physical-hero-1.png";
import PhysicalImage2 from "../../../Images/Service/physical-hero-2.png";

const useStyles = makeStyles((theme) => ({
	infrastructure: {
		minHeight: "100vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background:
			"linear-gradient(72.49deg, #DADBDD -19.29%, #DADBDD -19.28%, #FFFFFF 53.4%)",
		position: "relative",
		alignItems: "center",
	},
	serviceImage: {
		backgroundImage: `url(${backgroundImage})`,
		opacity: "0.6",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "100px 0",
		[theme.breakpoints.down("sm")]: {
			flexFlow: "column-reverse",
			padding: "30px 0 60px",
		},
	},
	leftContainer: {
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			alignItems: "center",
			flexFlow: "column",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
		},
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "46px",
		textTransform: "capitalize !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
			fontSize: "40px",
		},
	},
	titleHighlight: {
		background: "#FBCC9B",
		width: "450px",
		height: "15px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "510px",
		},
	},
	titleParagraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		lineHeight: "24px !important",
		maxWidth: "520px",
		marginTop: "10px",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	subTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "20px !important",
		marginTop: "70px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "50px",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		lineHeight: "24px !important",
		maxWidth: "500px",
	},
	heroImage1: {
		margin: "20px 0 0",
		[theme.breakpoints.down("sm")]: {
			margin: "10px 0 0",
		},
	},
	heroImage2: {
		marginTop: "20px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "10px",
		},
	},
	rightContainer: {
		zIndex: "1",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "center",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-end",
		},
	},
	physicalImageStyle: {
		[theme.breakpoints.down("sm")]: {
			height: "250px",
		},
		[theme.breakpoints.down("xs")]: {
			height: "200px",
		},
	},
}));

const PhysicalInfrastructure = () => {
	const classes = useStyles();
	return (
		<div className={classes.infrastructure}>
			<div className={classes.serviceImage}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} className={classes.leftContainer}>
						<Typography variant="h2" className={classes.title}>
							Physical Infrastructure
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Typography variant="subtitle1" className={classes.titleParagraph}>
							Enhance your business performance with cutting-edge low-voltage networking, robust security systems, immersive audio-video solutions, and dependable wireless connectivity.
						</Typography>
						<Typography variant="subtitle1" className={classes.subTitle}>
							Building Your IT Infrastructure
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							Not sure where to begin? Crafting a custom IT system can seem overwhelming, but we’re here to guide you every step of the way. We partner with you to analyze your operations and develop a clear, tailored strategy that perfectly fits your unique business needs.
						</Typography>
						<img
							src={PhysicalImage1}
							alt="Four Cubes"
							className={classes.heroImage1}
						/>
						<Typography variant="subtitle1" className={classes.subTitle}>
							Every IT Strategy is Unique
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							We simplify complex systems by breaking them down into manageable components. Your insights are essential, and together, we create the optimal IT solution that propels your business forward.
						</Typography>
						<img
							src={PhysicalImage2}
							alt="Four Cubes"
							className={classes.heroImage2}
						/>
					</Grid>
					<Grid item md={6} className={classes.rightContainer}>
						<img
							src={PhysicalImage}
							alt="Physical Infrastructure"
							className={classes.physicalImageStyle}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default PhysicalInfrastructure;
