import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button, Box } from "@material-ui/core";
import "./AboutUs.css";

// Images
import Cubes from "../../../Images/About Us/apartCubes.png";
import CubesTablet from "../../../Images/About Us/apartCubesTablet.png";
import CubesMobile from "../../../Images/About Us/apartCubesMobile.png";
import Pointer from "../../../Images/About Us/pointer.png";

const useStyles = makeStyles((theme) => ({
	setsUsApart: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		background: "linear-gradient(255.64deg, #2289a9 1.47%, #07516C 117.99%)",
		[theme.breakpoints.down("sm")]: {
			background: "linear-gradient(356.05deg, #2289a9 3.85%, #07516C 113.72%)",
		},
		[theme.breakpoints.down("xs")]: {
			background: "linear-gradient(255.64deg, #2289a9 1.47%, #07516C 117.99%)",
		},
	},
	container: {
		padding: "0 24px 0 14px",
		[theme.breakpoints.down("sm")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "150px 0",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 0",
		},
	},
	pointer: {
		position: "absolute",
		left: "103px",
		bottom: "196px",
		width: "250px",
	},
	CubesTabletImage: {
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	leftContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-start",
		},
	},
	title: {
		color: "#fff !important",
		fontFamily: "Roboto !important",
		fontSize: "36px",
		fontWeight: "600 !important",
		marginBottom: "5px",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
			fontSize: "40px",
			marginBottom: "5px",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "35px 0 15px",
			textAlign: "unset",
			fontSize: "38px",
		},
	},
	titleHighlight: {
		background: "linear-gradient(255.64deg,#032836 1.47%, #07516C 117.99%)",
		height: "15px",
		width: "450px",
		marginBottom: "2rem",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "400px",
			margin: "unset",
		},
	},
	paragraph: {
		fontFamily: "Roboto !important",
		color: "#fff !important",
		fontWeight: "400 !important",
		maxWidth: "530px",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "400px",
			margin: "unset",
		},
	},
	rightContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-end",
		},
	},
}));

const SetsUsApart = () => {
	const classes = useStyles();
	return (
		<div className={classes.setsUsApart}>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} sm={12} xs={12} className={classes.leftContainer}>
						<div style={{ position: "relative" }}>
							<Box display={{ xs: "none", sm: "none", md: "block" }}>
								<img src={Cubes} alt="4 Cubes" />
								<img src={Pointer} alt="Pointer" className={classes.pointer} />
							</Box>
							<Box display={{ xs: "none", sm: "block", md: "none" }}>
								<img
									src={CubesTablet}
									alt="4 Cubes Tablet"
									className={classes.CubesTabletImage}
								/>
							</Box>
							<Box display={{ xs: "block", sm: "none", md: "none" }}>
								<img src={CubesMobile} alt="4 Cubes and Pointer Mobile" />
							</Box>
						</div>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Typography variant="h2" className={classes.title}>
							What sets us apart
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Typography variant="subtitle1" className={classes.paragraph}>
							At Kinetx Co, our brilliance, intelligence, and innovation drive us to deliver premium, custom IT solutions. Our approach goes beyond traditional methods—we meticulously design unique infrastructures that match your business goals with exceptional precision and creativity.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default SetsUsApart;
