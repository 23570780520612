import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Services.css";

// Images
import Cube1 from "../../../Images/Services/orange-cube.png";
import Cube2 from "../../../Images/Services/dark-cube.png";

const useStyles = makeStyles((theme) => ({
	services: {
		background: "linear-gradient(255.64deg, #032836 1.47%, #07516C 117.99%)",
		height: "auto",
		display: "flex",
	},
	container: {
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "130px 8px",
		margin: "0 !important",
		justifyContent: "space-between",
		height: "100%",
		[theme.breakpoints.down("xs")]: {
			padding: "50px 0",
		},
	},
	titleContainer: {
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		[theme.breakpoints.down("xs")]: {
			display: "unset",
		},
	},
	title: {
		fontWeight: "500 !important",
		fontFamily: "Roboto !important",
		color: "#fff",
		fontSize: "36px !important",
		[theme.breakpoints.down("sm")]: {
			fontWeight: "700 !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontWeight: "500 !important",
		},
	},
	grid: {
		padding: "10px !important",
		display: "flex",
		alignItems: "center",
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			marginTop: "60px",
		},
	},
	card: {
		padding: "30px",
		color: "#000",
		background: "#e5ebec",
		height: "100%",
		position: "relative",
		zIndex: "1",
		[theme.breakpoints.down("xs")]: {
			marginLeft: "15px",
			width: "100%",
			maxWidth: "400px",
		},
	},

	cardTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "18px !important",
		textTransform: "uppercase !important",
		color: "#2D2D2D !important",
	},
	highlight: {
		position: "absolute",
		top: "-10px",
		height: "20px",
		width: "100%",
		left: "0",
	},
	cardParagraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		fontSize: "18px !important",
		margin: "15px 0 !important",
		minHeight: "155px",
		color: "#2D2D2D !important",
		[theme.breakpoints.down("sm")]: {
			minHeight: "189px",
		},
	},
	cardButton: {
		color: "#07516C !important",
		textTransform: "none !important",
		fontFamily: "Roboto !important",
		fontWeight: "700 !important",
		textDecoration: "underline !important",
		paddingLeft: "0 !important",
		fontSize: "18px !important",
		justifyContent: "flex-start !important",
	},
	icon: {
		fontSize: "12px !important",
	},
	cubeImage: {
		position: "absolute",
		bottom: "-70px",
		left: "-5px",
		zIndex: "0",
		[theme.breakpoints.down("sm")]: {
			left: "0",
		},
		[theme.breakpoints.down("xs")]: {
			left: "10px",
		},
	},
}));

const Services = () => {
	const classes = useStyles();
	return (
		<div className={classes.services}>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={3} sm={12} xs={12} className={classes.titleContainer}>
						<Typography variant="h2" className={classes.title}>
						Virtual & Physical Foundations
						</Typography>
					</Grid>
					<Grid item md={4} sm={6} xs={12} className={classes.grid}>
						<Box className={classes.card}>
							<Typography variant="h2" className={classes.cardTitle}>
								<span style={{ position: "relative" }}>
									<span style={{ position: "relative", zIndex: "2" }}>
										Physical Infrastructure
									</span>
									<span
										className={classes.highlight}
										style={{ background: "#FBCC9B" }}
									></span>
								</span>
							</Typography>
							<Typography variant="subtitle1" className={classes.cardParagraph}>
							We design and install robust physical IT systems — low-voltage networking, 
							security, audio/visual, and wireless solutions — that ensure reliable connectivity 
							and optimized performance for your business.
							</Typography>
							<Link to={"/physical-infrastructure/"}>
								<Button
									className={classes.cardButton}
									endIcon={<ArrowForwardIosIcon className={classes.icon} />}
								>
									More
								</Button>
							</Link>
						</Box>
						<div className={classes.cubeImage}>
							<img src={Cube1} alt="Card Cube" />
						</div>
					</Grid>
					<Grid item md={4} sm={6} xs={12} className={classes.grid}>
						<Box className={classes.card}>
							<Typography variant="h2" className={classes.cardTitle}>
								<span style={{ position: "relative" }}>
									<span style={{ position: "relative", zIndex: "2" }}>
										Virtual Infrastructure
									</span>
									<span
										className={classes.highlight}
										style={{ background: "#ADCFDB" }}
									></span>
								</span>
							</Typography>
							<Typography variant="subtitle1" className={classes.cardParagraph}>
							We deliver agile virtual solutions — secure data management, cloud storage, 
							and collaboration platforms — that streamline operations, enhance communication, 
							and drive sustainable business growth.
							</Typography>
							<Link to={"/virtual-infrastructure/"}>
								<Button
									className={classes.cardButton}
									endIcon={<ArrowForwardIosIcon className={classes.icon} />}
								>
									More
								</Button>
							</Link>
						</Box>
						<div className={classes.cubeImage}>
							<img src={Cube2} alt="Card Cube" />
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Services;
