import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import CorporateIcon from "../../../../Images/Industries/SubPages/CorporateIconWhite.png";
import HeroCorporate from "../../../../Images/Industries/SubPages/HeroImages/Corporate.webp";

// Images for Corporate service cards
import CorporateDesignImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/CorporateDesign.webp";
import CorporateInstallationImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/CorporateInstallation.webp";
import CorporateIntegrationImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/CorporateIntegration.webp";
import CorporateMaintenanceImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/CorporateMaintenance.webp";

// Workflow images for Corporate (sized 450px x 380px)
import WorkflowCorporateSurveyImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/Workflow/WorkflowSurvey.webp";
import WorkflowCorporateInstallImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/Workflow/WorkflowInstall.webp";
import WorkflowCorporateSupportImage from "../../../../Images/Industries/SubPages/ServicesImages/Corporate/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Updated Text for Corporate Industry */

// Hero Section
const heroTitle = "Innovative IT Solutions for Corporate Excellence";
const heroSubTitle =
  "Elevate your corporate operations with tailored IT infrastructures that drive productivity, ensure security, and enable seamless connectivity. Our solutions integrate advanced physical and virtual technologies to empower your business in a competitive marketplace.";

// Services Section
const serviceTitle = "Comprehensive Corporate IT Solutions";
const serviceSubTitle =
  "Our expert services blend robust physical infrastructure with cutting-edge virtual technologies to create a secure, efficient, and agile IT ecosystem for your organization. We deliver solutions such as WiFi Networks, Low Voltage Cabling, Cloud Systems, and Data Backup & Protection.";

// Service Cards (using Kinetx services for corporate)
const corporateCardTitles = [
  "WiFi Networks",
  "Low Voltage Cabling",
  "Cloud Systems",
  "Data Backup & Protection",
];
const corporateCardSubTitles = [
  "Deploy state-of-the-art wireless networks that ensure seamless connectivity throughout your corporate environment.",
  "Experience high-speed, reliable data transfer with expertly installed cabling systems designed for corporate demands.",
  "Leverage scalable cloud solutions that offer secure, 24/7 access to your business-critical applications and data.",
  "Safeguard your corporate data with robust backup and protection solutions that minimize risk and ensure continuity.",
];
const corporateCardImages = [
  CorporateDesignImage,
  CorporateInstallationImage,
  CorporateIntegrationImage,
  CorporateMaintenanceImage,
];

// Vendors Section
const vendorsTitle = "Our Valued Clients";

// Workflow Section
const workflowTitle = "Our Corporate IT Implementation Process";
const workflowSubTitle =
  "Our proven methodology ensures that every solution is custom-designed, seamlessly integrated, and continuously supported to drive lasting corporate success.";
const workflowCardItems = [
  {
    title1: "Consultation & Assessment",
    subtitle1:
      "We evaluate your corporate environment and define your unique IT requirements.",
    image1: WorkflowCorporateSurveyImage,
    listItem1: "Initial Consultation",
    listItem2: "Corporate Audit",
    listItem3: "Needs Analysis",
    listItem4: "Proposal",
    listItem5: "Plan Approval",
  },
  {
    title2: "Design & Integration",
    subtitle2:
      "Our experts craft a tailored solution and integrate it with your existing systems for optimal performance.",
    image2: WorkflowCorporateInstallImage,
    listItem6: "Blueprint Development",
    listItem7: "Component Selection",
    listItem8: "System Design",
    listItem9: "Integration Testing",
    listItem10: "Validation",
  },
  {
    title3: "Deployment & Support",
    subtitle3:
      "We deploy your IT solution with precision and provide ongoing support to ensure continuous improvement.",
    image3: WorkflowCorporateSupportImage,
    listItem11: "System Deployment",
    listItem12: "Configuration",
    listItem13: "Quality Assurance",
    listItem14: "Continuous Monitoring",
    listItem15: "Performance Optimization",
  },
];

const CorporateITSolutions = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroCorporate}
        serviceTitle="Corporate IT Solutions"
        serviceImage={CorporateIcon}
        heroImageClass="service-image"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={corporateCardTitles}
        cardSubTitles={corporateCardSubTitles}
        cardImages={corporateCardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"physical"} />
    </div>
  );
};

export default CorporateITSolutions;
