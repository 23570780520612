import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./Partners.css";

const useStyles = makeStyles((theme) => ({
	partners: {
		minHeight: "100vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background:
			"linear-gradient(61.54deg, #DADDDD -48.56%, #DADBDD -48.55%, #F0F7FA 72.22%)",
		position: "relative",
		alignItems: "center",
	},
	container: {},
	leftContainer: {},
	rightContainer: {},
}));

const Partners = () => {
	const classes = useStyles();
	return (
		<div className={classes.partners}>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container>
					<Grid
						item
						md={6}
						sm={12}
						xs={12}
						className={classes.leftContainer}
					></Grid>
					<Grid
						item
						md={6}
						sm={12}
						xs={12}
						className={classes.rightContainer}
					></Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Partners;
