import React from "react";
import { makeStyles } from "@material-ui/core";

// Components
import Hero from "../components/Hero.jsx";
import Services from "../components/Services.jsx";
import Vendors from "../components/Vendors.jsx";
import Workflow from "../components/Workflow.jsx";
import Consult from "../../../PageComponents/Consult/Consult";

// Images for Hero section
import Systems from "../../../../Images/Service/SubPages/cloud-systems.png";
import HeroSystems from "../../../../Images/Service/SubPages/HeroImages/CloudSystems.png";

// Images for Cloud & AI service cards
import CloudStrategyImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/CloudStrategy.webp";
import AIDrivenImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/AIDriven.webp";
import IntegrationAutomationImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/IntegrationAutomation.webp";
import ContinuousSupportImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/ContinuousSupport.webp";

// Workflow images for Cloud & AI (sized 450px x 380px)
import WorkflowConsultImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/Workflow/WorkflowConsult.webp";
import WorkflowIntegrationImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/Workflow/WorkflowIntegration.webp";
import WorkflowSupportImage from "../../../../Images/Service/SubPages/ServicesImages/CloudAI/Workflow/WorkflowSupport.webp";

const useStyles = makeStyles((theme) => ({}));

/* Approved Text */

// Hero Section
const heroTitle = "Innovative Cloud & AI Solutions for a Smarter Business";
const heroSubTitle =
  "Leverage our cutting-edge cloud and AI technologies to reduce tech maintenance costs and boost business efficiency. Our innovative approach transforms your IT landscape, enhancing performance and increasing business value.";

// Services Section
const serviceTitle = "Advanced Cloud & AI Services";
const serviceSubTitle =
  "Our comprehensive services integrate cloud computing with artificial intelligence to streamline operations, reduce maintenance expenses, and drive business growth.";

// Service Cards (4 cards)
const cloudAICardTitles = [
  "Cloud Strategy & Migration",
  "AI-Driven Optimization",
  "Integration & Automation",
  "Continuous Support & Innovation",
];
const cloudAICardSubTitles = [
  "Effortlessly transition to a scalable cloud infrastructure.",
  "Utilize AI to refine processes and cut operational costs.",
  "Merge cloud solutions with intelligent automation for improved efficiency.",
  "Ongoing management and proactive enhancements to keep your systems ahead.",
];
const cloudAICardImages = [
  CloudStrategyImage,
  AIDrivenImage,
  IntegrationAutomationImage,
  ContinuousSupportImage,
];

// Vendors Section
const vendorsTitle = "Our Esteemed Clients";

// Workflow Section
const workflowTitle = "Our Implementation Process";
const workflowSubTitle =
  "Our structured process leverages cloud and AI to revolutionize your IT operations from assessment to continuous improvement.";

// Workflow Cards (3 cards)
const workflowCardItems = [
  {
    title1: "Initial Consultation & Assessment",
    subtitle1:
      "We evaluate your current infrastructure to uncover opportunities for cost reduction.",
    image1: WorkflowConsultImage,
    listItem1: "Initial Consultation",
    listItem2: "System Audit",
    listItem3: "Needs Analysis",
    listItem4: "Strategic Proposal",
    listItem5: "Plan Finalization",
  },
  {
    title2: "Cloud Migration & AI Integration",
    subtitle2:
      "Deploy advanced cloud systems and integrate AI-driven tools to enhance efficiency.",
    image2: WorkflowIntegrationImage,
    listItem6: "Infrastructure Setup",
    listItem7: "Data Migration",
    listItem8: "AI Tool Integration",
    listItem9: "Testing",
    listItem10: "Go-Live Support",
  },
  {
    title3: "Ongoing Optimization & Innovation",
    subtitle3:
      "Continuous monitoring and regular updates keep your IT ecosystem at peak performance.",
    image3: WorkflowSupportImage,
    listItem11: "Performance Monitoring",
    listItem12: "Regular Updates",
    listItem13: "AI Optimization",
    listItem14: "Troubleshooting",
    listItem15: "Innovation Reviews",
  },
];

const CloudSystems = () => {
  const classes = useStyles();
  return (
    <div>
      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        image={HeroSystems}
        serviceTitle="Cloud & AI"
        serviceImage={Systems}
        heroImageClass="service-image3"
      />
      <Services
        title={serviceTitle}
        subtitle={serviceSubTitle}
        cardTitles={cloudAICardTitles}
        cardSubTitles={cloudAICardSubTitles}
        cardImages={cloudAICardImages}
      />
      <Vendors title={vendorsTitle} />
      <Workflow
        title={workflowTitle}
        subtitle={workflowSubTitle}
        title1={workflowCardItems[0].title1}
        title2={workflowCardItems[1].title2}
        title3={workflowCardItems[2].title3}
        subtitle1={workflowCardItems[0].subtitle1}
        subtitle2={workflowCardItems[1].subtitle2}
        subtitle3={workflowCardItems[2].subtitle3}
        image1={workflowCardItems[0].image1}
        image2={workflowCardItems[1].image2}
        image3={workflowCardItems[2].image3}
        listItem1={workflowCardItems[0].listItem1}
        listItem2={workflowCardItems[0].listItem2}
        listItem3={workflowCardItems[0].listItem3}
        listItem4={workflowCardItems[0].listItem4}
        listItem5={workflowCardItems[0].listItem5}
        listItem6={workflowCardItems[1].listItem6}
        listItem7={workflowCardItems[1].listItem7}
        listItem8={workflowCardItems[1].listItem8}
        listItem9={workflowCardItems[1].listItem9}
        listItem10={workflowCardItems[1].listItem10}
        listItem11={workflowCardItems[2].listItem11}
        listItem12={workflowCardItems[2].listItem12}
        listItem13={workflowCardItems[2].listItem13}
        listItem14={workflowCardItems[2].listItem14}
        listItem15={workflowCardItems[2].listItem15}
      />
      <Consult color={"virtual"} />
    </div>
  );
};

export default CloudSystems;
