import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import "./Service.css";

// Images
import backgroundImage from "../../../Images/Hero/BackgroundImage.jpg";
import VirtualImage from "../../../Images/Service/virtual.png";
import VirtualImage1 from "../../../Images/Service/virtual-hero-1.png";
import VirtualImage2 from "../../../Images/Service/virtual-hero-2.png";

const useStyles = makeStyles((theme) => ({
	infrastructure: {
		minHeight: "100vh",
		width: "100%",
		overflow: "hidden",
		display: "flex",
		background:
			"linear-gradient(72.49deg, #DADBDD -19.29%, #DADBDD -19.28%, #FFFFFF 53.4%)",
		position: "relative",
		alignItems: "center",
	},
	serviceImage: {
		backgroundImage: `url(${backgroundImage})`,
		opacity: "0.6",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: "0",
	},
	container: {
		[theme.breakpoints.down("xs")]: {
			padding: "0 24px",
		},
	},
	gridContainer: {
		padding: "100px 0",
		[theme.breakpoints.down("sm")]: {
			flexFlow: "column-reverse",
			padding: "30px 0 60px",
		},
	},
	leftContainer: {
		zIndex: "1",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			alignItems: "center",
			flexFlow: "column",
		},
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
		},
	},
	title: {
		fontWeight: "700 !important",
		fontFamily: "Roboto!important",
		fontSize: "46px",
		textTransform: "capitalize !important",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
			fontSize: "40px",
		},
	},
	titleHighlight: {
		background: "#ADCFDB",
		width: "450px",
		height: "15px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "510px",
		},
	},
	titleParagraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		lineHeight: "24px !important",
		maxWidth: "500px",
		marginTop: "10px",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "unset",
		},
	},
	subTitle: {
		fontWeight: "700 !important",
		fontFamily: "Roboto !important",
		fontSize: "20px !important",
		marginTop: "70px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "50px",
		},
	},
	paragraph: {
		fontWeight: "400 !important",
		fontFamily: "Roboto !important",
		lineHeight: "24px !important",
		maxWidth: "520px",
	},
	fourCubesImage: {
		margin: "20px 0 0",
		[theme.breakpoints.down("sm")]: {
			margin: "10px 0",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			maxWidth: "350px",
		},
	},
	heroImage1: {
		margin: "20px 0 0",
		[theme.breakpoints.down("sm")]: {
			margin: "10px 0 0",
		},
	},
	heroImage2: {
		marginTop: "20px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "10px",
		},
	},
	rightContainer: {
		zIndex: "1",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "center",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "flex-end",
		},
	},
	virtualImageStyle: {
		[theme.breakpoints.down("sm")]: {
			height: "250px",
		},
		[theme.breakpoints.down("xs")]: {
			height: "200px",
		},
	},
}));

const VirtualInfrastructure = () => {
	const classes = useStyles();
	return (
		<div className={classes.infrastructure}>
			<div className={classes.serviceImage}></div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container className={classes.gridContainer}>
					<Grid item md={6} className={classes.leftContainer}>
						<Typography variant="h2" className={classes.title}>
							Virtual <br /> Infrastructure
						</Typography>
						<div className={classes.titleHighlight}></div>
						<Typography variant="subtitle1" className={classes.titleParagraph}>
							Improving business performance by installing high end Low-voltage
							Networking Systems, Security Systems, Audio Video Systems and
							Wireless Connections
						</Typography>
						<Typography variant="subtitle1" className={classes.subTitle}>
							Building Your IT Infrastructure
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							Not sure where to start? Building an IT system for your business
							can be a daunting and difficult task to take on. We are always
							ready to partner with you to study and analyze each step and
							detail to make this process as seamless and comprehensible as
							possible!
						</Typography>
						<img
							src={VirtualImage1}
							alt="Four Cubes"
							className={classes.heroImage1}
						/>
						<Typography variant="subtitle1" className={classes.subTitle}>
							No two IT strategies are similar:
						</Typography>
						<Typography variant="subtitle1" className={classes.paragraph}>
							We break down the system to make it as easy as possible for you to
							utilize. We ask for your input as we build because we want to
							build what is best for you and your business.
						</Typography>
						<img
							src={VirtualImage2}
							alt="Four Cubes"
							className={classes.heroImage2}
						/>
					</Grid>
					<Grid item md={6} className={classes.rightContainer}>
						<img
							src={VirtualImage}
							alt="Virtual Infrastructure"
							className={classes.virtualImageStyle}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default VirtualInfrastructure;
